// components/Logo.js
import { View, StyleSheet } from 'react-native';
import { useRelativeStyles } from '../utils/screenDimensions';

export const LogoSize = {
    SMALL: 'small',
    MIDDLE: 'middle',
    LARGE: 'large',
};

const SizeConfigMap = {
    'small': {
        iLogoSize: 26,
        iLogoContainerSize: 34,
        iBorderWidth: 1
    },
    'middle': {
        iLogoSize: 40,
        iLogoContainerSize: 52,
        iBorderWidth: 3
    },
    'large': {
        iLogoSize: 140,
        iLogoContainerSize: 183,
        iBorderWidth: 6
    }
}

const Logo = ({ userColor, otherUserColor, logoSize }) => {
    const { iLogoSize, iLogoContainerSize, iBorderWidth } = SizeConfigMap[logoSize] || SizeConfigMap['small'];

    const styles = StyleSheet.create(useRelativeStyles({
        container: {
            position: 'relative',
            rlw_width: iLogoContainerSize,
            rlw_height: iLogoContainerSize,
        },
        solidCircle: {
            backgroundColor: userColor,
            position: 'absolute',
            bottom: 0,
            right: 0,
            rlw_width: iLogoSize,
            rlw_height: iLogoSize,
            rlw_borderRadius: iLogoSize / 2,
        },
        hollowCircle: {
            borderColor: otherUserColor,
            position: 'absolute',
            borderWidth: iBorderWidth,
            rlw_width: iLogoSize,
            rlw_height: iLogoSize,
            rlw_borderRadius: iLogoSize / 2,
            top: 0,
            left: 0,
            zIndex: 1,
        },
    }));

    return (
        <View style={styles.container}>
            <View testID="hollow-circle" style={styles.hollowCircle} />
            <View testID="solid-circle" style={styles.solidCircle} />
        </View>
    );
};

export default Logo;