// utils/messageUtils.js

import { MessageType } from "../models/Message";

const TIME_GAP_IN_MILLISECONDS = 3600000;       // 1 hour

export const formatDate = (timestamp) => {
    const inputDate = new Date(timestamp);
    const currentDate = new Date();

    // Calculate the difference in days between input date and current date
    const diffDays = Math.floor((currentDate - inputDate) / (1000 * 60 * 60 * 24));
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const yearSuffix = inputDate.getFullYear() !== currentDate.getFullYear() ? `, ${inputDate.getFullYear()}` : "";
    let formattedDate = "";
    if (diffDays === 0) {
        formattedDate += `Today`;
    } else if (diffDays === 1) {
        formattedDate += `Yesterday`;
    } else {
        formattedDate += `${monthNames[inputDate.getMonth()]} ${inputDate.getDate()}${yearSuffix}`;
    }

    let formattedTime = "";
    const hours = inputDate.getHours();
    const minutes = inputDate.getMinutes();
    const amOrPm = hours < 12 ? "AM" : "PM";
    const twelveHourFormat = hours % 12 || 12;
    formattedTime += `${twelveHourFormat}:${String(minutes).padStart(2, '0')} ${amOrPm}`;

    return { formattedDate, formattedTime };
};

const isLastSimulatedMessage = (messages, index) => {
    return (messages[index]?.type === MessageType.SIMULATED &&
        messages[index - 1]?.type !== MessageType.SIMULATED)
};

const isLastMessageFromSameAuthor = (messages, index) => {
    const thisMessage = messages[index];
    const nextMessage = messages[index - 1];
    return !(thisMessage && nextMessage &&
        thisMessage.author?.userid === nextMessage.author?.userid);
};

const hasTimeGapBetweenMessages = (thisMessage, nextMessage) => {
    if (thisMessage == null || nextMessage == null) {
        return true;
    }
    const thisDate = new Date(thisMessage.created);
    const nextDate = new Date(nextMessage.created);
    return Math.abs(nextDate.getTime() - thisDate.getTime()) >= TIME_GAP_IN_MILLISECONDS;
};

export const hasTimeGap = (messages, index) => {
    return {
        before: hasTimeGapBetweenMessages(messages[index + 1], messages[index]),
        after: hasTimeGapBetweenMessages(messages[index], messages[index - 1]),
    }
};

export const getMessageDetailsForRendering = (user, messages, index) => {
    const message = messages[index];
    const isMyMessage = message?.author?.userid === user?.pk;
    const isLastMessage = isLastMessageFromSameAuthor(messages, index);
    const isLastSimulated = isLastSimulatedMessage(messages, index);
    const timeGap = hasTimeGap(messages, index);
    return {
        isMyMessage: isMyMessage,
        isLastMessage: isLastMessage,
        isLastSimulated: isLastSimulated,
        showThumbnail: isLastMessage || timeGap.after,
        compressBottomMargin: !(isLastMessage || timeGap.after || isLastSimulated),
        showTimestamp: timeGap.before && messages[index]?.type !== MessageType.SIMULATED,
        formattedTimestamp: timeGap.before && formatDate(message?.created),
        showSimulatedNonAction: isLastSimulated && index > 0,
        showSimulatedAction: index === 0 && (messages[index]?.type === MessageType.SIMULATED),
        showRequestedAction: index === 0 && !isMyMessage && (messages[index]?.type === MessageType.REQUEST),
        showRequestedNonAction: index === 0 && isMyMessage && (messages[index]?.type === MessageType.REQUEST),
    }
};