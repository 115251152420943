import { useState, useEffect } from 'react';
import { AppState as nativeAppState } from 'react-native';

export const AppState = Object.freeze({
    unknown: "unknown",
    background: "background",
    foreground: "foreground"
});

// this is a wrapper of react-native AppState so that
// the state of the app can be accessed as a state
export const useAppState = () => {
    // Convert nativeAppState to our custom AppState
    const convertToAppState = (nativeState) => {
        switch (nativeState) {
            case 'active':
                return AppState.foreground;
            case 'inactive':
            case 'background':
                return AppState.background;
            default:
                return AppState.unknown;
        }
    };

    // Set the initial state using the conversion function
    const [appState, setAppState] = useState(() => convertToAppState(nativeAppState.currentState));

    useEffect(() => {
        const handleChange = (nextAppState) => {
            console.log(`App has transitioned to: ${nextAppState}`);
            setAppState(convertToAppState(nextAppState));
        }

        const h = nativeAppState.addEventListener('change', handleChange);

        return () => {
            console.log("remove app state change listener");
            h.remove();
        }
    }, []);

    return appState;
}