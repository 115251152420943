// screens/AssistantScreen.js
import { useCallback, useEffect, useState, useRef } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import {
    View, Keyboard, KeyboardAvoidingView, Platform, FlatList, StyleSheet,
    Linking,
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { SafeAreaView } from 'react-native-safe-area-context';
import { resetNavigation } from '../utils/navigation';
import { removeTokenAndUser, deleteUserData } from '../actions/userActions';
import { startAssistant } from '../services/chatService';
import { fetchMessages, MessageWebSocketService } from '../services/messageService';
import MessageInput from '../components/MessageInput';
import { getUserCosmeticColor } from '../utils/userUtils';
import { resetNavigationFromUserStatus } from '../utils/navigation';
import ChatCTAButton from '../components/ChatCTAButton';
import SystemChatHeader from '../components/SystemChatHeader';
import FlatListFooter from '../components/FlatListFooter';
import { useScreenDimensions, useRelativeStyles } from '../utils/screenDimensions';
import SystemChatBubbleBody from '../components/SystemChatBubbleBody';
import { UserThumbnailSize } from '../utils/userUtils';
import { MessageType } from '../models/Message';
import HintBubble from '../components/assistant/HintBubble';
import { shareLink } from '../utils/share';
import { openBoostMenu } from '../actions/boostActions';
import PageViewWrapper from '../firebase/components/pageViewWrapper';
import {
    BUTTON_CLICK_ASSISTANT_BUY_BOOST,
    BUTTON_CLICK_ASSISTANT_EMAIL_SUPPORT,
    BUTTON_CLICK_ASSISTANT_SHARE_PROFILE,
    BUTTON_CLICK_ASSISTANT_LOGOUT,
    BUTTON_CLICK_ASSISTANT_DELETE_ACCOUNT,
    BUTTON_CLICK_ASSISTANT_GO_BACK,
} from '../firebase/constants';
import { logMessageSent } from '../firebase/utils';
import ButtonClickWrapper from '../firebase/components/buttonClickWrapper';

const AssistantScreen = ({ navigation }) => {
    const styles = StyleSheet.create(useRelativeStyles({
        container: {
            flexGrow: 1,
            justifyContent: 'flex-end',
            rlw_paddingTop: 10,
        },
    }));

    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const { windowHeight } = useScreenDimensions();
    const [messages, setMessages] = useState([]);
    const [messageInput, setMessageInput] = useState('');
    const [sendEnabled, setSendEnabled] = useState(false);
    const [keyboardHeight, setKeyboardHeight] = useState(0);
    const [headerHeight, setHeaderHeight] = useState(0);
    const [messageInputHeight, setMessageInputHeight] = useState(0);
    const [isReceivingStream, setIsReceivingStream] = useState(false);
    const [page, setPage] = useState(0);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [showBuyBoostButton, showShowBuyBoostButton] = useState(false);
    const [showSupportButton, setShowSupportButton] = useState(false);
    const [showShareButton, setShowShareButton] = useState(false);
    const [showExitButton, setShowExitButton] = useState(false);
    const [showLogoutButton, setShowLogoutButton] = useState(false);
    const [showDeleteUserButton, setShowDeleteUserButton] = useState(false);
    const relevantLinkRef = useRef('');
    const chatIdRef = useRef(null);
    const hasMorePagesRef = useRef(true);
    const wsRef = useRef(null);
    const flatListRef = useRef();
    const textInputRef = useRef(null);

    useEffect(() => {
        const subKDS = Keyboard.addListener('keyboardDidShow', (e) => {
            setKeyboardHeight(e.endCoordinates.height);
        });
        const subKDH = Keyboard.addListener('keyboardDidHide', () => {
            setKeyboardHeight(0);
        });

        // cleanup function
        return () => {
            subKDS.remove();
            subKDH.remove();
        };
    }, []);

    useFocusEffect(
        useCallback(() => {
            const initChatAndConnection = async () => {
                try {
                    chatIdRef.current = await startAssistant();
                    if (chatIdRef.current != null) {
                        await handleLoadMore(true);
                        wsRef.current = await MessageWebSocketService.init(chatIdRef.current);
                        wsRef.current.setOnMessageCallback(handleReceive);
                        wsRef.current.openConnection();
                    } else {
                        handleGoBack();
                    }
                } catch (error) {
                    if (error.response.status === 401) {
                        resetNavigation(navigation);
                    } else if (error.message.includes('Network Error') || error.message.includes('timeout')) {
                        showNetworkError();
                    } else {
                        console.error('Error initialize WebSocket connection:', error);
                    }
                }
            }

            if (user === null) {
                resetNavigation(navigation);
            } else {
                initChatAndConnection();
            }

            return () => {
                if (wsRef.current) {
                    wsRef.current.closeConnection();
                }
            };
        }, [navigation])
    );

    useEffect(() => {
        return () => {
            if (wsRef.current) {
                wsRef.current.closeConnection();
            }
        };
    }, []);

    const handleClose = () => {
        const messagePayload = {
            type: 'chat_closing',
            messagetype: MessageType.ASSISTANCE,
        };
        wsRef.current.sendMessage(messagePayload);
        handleGoBack();
    };

    const handleGoBack = () => {
        if (navigation.canGoBack()) {
            navigation.goBack();
        } else {
            resetNavigationFromUserStatus(navigation, user?.status);
        }
    };

    const handleMessageChange = (text) => {
        setMessageInput(text);
        setSendEnabled(text.length !== 0);
    };

    const handleMessageSubmit = () => {
        if (messageInput !== '') {
            // Send a message through the WebSocket
            const messagePayload = {
                type: 'chat_message_send',
                message: messageInput,
                messagetype: MessageType.ASSISTANCE,
            };
            wsRef.current.sendMessage(messagePayload);
            // analytics
            logMessageSent(MessageType.ASSISTANCE, 'None', messageInput);
            // Clear the input field
            setMessageInput('');
            setSendEnabled(false);
        }
    };

    const handleScrollLayout = (e) => {
        if (keyboardHeight === 0) {
            // this is triggered by a keyboard opening event
            flatListRef.current?.scrollToOffset({ offset: 0, animated: true });
        }
    };

    const handleReceive = (event) => {
        const data = JSON.parse(event.data);

        switch (data.type) {
            case 'chat_typing':
                if (data.start) {
                    setMessages((prevMessages) => [{
                        author: {
                            userid: data.userid,
                            gender: data.usergender,
                        },
                        content: ''
                    }, ...prevMessages]);
                    setIsReceivingStream(true);
                } else {
                    setMessages((prevMessages) => {
                        let newMessages = [...prevMessages];
                        if (hasMorePagesRef.current) {
                            newMessages.pop();
                        }
                        return newMessages;
                    });
                    setIsReceivingStream(false);
                    wsRef.current.sendMessage({
                        type: 'chat_message_ack',
                        messageid: data.messageid,
                    });
                }
                break;
            case 'chat_message':
                setMessages((prevMessages) => {
                    let newMessages = [{
                        author: {
                            userid: data.userid,
                            gender: data.usergender,
                            cosmeticMetadata: data.userid === user?.pk ?
                                user.cosmeticMetadata : undefined,
                            thumbnail: data.userid === user?.pk ?
                                user.thumbnail : undefined,
                        },
                        content: data.message,
                        type: data.messagetype,
                    }, ...prevMessages];
                    if (hasMorePagesRef.current) {
                        newMessages.pop();
                    }
                    return newMessages;
                });
                wsRef.current.sendMessage({
                    type: 'chat_message_ack',
                    messageid: data.messageid,
                });
                break;
            case 'chat_message_chunk':
                setMessages((prevMessages) => {
                    if (prevMessages.length > 0) {
                        return [{
                            ...prevMessages[0],
                            content: prevMessages[0].content + data.message,
                        }, ...prevMessages.slice(1),];
                    } else {
                        // If there are no previous messages, just add the new chunk as a new message
                        return [{
                            author: {
                                userid: data.userid,
                                gender: data.usergender,
                            },
                            content: data.message
                        }];
                    }
                });
                break;
            case 'assist_buy_a_boost':
                textInputRef.current.blur();
                showShowBuyBoostButton(true);
                break;
            case 'assist_email_for_support':
                textInputRef.current.blur();
                setShowSupportButton(true);
                break;
            case 'assist_send_to_friends':
                textInputRef.current.blur();
                relevantLinkRef.current = data.relevant_link;
                setShowShareButton(true);
                break;
            case 'assist_others':
                textInputRef.current.blur();
                setShowExitButton(true);
                break;
            case 'assist_logout':
                textInputRef.current.blur();
                setShowLogoutButton(true);
                break;
            case 'assist_delete_account':
                textInputRef.current.blur();
                setShowDeleteUserButton(true);
                break;
            default:
                console.error('Received unknown event type:', data.type);
        }
    };

    const handleLoadMore = async () => {
        if (isLoadingMore ||
            !hasMorePagesRef.current ||
            chatIdRef.current == null)
            return;
        setIsLoadingMore(true);
        try {
            const nextPage = page + 1;
            const currentMessages = await fetchMessages(chatIdRef.current, nextPage, 30);
            setMessages(prevMessages => [...prevMessages, ...currentMessages.messages]);
            setPage(nextPage);
            hasMorePagesRef.current = currentMessages.hasMorePages;
        } catch (error) {
            if (error.response.status === 401) {
                resetNavigation(navigation);
            } else if (error.message.includes('Network Error') || error.message?.includes('timeout')) {
                showNetworkError();
            } else {
                console.error('Error fetching messages:', error);
            }
        }
        setIsLoadingMore(false);
    };

    const handleSetText = (text) => {
        setMessageInput(messageInput + text);
        textInputRef.current.focus();
        setSendEnabled(true);
    };

    const BuyBoostButton = ButtonClickWrapper(({ onPress }) => (
        <ChatCTAButton textCTA={'Buy Boost'} onPress={onPress} />
    ), BUTTON_CLICK_ASSISTANT_BUY_BOOST);

    const EmailSupportButton = ButtonClickWrapper(({ onPress }) => (
        <ChatCTAButton
            textCTA={'Email support'}
            onPress={onPress}
        />
    ), BUTTON_CLICK_ASSISTANT_EMAIL_SUPPORT);

    const ShareProfileButton = ButtonClickWrapper(({ onPress }) => (
        <ChatCTAButton
            textCTA={'Share profile'}
            onPress={onPress}
            action={'share'}
        />
    ), BUTTON_CLICK_ASSISTANT_SHARE_PROFILE);

    const GoBackButton = ButtonClickWrapper(({ onPress }) => (
        <ChatCTAButton
            textCTA={'Go back'}
            onPress={onPress}
        />
    ), BUTTON_CLICK_ASSISTANT_GO_BACK);

    const LogoutButton = ButtonClickWrapper(({ onPress }) => (
        <ChatCTAButton
            textCTA={'Return to Home'}
            onPress={onPress}
        />
    ), BUTTON_CLICK_ASSISTANT_LOGOUT);

    const DeleteUserButton = ButtonClickWrapper(({ onPress }) => (
        <ChatCTAButton
            textCTA={'Delete My Account'}
            onPress={onPress}
        />
    ), BUTTON_CLICK_ASSISTANT_DELETE_ACCOUNT);

    return (
        <View style={{ flex: 1, backgroundColor: 'transparent' }}>
            <SafeAreaView edges={['bottom',]} style={{ flex: 1 }}>
                <KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" : "height"} style={{ flex: 1 }}>
                    <View style={{ flex: 1, justifyContent: 'flex-end' }}>
                        <SystemChatHeader
                            isDark={true}
                            onLayout={(event) => {
                                const { height } = event.nativeEvent.layout;
                                setHeaderHeight(height);
                            }}
                        />
                        <FlatList
                            inverted
                            ref={flatListRef}
                            data={messages.slice(0)}
                            renderItem={({ item, index }) => {
                                return <SystemChatBubbleBody
                                    message={item}
                                    isMyMessage={item.author.userid === user?.pk}
                                    thumbnailSize={UserThumbnailSize.SMALL}
                                    isStreaming={index === 0 && isReceivingStream}
                                />
                            }}
                            keyExtractor={(item, index) => index.toString()}
                            contentContainerStyle={styles.container}
                            style={{
                                flex: 1,
                                backgroundColor: 'transparent',
                                maxHeight: Platform.OS === 'web' ?
                                    windowHeight - headerHeight - keyboardHeight - messageInputHeight : undefined
                            }}
                            ListFooterComponent={
                                <FlatListFooter isDark={true} isLoadingMore={isLoadingMore} />
                            }
                            ListHeaderComponent={
                                <View>
                                    {messages.length == 1 && <HintBubble
                                        user={user}
                                        setText={handleSetText}
                                    />}
                                    {
                                        showBuyBoostButton
                                        ? <BuyBoostButton
                                            onPress={() => dispatch(openBoostMenu())}
                                        />
                                        : showSupportButton
                                        ? <EmailSupportButton
                                            onPress = {() =>
                                                Linking.openURL('mailto:support@volardating.com')
                                            }
                                        />
                                        : showShareButton
                                        ? <ShareProfileButton
                                            onPress = {() => {
                                                shareLink(relevantLinkRef.current)
                                            }}
                                        />
                                        : showExitButton
                                        ? <GoBackButton onPress={handleGoBack} />
                                        : showLogoutButton
                                        ? <LogoutButton
                                            onPress={async () => {
                                                await dispatch(removeTokenAndUser());
                                                resetNavigation(navigation);
                                            }}
                                        />
                                        : showDeleteUserButton
                                        ? <DeleteUserButton
                                            onPress={async () => {
                                                await dispatch(deleteUserData());
                                                resetNavigation(navigation);
                                            }}
                                        />
                                        : null
                                    }
                                </View>
                            }
                            onEndReached={handleLoadMore}
                            onEndReachedThreshold={0.1}
                            onLayout={handleScrollLayout}
                        />
                        <MessageInput
                            ref={textInputRef}
                            onLayout={(event) => {
                                const { height } = event.nativeEvent.layout;
                                setMessageInputHeight(height);
                            }}
                            value={messageInput}
                            userColor={getUserCosmeticColor(user)}
                            sendEnabled={
                                sendEnabled &&
                                !isReceivingStream &&
                                !showBuyBoostButton &&
                                !showSupportButton &&
                                !showShareButton &&
                                !showExitButton &&
                                !showLogoutButton
                            }
                            onChange={handleMessageChange}
                            onSubmit={handleMessageSubmit}
                            onPressLogo={handleClose}
                            hasLogo={true}
                            autoFocus={false}
                            isDark={true}
                            boostable={false}
                            isAssistantPage={true}
                        />
                    </View>
                </KeyboardAvoidingView>
            </SafeAreaView>
        </View>
    );
};

export default PageViewWrapper(AssistantScreen);
