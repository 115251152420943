import { View, Text, Image, StyleSheet, TouchableOpacity } from "react-native";
import { useRelativeStyles } from "../utils/screenDimensions";
import Drawer from "./common/drawer";
import { CancelXButton } from "./common/closeButton";
import Logo, { LogoSize } from "./Logo";

const MatchRequestModal = ({
    onClose,
    onConfirm,
    message,
    buttonText,
}) => {
    const styles = StyleSheet.create(useRelativeStyles({
        overlay: {
            flex: 1,
            backgroundColor: "rgba(0,0,0,0.5)",
            justifyContent: "center",
            alignItems: "center",
        },
        modalContainer: {
            rlw_width: 350,
            rlw_height: 307,
            backgroundColor: "white",
            alignItems: "center",
            rlw_borderRadius: 12,
        },
        iconWrapper: {
            position: "absolute",
            // icon size is relative to width not height
            // since it's a circle
            rlw_top: -50,
            rlw_width: 100,
            rlw_height: 100,
            rlw_borderRadius: 50,
            rlw_borderWidth: 10,
            borderColor: "white",
            backgroundColor: "#F3F631",
            justifyContent: "center",
            alignItems: "center",
        },
        messageWrapper: {
            rlw_marginTop: 94,
            flex: 1,
            rlw_width: 300,
        },
        message: {
            rlw_fontSize: 30,
            rlw_marginBottom: 20,
            textAlign: "center",
            fontFamily: "BricolageGrotesque_SemiBold",
            fontStyle: "normal",
        },
        button: {
            flexDirection: "row",
            alignItems: "center",
            rlw_width: 256,
            rlw_height: 49,
            backgroundColor: "#F3F631",
            rlw_borderRadius: 28,
            rlw_marginBottom: 39,
        },
        buttonText: {
            color: "black",
            rlw_fontSize: 14,
            overflow: "visible",
            fontFamily: "BricolageGrotesque_Regular",
            flex: 1,
            rlw_marginLeft: 16,
            rlw_marginRight: 12,
            textAlign: "center",
        },
        verticalDivider: {
            height: "100%",
            width: 1,
            backgroundColor: "black",
            marginHorizontal: 0,
        },
        arrowContainer: {
            height: "100%",
            rlw_width: 49,
            justifyContent: "center",
            alignItems: "center",
        },
        arrow: {
            rlw_width: 16,
            rlw_height: 14,
            resizeMode: "stretch",
            transform: [{ rotate: "180deg" }],
        }
    }));

    const icon = require("../assets/common/notification/request_icon.png");
    const arrowLeft = require("../assets/arrows/arrow.left.sf_light.png");

    const RightArrow = () => {
        return (
            <Image
                source={arrowLeft}
                style={styles.arrow}
            />
        );
    };

    const WrappedLogo = () => {
        return (
            <View style={styles.iconWrapper}>
                <Logo userColor="black" otherUserColor="black" logoSize={LogoSize.MIDDLE} style={styles.Logo}/>
            </View>
        )
    }

    return (
        <View style={styles.overlay}>
            <View style={styles.modalContainer}>
                <CancelXButton onPress={onClose} color="#5A5A5A"/>
                <WrappedLogo/>
                <View style={styles.messageWrapper}>
                    <Text style={styles.message}>{message}</Text>
                </View>
                <TouchableOpacity style={styles.button} onPress={onConfirm}>
                    <Text style={styles.buttonText}>{buttonText}</Text>
                    <View style={styles.verticalDivider} />
                    <View style={styles.arrowContainer}>
                        <RightArrow/>
                    </View>
                </TouchableOpacity>
            </View>
        </View>
    );
};

export default Drawer(MatchRequestModal);
