// components/assistant/HintButton.js
import {
    View,
    Text,
    StyleSheet,
    TouchableOpacity
} from "react-native";
import { useRelativeStyles } from "../../utils/screenDimensions";
import {
    getUserCosmeticColor,
} from '../../utils/userUtils';
import ButtonClickWrapper from "../../firebase/components/buttonClickWrapper";
import { BUTTON_CLICK_ASSISTANT_HINT } from "../../firebase/constants";

const HintButton = ButtonClickWrapper(({ user, onPress, text }) => {
    const styles = StyleSheet.create(useRelativeStyles({
        container: {
            backgroundColor: 'white',
            rlw_height: 34,
            rlw_borderRadius: 16,
            rlw_marginRight: 6,
        },
        button: {
            backgroundColor: getUserCosmeticColor(user) + '4D',
            alignItem: 'center',
            justifyContent: 'center',
            rlw_height: 34,
            rlw_borderRadius: 16,
        },
        text: {
            fontFamily: 'IBMPlexSans_400Regular',
            color: '#8A8A8A',
            textAlignVertical: 'center',
            textAlign: 'center',
            rlw_fontSize: 14,
            rlw_lineHeight: 17.5,
            rlw_marginHorizontal: 8,
        },
    }));

    return (
        <View style={styles.container}>
            <TouchableOpacity style={styles.button} onPress={onPress} >
                <Text style={styles.text}>
                    {text}
                </Text>
            </TouchableOpacity>
        </View>
    );
}, BUTTON_CLICK_ASSISTANT_HINT);

export default HintButton;