// components/BlinkingCursor.js

import React, { useEffect, useRef } from 'react';
import { Animated } from 'react-native';

const BlinkingCursor = () => {
    const blinkingOpacity = useRef(new Animated.Value(1)).current;

    const startBlinking = () => {
        Animated.loop(
            Animated.sequence([
                Animated.timing(blinkingOpacity, {
                    toValue: 0,
                    duration: 500,
                    useNativeDriver: false,
                }),
                Animated.timing(blinkingOpacity, {
                    toValue: 1,
                    duration: 500,
                    useNativeDriver: false,
                }),
            ])
        ).start();
    };

    useEffect(() => {
        startBlinking();
    }, []);

    return (
        <Animated.Text style={{ opacity: blinkingOpacity }}>
            ⬤
        </Animated.Text>
    );
};

export default BlinkingCursor;