// store.js

import { configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/userReducer';
import boostReducer from './reducers/boostReducer';
import referralReducer from './reducers/referralReducer';

const store = configureStore({
    reducer: {
        user: userReducer,
        boost: boostReducer,
        referral: referralReducer,
    },
});

export default store;