// utils/navigation.js
import { CommonActions } from '@react-navigation/native';
import { UserStatus } from '../models/User';
import { fetchUserDetails } from '../actions/userActions';

export const getRoutesFromUserStatus = (userStatus) => {
    switch (userStatus) {
        case UserStatus.PRE_REGISTERED:
        case UserStatus.REGISTERED:
            return 'Onboarding';
        case UserStatus.ONBOARDED:
            return 'UploadImage';
        case UserStatus.PROFILE_COMPLETE:
            return 'Chats';
        default:
            return 'Home';
    }
};

export const resetNavigation = (navigation, routeName='Home', params={}) => {
    navigation.dispatch(
        CommonActions.reset({
            index: 0,
            routes: [
                { name: routeName , params },
            ],
        })
    );
};

export const resetNavigationFromUserStatus = (navigation, userStatus) => {
    navigation.dispatch(
        CommonActions.reset({
            index: 0,
            routes: [
                { name: getRoutesFromUserStatus(userStatus) },
            ],
        })
    );
};

export const resetNavigationByKey = async (navigation, dispatch) => {
    const result = await dispatch(fetchUserDetails());
    if (result.success) {
        resetNavigationFromUserStatus(navigation, result.user.status);
    } else {
        resetNavigation(navigation);
    }
};