// components/chats/EmptyState.js
import { View, StyleSheet, Image, Text, TouchableOpacity } from "react-native";
import { useRelativeStyles } from "../../utils/screenDimensions";
import { shareLink } from "../../utils/share";
import { createShareURL } from "../../services/shareService";
import ButtonClickWrapper from "../../firebase/components/buttonClickWrapper";
import { BUTTON_CLICK_CHATS_SHARE_PROFILE } from "../../firebase/constants";

const EmptyState = () => {
    const styles = StyleSheet.create(useRelativeStyles({
        container: {
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            rlw_marginVertical: 10,
        },
        largeText: {
            fontFamily: 'IBMPlexSans_400Regular',
            rlw_fontSize: 16,
            rlw_lineHeight: 20,
            rlw_marginBottom: 5,
            color: '#999',
        },
        smallText: {
            fontFamily: 'IBMPlexSans_700Bold',
            rlw_fontSize: 14,
            rlw_lineHeight: 17.5,
            color: '#999',
        },
        image: {
            rlw_width: 24,
            rlw_height: 20,
            tintColor: '#999',
            resizeMode: 'contain',
        },
    }));
    const shareImage = require('../../assets/arrows/square.and.arrow.up.sf_bold.png');

    const handleShare = async () => {
        const link = await createShareURL();
        await shareLink(link);
    };

    const ShareProfileButton = ButtonClickWrapper(({onPress}) =>
        <TouchableOpacity
            style={{ flexDirection: 'row' }}
            activeOpacity={0.7}
            onPress={onPress}
        >
            <Image source={shareImage} style={styles.image} />
            <Text style={styles.smallText} selectable={false}>
                Share your profile{' '}
            </Text>
        </TouchableOpacity>
    , BUTTON_CLICK_CHATS_SHARE_PROFILE)

    return (
        <View style={styles.container}>
            <Text style={styles.largeText} selectable={false}>
                Looking for more?
            </Text>
            <View style={{ flexDirection: 'row' }}>
                <ShareProfileButton onPress={handleShare} />
                <Text style={[styles.smallText, { fontFamily: 'IBMPlexSans_400Regular' }]} selectable={false}>
                        to get directly matched!
                </Text>
            </View>
        </View>
    );
};

export default EmptyState;