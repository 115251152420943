// components//upload/UploadScrollView.js
import { ScrollView, StyleSheet, Platform, Text, View } from "react-native";
import { useRoute, useNavigation } from '@react-navigation/native';
import { useRelativeStyles } from "../../utils/screenDimensions";
import UserBio from '../../components/upload/UserBio';
import ProfileImage from '../../components/upload/ProfileImage';
import BoostIndicator from '../../components/boost/indicator';
import { getDisplayTime } from "../../utils/chatUtils";
import { resetNavigation } from "../../utils/navigation";

const UploadScrollView = ({
    maxHeight, profileImages, isEditing, targetUser,
    handlePressDelete, handlePressUpload, handleLongPress, handleImagePress,
}) => {
    const styles = StyleSheet.create(useRelativeStyles({
        view: {
            flex: 1,
            maxHeight: Platform.OS === 'web' ? maxHeight : undefined,
            paddingTop: 0,
        },
        header: {
            rlw_width: 393,
            // per design the padding should 18, but there is a 12px padding from
            // the ScrollView and another 12px margin from first profile image
            // height: 64 - 36 = 28
            rlw_height: 28,
            rlw_paddingHorizontal: 24,
            display: "flex",
            flexDirection: 'row',
            backgroundColor: 'white',
            justifyContent: 'space-between',
        },
        text: {
            fontFamily: 'IBMPlexSans_400Regular',
            alignSelf: 'center',
            rlw_fontSize: 16,
            rlw_lineHeight: 20,
            color: '#999',
        },
        content: {
            justifyContent: 'flex-start',
            alignItems: 'center',
            rlw_paddingTop: 12,
            rlw_paddingBottom: Platform.OS === 'web' ? 72 : 88,
        },
    }));
    const indices = [1, 2, 3, 4, 5];
    const chatId = useRoute().params?.chatId;
    const navigation = useNavigation()

    return (
        <ScrollView style={styles.view} contentContainerStyle={styles.content}>
            <View style={styles.header}>
                {targetUser != null ?
                    <Text style={styles.text}>
                        {'You matched ' + getDisplayTime(new Date(targetUser.datetimeMatched)) + ' ago'}
                    </Text> : <View/>
                }
                { targetUser == null || chatId != null ?
                    <BoostIndicator
                        purchaseOnly={targetUser == null}
                        sideEffect={() => {
                            if (targetUser && chatId) {
                                // this is someone else's profile, click boost
                                // might result in redirecting to chat
                                resetNavigation(navigation, "Chat", {chatId: chatId})
                            }
                        }}
                    />: <View/>
                }
            </View>
            <ProfileImage images={profileImages} index={0}
                isEditing={isEditing}
                onPressUpload={handlePressUpload}
                onPressDelete={() => handlePressDelete(0)}
                onLongPress={handleLongPress}
                onImagePress={handleImagePress}
            />
            <UserBio targetUser={targetUser} />
            {indices.map((idx) => (
                <ProfileImage images={profileImages} index={idx} key={idx}
                    isEditing={isEditing}
                    onPressUpload={handlePressUpload}
                    onPressDelete={() => handlePressDelete(idx)}
                    onLongPress={handleLongPress}
                    onImagePress={handleImagePress}
                />
            ))}
        </ScrollView>
    );
};

export default UploadScrollView;