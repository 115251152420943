// components/chats/ChatBlock.js
import { useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import {
    View, Text, TouchableOpacity, StyleSheet, Image,
} from 'react-native';
import { useSelector } from 'react-redux';
import UserThumbnail from '../UserThumbnail';
import { UserThumbnailSize, getUserCosmeticColor } from '../../utils/userUtils';
import { useScreenDimensions } from '../../utils/screenDimensions';
import { getChatDisplayTypeAndText } from '../../utils/chatUtils';
import { BUTTON_CLICK_CHATS_CHAT } from '../../firebase/constants';
import ButtonClickWrapper from '../../firebase/components/buttonClickWrapper';

const styles = StyleSheet.create({
    chatBlock: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
    },
    chatDetail: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    chatText: {
        color: '#333',
        flexShrink: 1,
    },
    chatTime: {
        color: '#828282',
        textAlign: 'right',
    },
});

const BaseChatBlock = ButtonClickWrapper(({ chat, onPress }) => {
    const user = useSelector((state) => state.user);
    const { adjustedWidth } = useScreenDimensions();
    const configValues = {
        height: adjustedWidth * 0.1730,
        paddingHorizontal: adjustedWidth * 0.0611,
        chatMarginHorizontal: adjustedWidth * 0.0204,
        fontSize: adjustedWidth * 0.0407,
        lineHeight: adjustedWidth * 0.0509,
        textMargin: adjustedWidth * 0.0102,
        timeWidth: adjustedWidth * 0.1018,
    };
    const chatDisplay = getChatDisplayTypeAndText(chat, user);
    const [isPressed, setIsPressed] = useState(false);


    const handlePressIn = () => {
        setIsPressed(true);
    };

    const handlePressOut = () => {
        setIsPressed(false);
    };

    const getTextFont = (chatDisplay) => {
        return chatDisplay.isUnread ? 'IBMPlexSans_700Bold' : 'IBMPlexSans_400Regular';
    }

    const boostLogo = require('../../assets/boost/boostLogo.png')

    return (
        <TouchableOpacity testID='chat-block-button' style={[styles.chatBlock, {
            width: adjustedWidth,
            height: configValues.height,
            paddingHorizontal: configValues.paddingHorizontal,
            backgroundColor: isPressed ? 'ghostwhite' : 'white'
        }]}
            activeOpacity={1}
            onPressIn={handlePressIn}
            onPressOut={handlePressOut}
            onPress={onPress}
        >
            <UserThumbnail
                user={chatDisplay.otherUser}
                thumbnailSize={UserThumbnailSize.LARGE}
                hasBorder={false}
                onPress={onPress}
                isDark={false}
            />
            <View style={[styles.chatDetail, {
                marginHorizontal: configValues.chatMarginHorizontal,
            }]}>
                <Text style={[styles.chatText, {
                    color: getUserCosmeticColor(chatDisplay.otherUser),
                    fontFamily: getTextFont(chatDisplay),
                    fontSize: configValues.fontSize,
                    lineHeight: configValues.lineHeight,
                    marginBottom: configValues.textMargin,
                }]} numberOfLines={1} ellipsizeMode='tail'>
                    {chatDisplay.chatName}
                    {chatDisplay.isBoosted
                    ? <Image source={boostLogo} style={{
                        width: configValues.fontSize,
                        height: configValues.fontSize,
                    }}/>
                    : ''}
                </Text>
                <Text style={[styles.chatText, {
                    fontFamily: getTextFont(chatDisplay),
                    fontSize: configValues.fontSize,
                    lineHeight: configValues.lineHeight,
                }]} numberOfLines={1} ellipsizeMode='tail'>
                    {chatDisplay.displayText}
                </Text>
            </View>
            <Text style={[styles.chatTime, {
                fontFamily: getTextFont(chatDisplay),
                width: configValues.timeWidth,
                fontSize: configValues.fontSize,
                lineHeight: configValues.lineHeight,
            }]}>
                {chatDisplay.displayTime}
            </Text>
        </TouchableOpacity>
    );
}, BUTTON_CLICK_CHATS_CHAT);

const WrappedChatBlock = ({chat, analyticsProps}) => {
    const navigation = useNavigation();

    return <BaseChatBlock
        chat={chat}
        onPress={
            () => navigation.navigate('Chat', { chatId: chat.id })
        }
        analyticsProps={{
            ...analyticsProps,
            chat_id: chat.id
        }}
    />
};
export default WrappedChatBlock