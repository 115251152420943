// components/ChatCTAButton.js
import {
    View,
    TouchableOpacity,
    Image,
    StyleSheet,
    Text,
} from 'react-native';
import { useScreenDimensions } from '../utils/screenDimensions';

const styles = StyleSheet.create({
    container: {
        justifyContent: 'flex-start',
        alignItems: 'center',
        alignSelf: 'center',
    },
    button: {
        flex: 1,
        alignSelf: 'flex-start',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    text: {
        fontFamily: 'IBMPlexSans_700Bold',
        color: 'white',
    },
    image: {
        tintColor: 'white',
        resizeMode: 'contain',
    },
});

const ChatCTAButton = ({ textCTA, onPress, action='default' }) => {
    const { adjustedWidth } = useScreenDimensions();
    const configValues = {
        chatCTAMarginHorizontal: adjustedWidth * 0.1069,
        chatCTAMarginTop: adjustedWidth * 0.0153,
        chatCTAMarginBottom: adjustedWidth * 0.0509,
        chatCTAMarginInBetween: adjustedWidth * 0.0204,
        chatCTAFontSize: adjustedWidth * 0.0407,
        chatCTALineHeight: adjustedWidth * 0.0560,
        chatCTAImagePath: {
            default: require('../assets/arrows/arrow.right.circle.sf_bold.png'),
            share: require('../assets/arrows/square.and.arrow.up.sf_bold.png'),
        }
    };

    return (
        <View style={[styles.container, {
            width: adjustedWidth,
        }]}>
            <TouchableOpacity style={[styles.button, {
                marginHorizontal: configValues.chatCTAMarginHorizontal,
                marginTop: configValues.chatCTAMarginTop,
                marginBottom: configValues.chatCTAMarginBottom,
            }]} onPress={onPress}>
                <Text style={[styles.text, {
                    fontSize: configValues.chatCTAFontSize,
                    lineHeight: configValues.chatCTALineHeight,
                    marginRight: configValues.chatCTAMarginInBetween,
                }]}>
                    {textCTA}
                </Text>
                <Image source={configValues.chatCTAImagePath[action]} style={[styles.image, {
                    width: configValues.chatCTALineHeight,
                    height: configValues.chatCTALineHeight,
                }]} />
            </TouchableOpacity>
        </View>
    );
};

export default ChatCTAButton;