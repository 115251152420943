// notification/reducer.js
export default class Queue {
    // Reducer logic
    static defaultState = {
        q: [],
        popElement: undefined,
        lastPush: undefined
    }

    static reducer(state, action) {
        // partial thread-safe, avoid having multiple consumer
        // on this queue
        switch (action.type) {
            case 'PUSH':
                // push doesn't change popElement, only pull would
                return {
                    ...state,
                    q: [...state.q, action.item],
                    lastPush: Date.now()
                }
            case 'POP':
                // pop is not thread-safe when there is concurrent pop
                /// but it's thread-safe when there is concurrent push
                const [popElement, ...remainingQueue] = state.q;
                return {
                    ...state,
                    q: remainingQueue,
                    popElement,
                }
            default:
                return state;
        }
    }

    // Helper functions (optional) to create specific actions
    static push(item) {
        return { type: 'PUSH', item };
    }

    static pop() {
        return { type: 'POP' };
    }
}
