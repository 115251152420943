// actions/userActions.js

import { getUserDetails, deleteUser } from "../services/authService"
import { showNetworkError } from '../utils/errorHandlers';
import { removeToken } from "../services/common";
import { refreshBoostCount } from "./boostActions";

export const setUser = (user) => {
    return {
        type: 'SET_USER',
        payload: user,
    };
};

export const removeUser = () => ({
    type: 'REMOVE_USER'
});

export const updateCosmeticMetadata = (cosmeticMetadata) => {
    return {
        type: 'UPDATE_COSMETIC_METADATA',
        payload: cosmeticMetadata,
    };
};

export const removeTokenAndUser = () => {
    return (dispatch) => {
        return removeToken()
            .then(() => {
                dispatch(removeUser());
            })
            .catch(error => {
                console.error('Error in removing token: ', error);
            });
    }
};

export const fetchUserDetails = () => {
    return (dispatch) => {
        return getUserDetails()
            .then(userDetails => {
                dispatch(setUser(userDetails));
                dispatch(refreshBoostCount());
                return { success: true, user: userDetails };
            })
            .catch(error => {
                console.info('Error in fetching user details: ', error);
                if (error.message?.includes('Network Error') || error.message?.includes('timeout')) {
                    showNetworkError();
                } else {
                    dispatch(removeTokenAndUser());
                }
                return { success: false };
            });
    }
};

export const deleteUserData = () => {
    return (dispatch) => {
        deleteUser().then(() => {
            dispatch(removeTokenAndUser());
        })
    }
}