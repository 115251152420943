// services/chatService.js

import axios from 'axios';
import {
    CHAT_URL, CHAT_HISTORY_URL, WS_CHAT_LIST_URL,
    START_ASSISTANT_URL,
} from '../urlconfig';
import { getToken } from './common';
import { createChat } from '../models/Chat';

export async function startAssistant() {
    try {
        const token = await getToken();
        const response = await axios.post(START_ASSISTANT_URL, {}, {
            headers: {
                Authorization: `Token ${token}`,
            },
        });

        if (response.status === 201) {
            return response.data.chat_id;
        } else {
            console.error('Error in starting assistant: ', response);
            return null;
        }
    } catch (error) {
        if (error.message?.includes('Network Error') || error.message?.includes('timeout')) {
            showNetworkError();
        } else {
            console.error('Error in starting assistant: ', error);
        }
        return null;
    }
};

export const fetchChats = async (page, pageSize) => {
    const url = `${CHAT_URL}?page=${page}&page_size=${pageSize}`;
    try {
        const token = await getToken();
        const response = await axios.get(url, {
            headers: {
                Authorization: `Token ${token}`,
            },
        });
        const chats = response.data.results.map((chat) => (createChat(chat)));
        return {
            chats: chats,
            raw_data: response.data,
        };
    } catch (error) {
        console.error("Error fetching chats:", error);
        throw error;
    }
};

export const fetchAllChats = async (page, pageSize, prevChats = []) => {
    try {
        const chats = await fetchChats(page, pageSize);
        const allChats = [...prevChats, ...chats.chats];
        if (chats.raw_data.next) {
            return fetchAllChats(page + 1, pageSize, allChats);
        } else {
            return allChats;
        }
    } catch (error) {
        console.error("Error fetching chats:", error);
        throw error;
    }
};

export const fetchChat = async (chatId) => {
    const url = `${CHAT_HISTORY_URL}${chatId}/`;
    try {
        const token = await getToken();
        const response = await axios.get(url, {
            headers: {
                Authorization: `Token ${token}`,
            },
        });
        const chat = createChat(response.data);
        return chat;
    } catch (error) {
        console.error("Error fetching chat:", error);
        throw error;
    }
};

export class ChatWebSocketService {
    // WebSocket that manages the chat list for the user
    // This is also a passive WebSocket, as it only receives updates but never sends
    // To use:
    //   1. call init() first to create an instance
    //   2. call setOnChatUpdateCallback() to register callback function to receive updates
    //   3. call openConnection() to establish connections with backend
    //   .. n. remember to call closeConnection() to release resource in the end
    constructor(token) {
        this.token = token;
        this.wsUrl = `${WS_CHAT_LIST_URL}?token=${this.token}`;
        this.reconnectAttempts = 0;
        this.maxReconnectAttempts = 10;
    }

    static async init() {
        const token = await getToken();
        return new ChatWebSocketService(token);
    }

    openConnection() {
        this.websocket = new WebSocket(this.wsUrl);

        this.websocket.onopen = () => {
            console.log('Chat WebSocket connection opened');
            this.reconnectAttempts = 0;
        };

        this.websocket.onmessage = this.onChatUpdateCallback;

        this.websocket.onerror = (error) => {
            console.error('Chat WebSocket encountered error:', error);
        };

        this.websocket.onclose = (event) => {
            console.log('Chat WebSocket connection closed:', event);

            // retry 10 times if the disconnection is caused by network issue
            if (!event.wasClean && this.reconnectAttempts < this.maxReconnectAttempts) {
                this.reconnectAttempts++;
                let timeout = this.reconnectAttempts * 1000;
                console.log(`Attempting to reconnect in ${timeout} ms...`);
                setTimeout(() => this.openConnection(), timeout);
            }
        };
    }

    setOnChatUpdateCallback(callback) {
        this.onChatUpdateCallback = callback;
    }

    closeConnection() {
        this.reconnectAttempts = this.maxReconnectAttempts;
        this.websocket.close();
    }
};