// models/CosmeticMetadata.js

export const createCosmeticMetadata = (data) => {
    if (data == null) {
        return {
            color: "#333333",
            completeness: 0,
        };
    } else {
        return {
            color: data.color,
            completeness: data.completeness,
        };
    }
};