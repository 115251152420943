// reducers/userReducer.js

const userReducer = (state = null, action) => {
    switch (action.type) {
        case 'SET_USER':
            return action.payload;
        case 'REMOVE_USER':
            return null;
        case 'UPDATE_COSMETIC_METADATA':
            return {
                ...state,
                cosmeticMetadata: action.payload
            };
        default:
            return state;
    }
}

export default userReducer;