import React from "react";
import { View, TouchableOpacity, Text, Image, StyleSheet } from "react-native";
import { useSelector, useDispatch } from "react-redux";

import { openPurchaseMenu, startBoosting, stopBoosting } from "../../actions/boostActions";
import { useRelativeStyles } from "../../utils/screenDimensions";

const BoostIndicator = ({ sideEffect = () => {}, purchaseOnly }) => {
    const boostCount = useSelector((state) => state.boost.boostCount);
    const isBoosting = useSelector((state) => state.boost.isBoosting);
    const dispatch = useDispatch();

    const boostIcon = require("../../assets/boost/boostLogo.png");

    const styles = StyleSheet.create(
        useRelativeStyles({
            container: {
                justifyContent: "center",
            },
            button: {
                flexDirection: "row",
                backgroundColor: isBoosting ? "#269877" : "#E8E8E8",
                rlw_borderRadius: 10,
                rlw_paddingVertical: 4,
                rlw_paddingHorizontal: 7,
            },
            counter: {
                fontFamily: "IBMPlexSans_400Regular",
                rlw_fontSize: 16,
            },
            icon: {
                rlw_width: 22,
                rlw_height: 22,
            },
        })
    );

    return (
        <View style={styles.container}>
            <TouchableOpacity
                style={styles.button}
                testID="boost-indicator-button"
                onPress={() => {
                    if (purchaseOnly) {
                        dispatch(openPurchaseMenu())
                    } else {
                        if (!isBoosting) {
                            dispatch(startBoosting());
                            sideEffect();
                        } else {
                            dispatch(stopBoosting());
                        }
                    }
                }}
            >
                <Text style={styles.counter}>{boostCount}</Text>
                <Image style={styles.icon} source={boostIcon} />
            </TouchableOpacity>
        </View>
    );
};

export default BoostIndicator;
