import React from 'react';
import { useFocusEffect, useRoute } from '@react-navigation/native';
import { logScreenView } from '../utils';

const PageViewWrapper = (WrappedComponnet) => {
    const Wrapper =  (props) => {
        const route = useRoute();

        useFocusEffect(
            React.useCallback(() => {
                let name = route.name
                let params = undefined;
                switch (name) {
                    case "Chat":
                        params = {
                            chat_id: route.params?.chatId,
                        }
                        break;
                    case "UploadImage":
                        params = {
                            other_user_id: route.params?.userId,
                        }
                        break;
                    default:
                        params = undefined
                }

                logScreenView(name, params);
            }, [])
        );

        return <WrappedComponnet {...props}/>
    }

    Wrapper.displayName = `PageViewWrapper(${Object.prototype.toString.call(WrappedComponnet)})`;

    return Wrapper

}

export default PageViewWrapper;