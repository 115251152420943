// actions/boostActions.js
import {
    getBoostCount, getBoostPurchaseOptions
} from "../services/boostService";
import {
    CreateBoostPurchaseOption
} from "../models/BoostPurchaseOption";

export const actions = {
    BoostMenuOpen: "BoostMenuOpen",
    BoostMenuClose: "BoostMenuClose",
    PurchaseMenuOpen: "PurchaseMenuOpen",
    SetBoostCount: "SetBoostCount",
    ReduceBoost: "ReduceBoost",
    SetBoostPurchaseOptions: "SetBoostPurchaseOptions",
    StartBoosting: "StartBoosting",
    StopBoosting: "StopBoosting",
}

export const openBoostMenu = () => {
    return {
        type: actions.BoostMenuOpen,
    }
}

export const closeBoostMenu = () => {
    return {
        type: actions.BoostMenuClose,
    }
}

export const reduceBoost = () => {
    return {
        type: actions.ReduceBoost,
    }
}

export const openPurchaseMenu = () => {
    return {
        type: actions.PurchaseMenuOpen,
    }
}

export const refreshBoostCount = () => async (dispatch) => {
    try{
        getBoostCount().then(count => {
            dispatch({
                type: actions.SetBoostCount,
                payload: count,
            })
        })
    } catch(ex) {
        console.error("failed to fetch user boost count,", ex)
    }
}

export const refreshBoostPurchaseOptions = () => async (dispatch) => {
    try {
        getBoostPurchaseOptions().then(options => {
            dispatch({
                type: actions.SetBoostPurchaseOptions,
                payload: options.map(
                    option => CreateBoostPurchaseOption(option)
                ),
            })
        })
    } catch(ex) {
        console.error("failed to fetch boost purchase options,", ex)
    }
}

export const startBoosting = () => {
    return {
        type: actions.StartBoosting,
    }
}

export const stopBoosting = () => {
    return {
        type: actions.StopBoosting,
    }
}