import { createUser } from "./User";

// models/Notification.js
export const NotificationState = {
    UNKNOWN: "UNKNOWN",
    FIRST_MATCH: "FIRST_MATCH",
    DAILY_MATCH: "DAILY_MATCH",
    REQUEST: "REQUEST",
    ACCEPTED_REQUEST: "ACCEPTED_REQUEST",
    MESSAGE: "MESSAGE",
};

export const createNotification = (data) => {
    if (data == null) {
        return null
    }

    let fromUser = data.from_user ? createUser(data.from_user) : null

    return {
        type: data.type,
        message: data.message,
        fromUser: fromUser,
        type: data.type,
        chatId: data.chat_id,
    };
};
