// models/ProfileImage.js

export const createProfileImage = (data) => {
    if (data == null) {
        return null;
    } else {
        return {
            profileImageId: data.profile_image_id,
            order: data.order,
            presignedURL: data.presigned_url,
        };
    }
};