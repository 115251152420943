// notification/handler.js
// this is for in-app notification system using ws connect to probius
import { useEffect, useContext, useReducer } from "react";
import { Keyboard, Platform } from "react-native";
import { NotificationContext } from "./provider";
import MatchRequestModal from "../components/MatchRequestModal";
import NewMatchingModal from "../components/NewMatchingModal";
import { NotificationState } from "../models/Notification";
import { logNotification } from "../firebase/utils";
import { NOTIFICATION_FOREGROUND_RECEIVED } from "../firebase/constants";

const ACTION_HIDDEN_MODAL = "HIDDEN_MODAL";

const defaultState = {
    isShowModal: false,
    modalType: undefined,
    message: "",
    btnText: "",
    chatId: undefined,
    fromUser: undefined,
};

const reducer = (state, action) => {
    console.log("in reducer: pick up an action:", action);
    switch (action.type) {
        case NotificationState.FIRST_MATCH:
            return {
                isShowModal: true,
                modalType: NotificationState.FIRST_MATCH,
                message: "You have \na new match!",
                btnText: "Read Volar's first date",
                chatId: action.payload.chatId,
                fromUser: action.payload.fromUser,
            };
        case NotificationState.DAILY_MATCH:
            return {
                isShowModal: true,
                modalType: NotificationState.DAILY_MATCH,
                message: "You have \na new match!",
                btnText: "Read Volar's new date",
                chatId: action.payload.chatId,
                fromUser: action.payload.fromUser,
            };
        case NotificationState.REQUEST:
            return {
                isShowModal: true,
                modalType: NotificationState.REQUEST,
                message: "You have a new request!",
                btnText: "View your request",
                chatId: action.payload.chatId,
                fromUser: undefined,
            };
        case NotificationState.ACCEPTED_REQUEST:
            return {
                isShowModal: true,
                modalType: NotificationState.ACCEPTED_REQUEST,
                message: "Your request\nhas been accepted!",
                btnText: "View your chat",
                chatId: action.payload.chatId,
                fromUser: undefined,
            };
        case ACTION_HIDDEN_MODAL:
            return {
                isShowModal: false,
                modalType: undefined,
                message: "",
                btnText: "",
                chatId: undefined,
                fromUser: undefined,
            };
        default:
            return state;
    }
};

// NotificationHandler will present a modal when there is a new notification
export const NotificationHandler = ({ navigate }) => {
    const { modalQueue, popModalQueue } = useContext(NotificationContext);
    const [state, dispatch] = useReducer(reducer, defaultState);
    const closeModal = () => {
        dispatch({ type: ACTION_HIDDEN_MODAL });
    };

    const onConfirm = () => {
        dispatch({ type: ACTION_HIDDEN_MODAL });
        navigate("Chat", { chatId: state.chatId });
    };

    useEffect(() => {
        console.log("new notificaiton pushed");
        if (state.isShowModal) {
            // stop popping notification if the modal is showing
            console.log("modal is showing, skip popping");
            return;
        }

        console.log("popping notification");
        if (popModalQueue) {
            console.log("popModalQueue is not null, poping");
            popModalQueue();
        }
    }, [modalQueue.lastPush, state.isShowModal]);

    useEffect(() => {
        if (modalQueue.popElement == null) {
            console.log("no new notification");
            return;
        }

        console.log("new notification received");
        let notificaiton = modalQueue.popElement;
        console.log("notification:", notificaiton);

        dispatch({
            type: notificaiton.type,
            payload: {
                chatId: notificaiton.chatId,
                fromUser: notificaiton.fromUser,
            },
        });
    }, [modalQueue.popElement]);

    useEffect(() => {
        // hide keyboard
        if (Platform.OS === "web") {
            if (document.activeElement) {
                document.activeElement.blur();
            }
        } else {
            Keyboard.dismiss();
        }

        // analytics
        if (state.isShowModal) {
            logNotification(NOTIFICATION_FOREGROUND_RECEIVED, {
                notification_type: state.modalType
            })
        }
    }, [state.isShowModal]);

    return [
        <NewMatchingModal
            key="newmatchingmodal"
            isVisible={
                state.isShowModal &&
                (state.modalType === NotificationState.FIRST_MATCH ||
                    state.modalType === NotificationState.DAILY_MATCH)
            }
            message={state.message}
            buttonText={state.btnText}
            fromUser={state.fromUser}
            onClose={closeModal}
            onConfirm={onConfirm}
        />,
        <MatchRequestModal
            key="matchrequestmodal"
            isVisible={
                state.isShowModal &&
                (state.modalType === NotificationState.REQUEST ||
                    state.modalType === NotificationState.ACCEPTED_REQUEST)
            }
            message={state.message}
            buttonText={state.btnText}
            fromUser={state.fromUser}
            onClose={closeModal}
            onConfirm={onConfirm}
        />,
    ];
};
