// screens/OnboardingScreen.js
import { useEffect, useState, useRef } from 'react';
import { SafeAreaView } from 'react-native-safe-area-context';
import {
    View,
    KeyboardAvoidingView,
    FlatList,
    Platform,
    Keyboard,
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import ReactGA from "react-ga4";
import { fetchUserDetails, removeTokenAndUser } from '../actions/userActions';
import { useScreenDimensions } from '../utils/screenDimensions';
import { updateToken } from '../services/authService';
import { fetchMessages, MessageWebSocketService } from '../services/messageService';
import { showNetworkError } from '../utils/errorHandlers';
import { resetNavigation, resetNavigationByKey } from '../utils/navigation';
import MessageInput from '../components/MessageInput';
import { UserStatus } from '../models/User';
import { getMessageTypeFromUserStatus } from '../utils/chatUtils';
import { getCosmeticColor, animateColorTransition } from '../utils/cosmetics';
import { updateCosmeticMetadata } from '../actions/userActions';
import { createCosmeticMetadata } from '../models/CosmeticMetadata';
import ChatCTAButton from '../components/ChatCTAButton';
import SystemChatHeader from '../components/SystemChatHeader';
import FlatListFooter from '../components/FlatListFooter';
import SystemChatBubbleBody from '../components/SystemChatBubbleBody';
import { UserThumbnailSize } from '../utils/userUtils';
import PageViewWrapper from '../firebase/components/pageViewWrapper';
import { logMessageSent } from '../firebase/utils';
import ButtonClickWrapper from '../firebase/components/buttonClickWrapper';
import {
    BUTTON_CLICK_ONBOARDING_CONTINUE,
    BUTTON_CLICK_ONBOARDING_UPLOAD_PHOTO,
    BUTTON_CLICK_ONBOARDING_LOGOUT
} from '../firebase/constants';

const OnboardingScreen = ({ navigation }) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const { windowHeight, adjustedWidth } = useScreenDimensions();
    const configValues = {
        scrollViewPaddingBottom: adjustedWidth * 0.0254,
    };
    const [cosmeticColor, setCosmeticColor] = useState('#33333300');
    const [messages, setMessages] = useState([]);
    const [messageInput, setMessageInput] = useState('');
    const [sendEnabled, setSendEnabled] = useState(false);
    const [keyboardHeight, setKeyboardHeight] = useState(0);
    const [headerHeight, setHeaderHeight] = useState(0);
    const [messageInputHeight, setMessageInputHeight] = useState(0);
    const [isReceivingStream, setIsReceivingStream] = useState(false);
    const [page, setPage] = useState(0);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [showSignInButton, setShowSignInButton] = useState(false);
    const [showUploadButton, setShowUploadButton] = useState(false);
    const [showHomeButton, setShowHomeButton] = useState(false);
    const hasMorePagesRef = useRef(true);
    const wsRef = useRef(null);
    const flatListRef = useRef();
    const textInputRef = useRef(null);
    const cosmeticColorRef = useRef(cosmeticColor);
    const userStatusRef = useRef(UserStatus.PRE_REGISTERED);

    const ContinueButton = ButtonClickWrapper((props) => (
        <ChatCTAButton {...props} />
    ), BUTTON_CLICK_ONBOARDING_CONTINUE);

    const UploadButton = ButtonClickWrapper((props) => (
        <ChatCTAButton {...props} />
    ), BUTTON_CLICK_ONBOARDING_UPLOAD_PHOTO);

    const LogoutButton = ButtonClickWrapper((props) => (
        <ChatCTAButton {...props}/>
    ), BUTTON_CLICK_ONBOARDING_LOGOUT);

    useEffect(() => {
        const subKDS = Keyboard.addListener('keyboardDidShow', (e) => {
            setKeyboardHeight(e.endCoordinates.height);
        });
        const subKDH = Keyboard.addListener('keyboardDidHide', () => {
            setKeyboardHeight(0);
        });

        // cleanup function
        return () => {
            subKDS.remove();
            subKDH.remove();
        };
    }, []);

    useEffect(() => {
        const initConnection = async () => {
            try {
                wsRef.current = await MessageWebSocketService.init(user.onboardingChat.id);
                wsRef.current.setOnMessageCallback(handleReceive);
                wsRef.current.openConnection();
            } catch (error) {
                if (error.response.status === 401) {
                    resetNavigation(navigation);
                } else if (error.message.includes('Network Error') || error.message.includes('timeout')) {
                    showNetworkError();
                } else {
                    console.error('Error initialize WebSocket connection:', error);
                }
            }
        }

        if (user === null) {
            resetNavigation(navigation);
        } else {
            setCosmeticColor(getCosmeticColor(user.cosmeticMetadata));
            userStatusRef.current = user.status;
            if ([UserStatus.ONBOARDED, UserStatus.PROFILE_COMPLETE].includes(user.status)) {
                setShowUploadButton(true);
            } else if (!user.onboardingChat?.isActive) {
                setShowHomeButton(true);
            }
            handleLoadMore();
            initConnection();
        }

        return () => {
            if (wsRef.current) {
                wsRef.current.closeConnection();
            }
        };
    }, []);

    useEffect(() => {
        cosmeticColorRef.current = cosmeticColor;
    }, [cosmeticColor]);

    useEffect(() => {
        return () => {
            if (wsRef.current) {
                wsRef.current.closeConnection();
            }
        };
    }, []);

    const handleMessageChange = (text) => {
        setMessageInput(text);
        setSendEnabled(text.length !== 0);
    };

    const handleMessageSubmit = () => {
        if (messageInput !== '') {
            // Send a message through the WebSocket
            const messagePayload = {
                type: 'chat_message_send',
                message: messageInput,
                messagetype: getMessageTypeFromUserStatus(userStatusRef.current),
            };
            wsRef.current.sendMessage(messagePayload);

            try {
                logMessageSent("Onboarding", user.onboardingChat.id, messageInput)
            } catch (error) {
                console.error("failed to log message:", error)
            }

            // Clear the input field
            setMessageInput('');
            setSendEnabled(false);
        }
    };

    const handleScrollLayout = (e) => {
        if (keyboardHeight === 0) {
            // this is triggered by a keyboard opening event
            flatListRef.current?.scrollToOffset({ offset: 0, animated: true });
        }
    }

    const handleLoadMore = async () => {
        if (isLoadingMore || !hasMorePagesRef.current) return;
        setIsLoadingMore(true);
        try {
            const nextPage = page + 1;
            const currentMessages = await fetchMessages(user.onboardingChat.id, nextPage, 30);
            setMessages(prevMessages => [...prevMessages, ...currentMessages.messages]);
            setPage(nextPage);
            hasMorePagesRef.current = currentMessages.hasMorePages;
        } catch (error) {
            if (error.response.status === 401) {
                resetNavigation(navigation);
            } else if (error.message.includes('Network Error') || error.message?.includes('timeout')) {
                showNetworkError();
            } else {
                console.error('Error fetching messages:', error);
            }
        }
        setIsLoadingMore(false);
    }

    const handleReceive = (event) => {
        const data = JSON.parse(event.data);

        switch (data.type) {
            case 'chat_typing':
                if (data.start) {
                    setMessages((prevMessages) => [{
                        author: {
                            userid: data.userid,
                            gender: data.usergender,
                        },
                        content: ''
                    }, ...prevMessages]);
                    setIsReceivingStream(true);
                } else {
                    setMessages((prevMessages) => {
                        let newMessages = [...prevMessages];
                        if (hasMorePagesRef.current) {
                            newMessages.pop();
                        }
                        return newMessages;
                    });
                    setIsReceivingStream(false);
                    wsRef.current.sendMessage({
                        type: 'chat_message_ack',
                        messageid: data.messageid,
                    });
                }
                break;
            case 'chat_message':
                setMessages((prevMessages) => {
                    let newMessages = [{
                        author: {
                            userid: data.userid,
                            gender: data.usergender,
                        },
                        content: data.message,
                        type: data.messagetype,
                    }, ...prevMessages];
                    if (hasMorePagesRef.current) {
                        newMessages.pop();
                    }
                    return newMessages;
                });
                wsRef.current.sendMessage({
                    type: 'chat_message_ack',
                    messageid: data.messageid,
                });
                break;
            case 'chat_message_chunk':
                setMessages((prevMessages) => {
                    if (prevMessages.length > 0) {
                        return [{
                            ...prevMessages[0],
                            content: prevMessages[0].content + data.message,
                        }, ...prevMessages.slice(1),];
                    } else {
                        // If there are no previous messages, just add the new chunk as a new message
                        return [{
                            author: {
                                userid: data.userid,
                                gender: data.usergender,
                            },
                            content: data.message
                        }];
                    }
                });
                break;
            case 'chat_update_metadata':
                dispatch(updateCosmeticMetadata(
                    createCosmeticMetadata(data.metadata)
                ));
                const oldHexColor = cosmeticColorRef.current;
                const newHexColor = getCosmeticColor(data.metadata);
                animateColorTransition(
                    oldHexColor, newHexColor, setCosmeticColor
                );
                break;
            case 'user_signed_in':
                updateToken(data.user_token_key);
                textInputRef.current.blur();
                setShowSignInButton(true);
                break;
            case 'user_signed_up':
                userStatusRef.current = UserStatus.REGISTERED;
                break;
            case 'user_onboarded':
                userStatusRef.current = UserStatus.ONBOARDED;
                textInputRef.current.blur();
                setShowUploadButton(true);
                break;
            case 'user_registration_failed':
                textInputRef.current.blur();
                setShowHomeButton(true);
                break;
            default:
                console.error('Received unknown event type:', data.type);
        }
    };

    return (
        <View style={{ flex: 1, backgroundColor: 'white' }}>
            <SafeAreaView edges={['bottom',]} style={{ flex: 1 }}>
                <KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" : "height"} style={{ flex: 1 }}>
                    <View style={{ flex: 1, justifyContent: 'flex-end' }}>
                        <SystemChatHeader
                            isDark={false}
                            cosmeticColor={cosmeticColor}
                            onLayout={(event) => {
                                const { height } = event.nativeEvent.layout;
                                setHeaderHeight(height);
                            }}
                        />
                        <FlatList
                            inverted
                            ref={flatListRef}
                            data={messages.slice(0)}
                            renderItem={({ item, index }) => {
                                return <SystemChatBubbleBody
                                    message={item}
                                    isMyMessage={item.author.userid === user?.pk}
                                    cosmeticColor={cosmeticColor}
                                    thumbnailSize={UserThumbnailSize.SMALL}
                                    isStreaming={index === 0 && isReceivingStream}
                                />
                            }}
                            keyExtractor={(item, index) => index.toString()}
                            contentContainerStyle={{
                                flexGrow: 1,
                                justifyContent: 'flex-end',
                                paddingTop: configValues.scrollViewPaddingBottom,
                            }}
                            style={{
                                flex: 1,
                                backgroundColor: '#000000',
                                maxHeight: Platform.OS === 'web' ?
                                    windowHeight - headerHeight - keyboardHeight - messageInputHeight : undefined
                            }}
                            ListFooterComponent={
                                <FlatListFooter isDark={true} isLoadingMore={isLoadingMore} />
                            }
                            ListHeaderComponent={
                                <View>
                                    {
                                        showSignInButton
                                        ? <ContinueButton
                                            textCTA={'Continue'}
                                            onPress={() => {
                                                resetNavigationByKey(navigation, dispatch);
                                            }}
                                        />
                                        : showUploadButton
                                        ? <UploadButton
                                            textCTA={'Upload photos'}
                                            onPress={async () => {
                                                if (Platform.OS === 'web') {
                                                    ReactGA.event({
                                                        category: "User",
                                                        action: "UploadClick",
                                                    });
                                                    if (typeof fbq !== 'undefined') {
                                                        ('trackCustom', 'UploadClick', {});
                                                    }
                                                }
                                                await dispatch(fetchUserDetails());
                                                navigation.navigate('UploadImage');
                                            }}
                                        />
                                        : showHomeButton
                                        ? <LogoutButton
                                            textCTA={'Back to homepage'}
                                            onPress={async () => {
                                                await dispatch(removeTokenAndUser());
                                                resetNavigation(navigation);
                                            }}
                                        />
                                        : undefined
                                    }
                                </View>
                            }
                            onEndReached={handleLoadMore}
                            onEndReachedThreshold={0.1}
                            onLayout={handleScrollLayout}
                        />
                        <MessageInput
                            ref={textInputRef}
                            onLayout={(event) => {
                                const { height } = event.nativeEvent.layout;
                                setMessageInputHeight(height);
                            }}
                            value={messageInput}
                            sendEnabled={
                                sendEnabled &&
                                !isReceivingStream &&
                                !showSignInButton &&
                                !showUploadButton &&
                                !showHomeButton
                            }
                            onChange={handleMessageChange}
                            onSubmit={handleMessageSubmit}
                            onPressLogo={() => navigation.navigate('Assistant')}
                            hasLogo={false}
                            autoFocus={true}
                            isDark={false}
                            userColor={cosmeticColor.slice(0, 7)}
                        />
                    </View>
                </KeyboardAvoidingView>
            </SafeAreaView>
        </View>
    );
};

export default PageViewWrapper(OnboardingScreen);