// components/upload/UploadImageHeader.js
import { View, TouchableOpacity, Text, StyleSheet, Platform, Image } from 'react-native';
import { useSelector } from 'react-redux';
import Svg, { Rect, Mask, Image as SVGImage } from 'react-native-svg';
import { useRelativeStyles } from '../../utils/screenDimensions';
import { resetNavigationFromUserStatus } from '../../utils/navigation';
import { UserStatus } from '../../models/User';
import { getUserDisplayName } from '../../utils/userUtils';
import ButtonClickWrapper from '../../firebase/components/buttonClickWrapper';
import { BUTTON_CLICK_UPLOAD_PHOTO_BACK, BUTTON_CLICK_UPLOAD_PHOTO_CONTINUE } from '../../firebase/constants';

const UploadImageHeader = ({
    cosmeticColor, cosmeticFontColor, onLayout, numOfImages,
    onConfirm, onShare, onUser,
    navigation, hasEdited, targetUser,
}) => {
    const styles = StyleSheet.create(useRelativeStyles({
        header: {
            flexDirection: 'row',
            backgroundColor: 'white',
            justifyContent: 'center',
            alignItems: 'flex-end',
            rlw_height: Platform.OS === 'web' ? 54 :
                (Platform.isPad ? 70 : 100),
        },
        background: {
            position: 'absolute',
            left: 0,
            bottom: 0,
            zIndex: 1,
            width: '100%',
            height: '100%',
        },
        container: {
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'center',
            zIndex: 2,
            rlw_height: 54,
        },
        backButton: {
            justifyContent: 'center',
            alignItems: 'center',
            rlw_width: 74,
        },
        buttonImage: {
            rlw_width: 22,
            rlw_height: 22,
            tintColor: cosmeticFontColor,
            resizeMode: 'contain',
        },
        dotImage: {
            rlw_width: 22,
            rlw_height: 16,
            tintColor: cosmeticFontColor,
            resizeMode: 'contain',
        },
        textView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        text: {
            fontFamily: 'IBMPlexSans_700Bold',
            rlw_fontSize: 16,
            rlw_lineHeight: 20,
            color: cosmeticFontColor,
        },
        forwardButton: {
            justifyContent: 'center',
            alignItems: 'center',
            rlw_width: 74,
        },
        buttonText: {
            fontFamily: 'IBMPlexSans_700Bold',
            rlw_fontSize: 13,
            rlw_lineHeight: 16.25,
            color: cosmeticFontColor,
        },
    }));
    const headerImagePath = require('../../assets/common/chat/header_mask.png');
    const headerBackImagePath = require('../../assets/arrows/arrow.left.sf_light.png');
    const sharePath = require('../../assets/arrows/square.and.arrow.up.sf_bold.png');
    const dotPath = require('../../assets/arrows/ellipsis.rotated.sf_thin.png');
    const user = useSelector(state => state.user);

    const getHeaderText = (numOfImages) => {
        if (targetUser != null) {
            return getUserDisplayName(targetUser);
        } else {
            if (user?.status === UserStatus.PROFILE_COMPLETE && !hasEdited) {
                return "My profile";
            } else {
                return (numOfImages == 6 ?
                    'Photo upload complete!' :
                    'Add profile photos (' + numOfImages + '/6)');
            }
        }
    };

    const handleForward = async () => {
        if (targetUser != null) {
            onUser();
        } else {
            if (user?.status === UserStatus.PROFILE_COMPLETE && !hasEdited) {
                await onShare();
            } else {
                if (numOfImages === 6) {
                    await onConfirm();
                }
            }
        }
    };

    const BackButton = ButtonClickWrapper(({onPress}) => (
        <TouchableOpacity
            activeOpacity={0.5}
            style={styles.backButton}
            onPress={onPress}
        >
            {targetUser == null &&
                user?.status === UserStatus.PROFILE_COMPLETE &&
                hasEdited ?
                <Text style={styles.buttonText}>Cancel</Text> :
                <Image source={headerBackImagePath} style={styles.buttonImage} />
            }
        </TouchableOpacity>
    ), BUTTON_CLICK_UPLOAD_PHOTO_BACK);

    const ForwardButton = ButtonClickWrapper(({onPress}) => (
        <TouchableOpacity
            activeOpacity={0.5}
            style={styles.forwardButton}
            onPress={onPress}
            disabled={targetUser == null && numOfImages < 6}
        >
            {targetUser != null ?
                <Image source={dotPath} style={styles.dotImage} /> :
                (user?.status === UserStatus.PROFILE_COMPLETE ?
                    (hasEdited ?
                        <Text style={[styles.buttonText, {
                            color: numOfImages < 6 ? '#A4A4A4' : cosmeticFontColor,
                        }]}>Confirm</Text> :
                        <Image source={sharePath} style={styles.buttonImage} />) :
                    (numOfImages < 6 ?
                        undefined :
                        <Text style={styles.buttonText}>Confirm</Text>
                    )
                )
            }
        </TouchableOpacity>
    ), BUTTON_CLICK_UPLOAD_PHOTO_CONTINUE);

    return (
        <View style={styles.header} onLayout={onLayout}>
            <View style={styles.background}>
                <Svg width="100%" height="100%" position='absolute'>
                    <Mask id="mask" x="0" y="0" height="100%" width="100%">
                        <SVGImage width="100%" height="100%"
                            preserveAspectRatio="xMidYMax slice"
                            href={headerImagePath}
                        />
                    </Mask>
                    <Rect x="0" y="0" width="100%" height="100%"
                        fill={cosmeticColor}
                        mask="url(#mask)"
                    />
                </Svg>
            </View>
            <View style={styles.container}>
                <BackButton onPress={() => 
                    navigation.canGoBack()
                    ? navigation.goBack()
                    : resetNavigationFromUserStatus(navigation, user?.status)}
                />
                <View style={styles.textView}>
                    <Text style={styles.text} selectable={false}>
                        {getHeaderText(numOfImages)}
                    </Text>
                </View>
                <ForwardButton onPress={handleForward}/>
            </View>
        </View>
    );
};

export default UploadImageHeader;