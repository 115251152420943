import * as React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { useEffect, useState, useRef } from 'react';
import { Platform, Linking } from 'react-native';
import * as ScreenOrientation from 'expo-screen-orientation';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import * as SplashScreen from 'expo-splash-screen';
import * as Font from 'expo-font';
import ReactGA from "react-ga4";
import {
    IBMPlexSans_400Regular,
    IBMPlexSans_700Bold,
} from '@expo-google-fonts/ibm-plex-sans'
import { Provider, useDispatch } from 'react-redux';
import store from './store';
import HomeScreen from './screens/HomeScreen';
import OnboardingScreen from './screens/OnboardingScreen';
import UploadImageScreen from './screens/UploadImageScreen';
import ChatListScreen from './screens/ChatListScreen';
import ChatScreen from './screens/ChatScreen';
import AssistantScreen from './screens/AssistantScreen';
import Analytics from './firebase/components/analytics.js';
import {
    cardStyleInterpolatorForSlideFromRight,
    cardStyleInterpolatorForSlideFromBottom,
} from './utils/animations';
import linkings from './utils/linkings';
import { fetchUserDetails } from './actions/userActions';
import { getRoutesFromUserStatus, resetNavigation } from './utils/navigation';
import * as Notifications from 'expo-notifications';
import { backgroundNotificationHandler } from './utils/notificationHandlers';
import NotificationProvider from './notification/provider';
import { NotificationHandler } from './notification/handler';
import BoostMenuModal from './components/boost/BoostMenuModal';
import { storeInvitationCode, storeUTMSource } from './actions/referralActions';
import { useInvitationCode } from './services/shareService';
import TermsScreen from './screens/TermsScreen';
import PrivacyScreen from './screens/PrivacyScreen';
import FacebookPixel from './components/FacebookPixel';
import { initFbSdk } from './facebook/fbsdk';

SplashScreen.preventAutoHideAsync();

const Stack = createStackNavigator();

function ReduxApp() {
    const [fontsLoaded, setFontsLoaded] = useState(false);
    const [initialRoute, setInitialRoute] = useState('Home');
    const dispatch = useDispatch();

    const responseListener = useRef();
    const navigationRef = useRef(null);

    const navigationPush = (path, params) => {
        resetNavigation(navigationRef.current, path, params);
    }

    useEffect(() => {
        responseListener.current = Notifications.addNotificationResponseReceivedListener(resp => {
            handleNotification = (resp) => {
                // when the app is resurrected from tombstoned, it takes a
                // significant amount of time for navigationRef.current to be ready
                // so we need to make sure it's ready before redirection
                if (navigationRef.current) {
                    backgroundNotificationHandler(resp, navigationPush)
                } else {
                    // wait for navigation ref to be ready,
                    // notice, this is a hack but there is no other way
                    // to detect if navigationRef.current is ready or not
                    // since it cannot be used as an dependency in useEffect
                    setTimeout(handleNotification, 500, resp)
                }
            }
            
            handleNotification(resp);
        });

        return () => {
            Notifications.removeNotificationSubscription(responseListener.current);
        };
    }, []);

    useEffect(() => {
        if (Platform.OS === 'web') {
            ReactGA.initialize('G-XEGD8RXEMY');
        } else {
            initFbSdk();
        }
    }, []);

    useEffect(() => {
        const handleDeepLink = async (event) => {
            const url = event.url;
            const match = url.match(/invitation=([^&]+)/);
            const invitationCode = match ? match[1] : null;
            if (invitationCode) {
                console.log("Invatation code stored: " + invitationCode);
                dispatch(storeInvitationCode(invitationCode));
                await useInvitationCode();
            }
            const matchUTM = url.match(/utm_source=([^&]+)/);
            const UTM = matchUTM ? matchUTM[1] : null;
            if (UTM) {
                console.log("UTM source stored: " + UTM);
                dispatch(storeUTMSource(UTM));
            }
        };

        async function prepare() {
            try {
                await Font.loadAsync({
                    'BricolageGrotesque_Regular': require('./assets/fonts/bricolage-grotesque/BricolageGrotesque-Regular.ttf'),
                    'BricolageGrotesque_Medium': require('./assets/fonts/bricolage-grotesque/BricolageGrotesque-Medium.ttf'),
                    'BricolageGrotesque_Bold': require('./assets/fonts/bricolage-grotesque/BricolageGrotesque-Bold.ttf'),
                    'BricolageGrotesque_SemiBold': require('./assets/fonts/bricolage-grotesque/BricolageGrotesque-SemiBold.ttf'),
                    IBMPlexSans_400Regular,
                    IBMPlexSans_700Bold,
                });
                if (Platform.OS === "web") {
                    const params = new URLSearchParams(window.location.search);
                    const invitationCode = params.get('invitation');
                    if (invitationCode) {
                        console.log("Invatation code stored: " + invitationCode);
                        dispatch(storeInvitationCode(invitationCode));
                        await useInvitationCode();
                    }
                    const UTM = params.get('utm_source');
                    if (UTM) {
                        console.log("UTM source stored: " + UTM);
                        dispatch(storeUTMSource(UTM));
                    }
                } else {
                    Linking.addEventListener('url', handleDeepLink);
                    await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
                }
                const userDetails = await dispatch(fetchUserDetails());
                if (userDetails.success) {
                    setInitialRoute(getRoutesFromUserStatus(userDetails.user.status));
                }
            } catch (e) {
                console.warn(e);
            } finally {
                setFontsLoaded(true);
                await SplashScreen.hideAsync();
            }
        }

        prepare();
    }, [dispatch]);

    if (!fontsLoaded) {
        return null;
    }

    return (
        <NavigationContainer linking={linkings} ref={navigationRef}>
            <Analytics/>
            <NotificationHandler navigate={navigationPush} />
            <BoostMenuModal />
            <Stack.Navigator
                initialRouteName={initialRoute}
                screenOptions={{ headerShown: false, cardStyleInterpolator: cardStyleInterpolatorForSlideFromRight }}
            >
                <Stack.Screen name="Home" component={HomeScreen} />
                <Stack.Screen name="Terms" component={TermsScreen} />
                <Stack.Screen name="Privacy" component={PrivacyScreen} />
                <Stack.Screen name="Onboarding" component={OnboardingScreen} />
                <Stack.Screen name="UploadImage" component={UploadImageScreen} />
                <Stack.Screen name="Chats" component={ChatListScreen} />
                <Stack.Screen name="Chat"
                    component={ChatScreen}
                    initialParams={{ chatId: '' }}
                />
                <Stack.Screen name="Assistant" component={AssistantScreen}
                    options={{
                        cardStyle: { backgroundColor: 'rgba(0, 0, 0, 0.92)' },
                        presentation: 'transparentModal',
                        cardStyleInterpolator: cardStyleInterpolatorForSlideFromBottom,
                    }}
                />
            </Stack.Navigator>
        </NavigationContainer>
    );
};

export default function App() {
    return (
        <Provider store={store}>
            <SafeAreaProvider>
                <NotificationProvider>
                    {Platform.OS === 'web' && <FacebookPixel />}
                    <ReduxApp />
                </NotificationProvider>
            </SafeAreaProvider>
        </Provider>
    );
};