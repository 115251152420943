// components/boost/BoostMenuModal.js
// BoostMenuModal, drawerfy menu modal that supports payment function
// on both ios and web
import { useEffect } from "react";
import { Modal, StyleSheet, Keyboard, Platform, Pressable } from "react-native";
import { useSelector, useDispatch } from "react-redux";

import MenuModal from "./MenuCard/MenuModal";
import {
    refreshBoostPurchaseOptions,
    closeBoostMenu,
} from "../../actions/boostActions";

const styles = StyleSheet.create({
    modalContainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,0.5)",
    },
});

const BoostMenuModal = () => {
    const dispatch = useDispatch();
    const isMenuOpen = useSelector((state) => state.boost.isMenuOpen);

    useEffect(() => {
        if (isMenuOpen) {
            // close keyboard
            if (Platform.OS === "web") {
                if (document.activeElement) {
                    document.activeElement.blur();
                }
            } else {
                Keyboard.dismiss();
            }

            // refresh purchase options
            dispatch(refreshBoostPurchaseOptions());
        }
    }, [isMenuOpen]);

    return (
        <Modal
            visible={isMenuOpen}
            transparent={true}
            onRequestClose={() => {
                dispatch(closeBoostMenu());
            }}
        >
            <Pressable
                style={styles.modalContainer}
                onPress={() => {
                    dispatch(closeBoostMenu());
                }}
            >
                <MenuModal />
            </Pressable>
        </Modal>
    );
};

export default BoostMenuModal;
