// components/SystemChatHeader.js
import {
    View,
    Text,
    StyleSheet,
    Platform,
} from 'react-native';
import Svg, { Rect, Mask, Image as SVGImage } from 'react-native-svg';
import { useRelativeStyles } from '../utils/screenDimensions';

const SystemChatHeader = ({ cosmeticColor, onLayout, isDark }) => {
    const styles = StyleSheet.create(useRelativeStyles({
        container: {
            flexDirection: 'row',
            backgroundColor: isDark ? 'tranparent' : 'white',
            justifyContent: 'center',
            alignItems: 'flex-end',
            alignSelf: 'center',
            width: '100%',
            rlw_height: Platform.OS === 'web' ? 54 :
                (Platform.isPad ? 70 : 100),
        },
        background: {
            position: 'absolute',
            left: 0,
            bottom: 0,
            zIndex: 1,
            width: '100%',
            height: '100%',
        },
        textView: {
            zIndex: 2,
            rlw_width: 393,
            rlw_height: 54,
        },
        text: {
            fontFamily: 'BricolageGrotesque_Medium',
            color: isDark ? 'white' : '#111',
            textAlign: 'left',
            flex: 1,
            zIndex: 2,
            rlw_fontSize: Platform.OS === 'web' ? 26 : 30,
            rlw_lineHeight: 52,
            rlw_marginHorizontal: 26,
        },
    }));
    const headerImagePath = require('../assets/common/chat/header_mask.png');

    return (
        <View style={styles.container} onLayout={onLayout}>
            {!isDark && <View style={styles.background}>
                <Svg width="100%" height="100%" position='absolute'>
                    <Mask id="mask" x="0" y="0" height="100%" width="100%">
                        <SVGImage width="100%" height="100%"
                            preserveAspectRatio="xMidYMax slice"
                            href={headerImagePath}
                        />
                    </Mask>
                    <Rect x="0" y="0" width="100%" height="100%"
                        fill={cosmeticColor}
                        mask="url(#mask)"
                    />
                </Svg>
            </View>}
            <View style={styles.textView}>
                <Text style={styles.text} selectable={false}>
                    Volar
                </Text>
            </View>
        </View>
    );
};

export default SystemChatHeader;