// utils/expoNotification.js

import { Platform } from 'react-native';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import Constants from 'expo-constants';
import { setUserExpoPushToken } from '../services/authService';

const Permissions = {
    GRANTED: 'granted',
    DENIED: 'denied',
    UNDETERMINED: 'undetermined'
};

class ExpoNotifications {
    constructor() {
        Notifications.setNotificationHandler({
            handleNotification: async () => ({
                shouldShowAlert: false,
                shouldPlaySound: true,
                shouldSetBadge: true,
            }),
        });
    }

    /**
     * Tries to get permission from the user for push notification if the user
     * hasn't granted before or removed a previously granted permission. Based on that
     * result, it may try to get the device token and update the remote user record.
     *
     * @async
     * @function
     * @param {Object} user - The user object.
     * @returns {Promise<void>} A promise that resolves when the operation is complete.
     */
    async register(user) {
        if (!Device.isDevice || Platform.OS === 'web') {
            // Skip notification if it's not a physical mobile device
            // Note, on web, Device.isDevice is always true.
            return
        }

        // extract current user notificiation permision status
        const { status: existingStatus } = await Notifications.getPermissionsAsync();
        console.debug("existing notification permission status: ", existingStatus)
        let requestPermission = false;
        let finalStatus = null;

        switch (existingStatus) {
            case Permissions.GRANTED:
                // Note, the token will not change even if the user flipped the permission
                // for multiple times as the token is binded to the device and app.
                // However, if the user changed the deviced, the token would potentially be
                // different from the remote reocrd. in this case, we need to resync the token.
                finalStatus = Permissions.GRANTED;
                console.debug("permission already granted")
                break;
            case Permissions.UNDETERMINED:
                requestPermission = true
                console.debug("permission hasn't been determined yet, requesting permission...")

                finalStatus = (await Notifications.requestPermissionsAsync()).status;
                break;
            case Permissions.DENIED:
                console.debug("permission has been denied")
                return;
            default:
                console.error("unknown status for token registration: ", existingStatus)
        }

        console.debug("final status: ", finalStatus)

        if (finalStatus == Permissions.GRANTED) {
            console.debug("permission granted, getting token...")
            try {
                // read dynamic config from app.json > extra
                // https://docs.expo.dev/workflow/configuration/#dynamic-configuration
                let cfg = {projectId:Constants.expoConfig.extra.eas.projectId}
                token = (await Notifications.getExpoPushTokenAsync(cfg)).data
                console.debug("expo token:", token)
            } catch (error) {
                // if the device is offline, an exception will be raised. In this case,
                // nothing we can do but to wait for the next time the app is online.
                console.error('Error in getting expo push token: ', error);
                return
            }

            console.debug("remote user token:", user.expoToken)
            if (token != user.expoToken) {
                console.debug(
                    "local (%s) & remote (%s) token has discrepency, resync...",
                    token,
                    user.expoToken
                )
                // sync up with the backend
                setUserExpoPushToken(token)
            }

            console.debug("user token is up to date")
        }
    }
}

const expoNotification = new ExpoNotifications();
export default expoNotification;