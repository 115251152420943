// components/upload/PopupMenu.js

import { View, Text, TouchableOpacity, Image, Platform, StyleSheet } from 'react-native';
import { useRelativeStyles } from '../../utils/screenDimensions';

const PopupMenu = ({ isVisible, onBlock, onReport }) => {
    const styles = StyleSheet.create(useRelativeStyles({
        dropdownContainer: {
            position: 'absolute',
            rlw_top: Platform.OS === 'web' ? 54 :
                (Platform.isPad ? 70 : 100),
            rlw_right: 15,
            backgroundColor: '#F0F0F0',
            rlw_borderRadius: 12,
            rlw_width: 165,
            zIndex: 1000,
        },
        button: {
            rlw_height: 44,
            rlw_paddingHorizontal: 16,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        splitter: {
            height: 0.5,
            width: '100%',
            backgroundColor: '#11111140',
        },
        text: {
            flex: 1,
            fontFamily: 'IBMPlexSans_400Regular',
            color: 'black',
            rlw_fontSize: 17,
            rlw_lineHeight: 21.25,
        },
        image: {
            rlw_width: 26,
            rlw_height: 18,
            tintColor: 'black',
            resizeMode: 'contain',
        },
    }));
    const buttonImages = [
        require('../../assets/arrows/person.crop.circle.badge.exclamationmark.sf_regular.png'),
        require('../../assets/arrows/trash.sf_regular.png'),
    ];

    return (isVisible &&
        <View style={styles.dropdownContainer}>
            <TouchableOpacity style={styles.button} onPress={onReport}>
                <Text style={styles.text}>Report user</Text>
                <Image style={styles.image} source={buttonImages[0]} />
            </TouchableOpacity>
            <View style={styles.splitter} />
            <TouchableOpacity style={styles.button} onPress={onBlock}>
                <Text style={[styles.text, { color: 'red' }]}>Block user</Text>
                <Image style={[styles.image, { tintColor: 'red' }]} source={buttonImages[1]} />
            </TouchableOpacity>
        </View>
    );
};

export default PopupMenu;