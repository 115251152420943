// services/shareService.js

import axios from 'axios';
import { SHARE_CREATE_URL, SHARE_USE_BASE_URL } from '../urlconfig';
import { getToken } from './common';
import store from '../store';

export const createShareURL = async () => {
    try {
        const token = await getToken();
        const response = await axios.post(SHARE_CREATE_URL, {}, {
            headers: {
                Authorization: `Token ${token}`,
            },
        });
        if (response.status != 201) {
            throw new Error('Failed to create share link.');
        }
        return response.data.link;
    } catch (error) {
        throw error;
    }
};

export const useInvitationCode = async () => {
    const code = store.getState().referral.invitationCode;
    if (code) {
        console.log("Invatation code used: " + code);
        const url = `${SHARE_USE_BASE_URL}${code}/`;
        try {
            const token = await getToken();
            const response = await axios.post(url, {}, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            });
            if (response.status != 201) {
                // fail silently
                console.log("Invitation code has been used, although unsuccessfully.")
                return false;
            } else {
                console.log("Successfully used invitation code.")
                return true;
            }
        } catch (error) {
            // fail silently
            console.log("Invitation code has been used, although unsuccessfully.")
            return false;
        }
    }
};