// screens.ChatScreen.js
import { useEffect, useState, useRef } from 'react';
import { useRoute } from '@react-navigation/native';
import { SafeAreaView } from 'react-native-safe-area-context';
import {
    View,
    StyleSheet,
    KeyboardAvoidingView,
    FlatList,
    Platform,
    Keyboard,
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { useScreenDimensions } from '../utils/screenDimensions';
import { fetchChat } from '../services/chatService';
import {
    fetchMessages, fetchMessagesUpTo, MessageWebSocketService,
    MAX_NUM_OF_MESSAGES_RENDERED,
} from '../services/messageService';
import { showNetworkError } from '../utils/errorHandlers';
import { resetNavigation } from '../utils/navigation';
import {
    getChatDisplayTypeAndText, updateChatType,
} from '../utils/chatUtils';
import {
    UserThumbnailSize,
    getUserCosmeticColor,
} from '../utils/userUtils';
import {
    getMessageDetailsForRendering,
} from '../utils/messageUtils';
import { MessageType } from '../models/Message';
import { ChatType } from '../models/Chat';
import ChatHeader from '../components/chat/ChatHeader';
import MessageInput from '../components/MessageInput';
import MessageDateTime from '../components/chat/MessageDateTime';
import SimulatedNonAction from '../components/chat/SimulatedNonAction';
import SimulatedAction from '../components/chat/SimulatedAction';
import RequestedNonAction from '../components/chat/RequestedNonAction';
import RequestedAction from '../components/chat/RequestedAction';
import RejectedNonAction from '../components/chat/RejectedNonAction';
import ChatBubbleBody from '../components/chat/ChatBubbleBody';
import FlatListFooter from '../components/FlatListFooter';
import { reduceBoost } from '../actions/boostActions';
import PageViewWrapper from '../firebase/components/pageViewWrapper';
import { logMessageSent } from '../firebase/utils';

const styles = StyleSheet.create({
    messageContainer: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
});

const ChatBubble = ({ configValues, message, details, otherUser, onPressRemove, onPressProfile, onLayout }) => {
    return (
        <View onLayout={onLayout} style={[styles.messageContainer, {
            marginHorizontal: configValues.messageBoxMarginHorizontal,
            width: configValues.messageBoxWidth,
        }]}>
            {details.showTimestamp && <MessageDateTime formattedTimestamp={details.formattedTimestamp} />}
            {message.type === MessageType.REJECT ? <RejectedNonAction /> :
                <ChatBubbleBody
                    parentWidth={configValues.messageBoxWidth}
                    thumbnailSize={UserThumbnailSize.SMALL}
                    message={message}
                    details={details}
                    onPressProfile={onPressProfile}
                />
            }
            {details.showSimulatedNonAction && <SimulatedNonAction />}
            {details.showSimulatedAction && <SimulatedAction chatName={otherUser?.firstName} />}
            {details.showRequestedNonAction && <RequestedNonAction
                parentWidth={configValues.messageBoxWidth}
                thumbnailSize={UserThumbnailSize.SMALL}
            />}
            {details.showRequestedAction && <RequestedAction
                parentWidth={configValues.messageBoxWidth}
                thumbnailSize={UserThumbnailSize.SMALL}
                onPress={onPressRemove}
            />}
            <View style={{
                height: configValues.messageBoxMarginBottom[
                    details.compressBottomMargin ? 0 : 1
                ]
            }} />
        </View>
    );
};

const ChatScreen = ({ navigation }) => {
    const user = useSelector(state => state.user);
    const { windowHeight, adjustedWidth } = useScreenDimensions();
    const configValues = {
        messageBoxMarginBottom: [adjustedWidth * 0.0051, adjustedWidth * 0.0509],
        messageBoxMarginHorizontal: adjustedWidth * 0.0382,
        messageBoxWidth: adjustedWidth * 0.9236,
        scrollViewPaddingBottom: adjustedWidth * 0,
    };
    const { chatId } = useRoute().params;
    const [otherUser, setOtherUser] = useState(null);
    const [chatType, setChatType] = useState(ChatType.MESSAGE);
    const [messages, setMessages] = useState([]);
    const [messageInput, setMessageInput] = useState('');
    const [sendEnabled, setSendEnabled] = useState(false);
    const [keyboardHeight, setKeyboardHeight] = useState(0);
    const [messageInputHeight, setMessageInputHeight] = useState(0);
    const [headerHeight, setHeaderHeight] = useState(0);
    const [flatListHeight, setFlatListHeight] = useState(0);
    const [page, setPage] = useState(1);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [hasInitialized, setHasInitialized] = useState(false);
    const hasMorePagesRef = useRef(true);
    const wsRef = useRef(null);
    const flatListRef = useRef();
    const textInputRef = useRef(null);
    const chatRef = useRef(null);
    const messageHeightsRef = useRef({});
    const lastReadIndexRef = useRef(0);
    const hasScrolledTheFirstTimeRef = useRef(false);

    useEffect(() => {
        const subKDS = Keyboard.addListener('keyboardDidShow', (e) => {
            setKeyboardHeight(e.endCoordinates.height);
        });
        const subKDH = Keyboard.addListener('keyboardDidHide', () => {
            setKeyboardHeight(0);
        });

        // cleanup function
        return () => {
            subKDS.remove();
            subKDH.remove();
        };
    }, []);

    useEffect(() => {
        const initChat = async ()  => {
            try {
                chatRef.current = await fetchChat(chatId);
                const chatDisplay = getChatDisplayTypeAndText(chatRef.current, user);
                setOtherUser(chatDisplay.otherUser);
                setChatType(chatDisplay.chatType);
            } catch (error) {
                if (error.response.status === 401) {
                    resetNavigation(navigation);
                } else if (error.message.includes('Network Error') || error.message?.includes('timeout')) {
                    showNetworkError();
                } else {
                    console.error('Error fetching messages:', error);
                }
            }
            await handleLoadMore(true);
            setHasInitialized(true);
        }

        const initConnection = async () => {
            try {
                wsRef.current = await MessageWebSocketService.init(chatId);
                wsRef.current.setOnMessageCallback(handleReceive);
                wsRef.current.openConnection();
            } catch (error) {
                if (error.response.status === 401) {
                    resetNavigation(navigation);
                } else if (error.message.includes('Network Error') || error.message.includes('timeout')) {
                    showNetworkError();
                } else {
                    console.error('Error initialize WebSocket connection:', error);
                }
            }
        }

        if (user == null || chatId === '') {
            resetNavigation(navigation);
        } else {
            initChat();
            initConnection();
        }

        return () => {
            if (wsRef.current) {
                wsRef.current.closeConnection();
            }
        };
    }, []);

    const handleMessageChange = (text) => {
        setMessageInput(text);
        setSendEnabled(text.length !== 0);
    };

    const dispatch = useDispatch();
    const isBoosting = useSelector(state => state.boost.isBoosting);

    const handleMessageSubmit = () => {
        if (messageInput !== '') {
            let messagePayload = {};
            switch (chatType) {
                case ChatType.MESSAGE:
                    messagePayload = {
                        type: 'chat_message_send',
                        message: messageInput,
                        messagetype: MessageType.TEXT,
                        is_boosted: isBoosting,
                    };
                    wsRef.current.sendMessage(messagePayload);
                    // analytics
                    logMessageSent(MessageType.TEXT, chatId, messageInput, isBoosting);
                    break;
                case ChatType.FIRSTDATE:
                    messagePayload = {
                        type: 'chat_message_send',
                        message: messageInput,
                        messagetype: MessageType.REQUEST,
                        is_boosted: isBoosting,
                    };
                    wsRef.current.sendMessage(messagePayload);
                    setChatType(updateChatType(chatType, MessageType.REQUEST, true));
                    // analytics
                    logMessageSent(MessageType.REQUEST, chatId, messageInput, isBoosting);
                    break;
                case ChatType.ISREQUESTED:
                    messagePayload = {
                        type: 'chat_message_send',
                        message: messageInput,
                        messagetype: MessageType.CONFIRM,
                        is_boosted: isBoosting,
                    }
                    wsRef.current.sendMessage(messagePayload);
                    setChatType(updateChatType(chatType, MessageType.CONFIRM, true));
                    // analytics
                    logMessageSent(MessageType.CONFIRM, chatId, messageInput, isBoosting);
                    break;
                default:
                    break;
            }

            // Clear the input field
            setMessageInput('');
            setSendEnabled(false);
            if (isBoosting) {
                // reudce boost will also turn boost mode off
                dispatch(reduceBoost())
            }
        }
    };

    const handleReject = () => {
        const messagePayload = {
            type: 'chat_message_send',
            message: '',
            messagetype: MessageType.REJECT,
        }
        wsRef.current.sendMessage(messagePayload);
        setChatType(updateChatType(chatType, MessageType.REJECT, true));
    };

    const handleProfile = (targetUser) => {
        if (targetUser.userid === user?.pk) {
            navigation.navigate('UploadImage');
        } else {
            navigation.navigate('UploadImage', {
                userId: targetUser.userid,
                chatId: chatId,
            });
        }
    };

    const handleScrollLayout = (e) => {
        setFlatListHeight(e.nativeEvent.layout.height);
        if (keyboardHeight === 0) {
            // this is triggered by a keyboard opening event
            flatListRef.current?.scrollToOffset({ offset: 0, animated: true });
        }
    };

    const handleChatBubbleLayout = (event, index, flatListHeight) => {
        const height = event.nativeEvent.layout.height;
        messageHeightsRef.current[index] = height;
        if (!hasScrolledTheFirstTimeRef.current) {
            const requiredItemsCount = lastReadIndexRef.current;
            const hasRequiredHeights =
                [...Array(requiredItemsCount).keys()]
                    .every(index => messageHeightsRef.current.hasOwnProperty(index));

            if (hasRequiredHeights) {
                const sumOfRequiredHeights =
                    [...Array(requiredItemsCount).keys()]
                        .reduce((acc, index) => acc + (messageHeightsRef.current[index] || 0), 0);
                console.log("Scroll to unread offset: " + sumOfRequiredHeights);
                flatListRef.current.scrollToOffset({
                    offset: Math.max(sumOfRequiredHeights - flatListHeight * 0.9, 0),
                    animated: false,
                });
                hasScrolledTheFirstTimeRef.current = true;
            }
        }
    };

    const handleLoadMore = async (upToLastReadMessage) => {
        if (isLoadingMore || !hasMorePagesRef.current) return;
        setIsLoadingMore(true);
        try {
            const currentMessages = upToLastReadMessage ? 
                await fetchMessagesUpTo(chatId, page, 30, chatRef.current.lastReadMessage) :
                await fetchMessages(chatId, page, 30);
            setMessages(prevMessages => [...prevMessages, ...currentMessages.messages]);
            setPage(currentMessages.nextPage);
            hasMorePagesRef.current = currentMessages.hasMorePages;
            if (upToLastReadMessage) {
                lastReadIndexRef.current = currentMessages.lastReadIndex;
            }
        } catch (error) {
            if (error.response.status === 401) {
                resetNavigation(navigation);
            } else if (error.message.includes('Network Error') || error.message?.includes('timeout')) {
                showNetworkError();
            } else {
                console.error('Error fetching messages:', error);
            }
        }
        setIsLoadingMore(false);
    }

    const handleReceive = (event) => {
        const data = JSON.parse(event.data);
        switch (data.type) {
            case 'chat_message':
                setMessages((prevMessages) => {
                    const author = chatRef.current.users.filter(
                        chatUser => chatUser.userid === data.userid)[0];
                    let newMessages = [{
                        id: data.id,
                        author: author,
                        content: data.message,
                        type: data.messagetype,
                        created: new Date().toISOString(),
                        isBoosted: data.is_boosted == "True",
                    }, ...prevMessages];
                    if (hasMorePagesRef.current) {
                        newMessages.pop();
                    }
                    return newMessages;
                });
                wsRef.current.sendMessage({
                    type: 'chat_message_ack',
                    messageid: data.messageid,
                });
                setChatType(updateChatType(
                    chatType, data.messagetype, data.userid === user?.pk));
                flatListRef.current?.scrollToOffset({ offset: 0, animated: true });
                break;
            default:
                if ("error" in data) {
                    console.error(data.error);
                } else {
                    console.error('Received unknown event type:', data.type);
                }
        }
    };

    return (
        <View style={{ flex: 1, backgroundColor: 'white' }}>
            <SafeAreaView edges={['bottom',]} style={{ flex: 1 }}>
                <KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" : "height"} style={{ flex: 1 }}>
                    <View style={{
                        flex: 1,
                        // On web, since KeyboardAvoidingView is not implemented, we use
                        // a workaround to specify heights of each components, then let them
                        // all align towards the bottom.
                        justifyContent: 'flex-end',
                    }}>
                        <ChatHeader
                            otherUser={otherUser}
                            navigation={navigation}
                            onLayout={(event) => {
                                const { height } = event.nativeEvent.layout;
                                setHeaderHeight(height);
                            }}
                            onPress={() => handleProfile(otherUser)}
                        />
                        <FlatList
                            inverted
                            ref={flatListRef}
                            data={messages.slice(0)}
                            renderItem={({ item, index }) => {
                                const details = getMessageDetailsForRendering(
                                    user, messages, index);
                                return (<ChatBubble
                                    message={item}
                                    details={details}
                                    otherUser={otherUser}
                                    configValues={configValues}
                                    chatType={chatType}
                                    onPressRemove={handleReject}
                                    onPressProfile={handleProfile}
                                    onLayout={(event) => handleChatBubbleLayout(event, index, flatListHeight)}
                                />);
                            }}
                            keyExtractor={(item, index) => index.toString()}
                            contentContainerStyle={{
                                flexGrow: 1,
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                paddingTop: configValues.scrollViewPaddingBottom,
                            }}
                            style={{
                                flex: 1,
                                backgroundColor: 'white',
                                maxHeight: Platform.OS === 'web' ?
                                    windowHeight - headerHeight - keyboardHeight - messageInputHeight : undefined
                            }}
                            ListFooterComponent={
                                <FlatListFooter isDark={false} isLoadingMore={isLoadingMore} />
                            }
                            onEndReached={hasInitialized ? () => handleLoadMore(false) : undefined}
                            onEndReachedThreshold={0.1}
                            onLayout={handleScrollLayout}
                            initialNumToRender={MAX_NUM_OF_MESSAGES_RENDERED}
                        />
                        <MessageInput
                            ref={textInputRef}
                            onLayout={(event) => {
                                const { height } = event.nativeEvent.layout;
                                setMessageInputHeight(height);
                            }}
                            value={messageInput}
                            userColor={getUserCosmeticColor(user)}
                            sendEnabled={sendEnabled &&
                                [ChatType.FIRSTDATE, ChatType.MESSAGE, ChatType.ISREQUESTED].includes(chatType)}
                            onChange={handleMessageChange}
                            onSubmit={handleMessageSubmit}
                            onPressLogo={() => navigation.navigate('Assistant')}
                            hasLogo={true}
                            autoFocus={false}
                            isDark={false}
                            boostable={true}
                            isAssistantPage={false}
                        />
                    </View>
                </KeyboardAvoidingView>
            </SafeAreaView>
        </View>
    );
};

export default PageViewWrapper(ChatScreen);