// components/MessageInput.js
import { useState, forwardRef } from 'react';
import {
    TouchableOpacity,
    View,
    TextInput,
    Platform,
    StyleSheet,
    Image,
} from 'react-native';
import { useRelativeStyles } from '../utils/screenDimensions';
import Logo, { LogoSize } from './Logo';
import { useSelector } from 'react-redux';
import ButtonClickWrapper from '../firebase/components/buttonClickWrapper';
import {
    BUTTON_CLICK_CHAT_ASSISTANT, BUTTON_CLICK_ASSISTANT_CLOSE
} from '../firebase/constants'

const MessageInput = forwardRef(({
    value, sendEnabled, onChange, onSubmit, onLayout, onPressLogo, hasLogo,
    autoFocus, userColor, isDark, boostable, isAssistantPage
}, textInputRef) => {
    const styles = StyleSheet.create(useRelativeStyles({
        inputContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: isDark ? 'transparent' : 'white',
            alignSelf: 'center',
            rlw_paddingHorizontal: 11,
            rlw_paddingVertical: 16,
            rlw_width: 393,
        },
        logoButton: {
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            alignSelf: 'flex-end',
            rlw_width: 47,
            rlw_height: 36,
        },
        // https://github.com/facebook/react-native/issues/35234
        paddingView: {
            flex: 1,
            borderColor: 'black',
            borderWidth: 1,
            backgroundColor: 'white',
            overflow: 'hidden',
            rlw_marginHorizontal: 14,
            rlw_paddingHorizontal: 10,
            rlw_paddingVertical: 7,
            rlw_borderRadius: 16,
        },
        textInput: {
            fontFamily: 'IBMPlexSans_400Regular',
            textAlignVertical: 'center',
            flex: 1,
            ...Platform.select({
                web: { outlineStyle: 'none' },
            }),
            rlw_paddingVertical: 0,
            rlw_fontSize: 16,
            rlw_lineHeight: 20,
        },
        sendButton: {
            alignSelf: 'flex-end',
            alignItems: 'center',
            justifyContent: 'center',
            rlw_borderRadius: 16,
            rlw_width: 36,
            rlw_height: 36,
        },
        sendImage: {
            rlw_width: 36,
            rlw_height: 36,
            tintColor: isDark ? 'white' : 'black',
            resizeMode: 'contain',

        },
        boostImage: {
            rlw_width: 36,
            rlw_height: 36,
            resizeMode: 'contain',
        },
        maxHeight: {
            rlw_maxHeight: 100,
        },
    }));
    
    const isBoosting = useSelector(state => state.boost.isBoosting);

    const sendImagePath = [
        require('../assets/arrows/arrow.up.circle.sf_thin.png'),
        require('../assets/arrows/arrow.up.circle.fill.sf_regular.png'),
    ];

    const boostedSendImagePath = [
        require('../assets/boost/boost_with_circle.png'),
        require('../assets/boost/boost_with_circle_solid.png'),
    ]

    const [height, setHeight] = useState(0);

    const adjustTextInputSizeWeb = (evt) => {
        // hack for web, see this link
        // https://github.com/necolas/react-native-web/issues/795#issuecomment-1297511068
        if (Platform.OS === 'web') {
            const el = textInputRef.current;
            if (el != null && el.style != null) {
                el.style.height = 0;
                const newHeight = Math.min(
                    el.offsetHeight - el.clientHeight + el.scrollHeight,
                    styles.maxHeight.maxHeight
                ) + styles.paddingView.paddingVertical * 2;
                el.parentNode.style.height = `${newHeight}px`;
            }
        }
    };

    const adjustTextInputSize = (e) => {
        if (Platform.OS !== 'web') {
            const contentHeight = e.nativeEvent.contentSize.height;
            setHeight(Math.min(
                contentHeight, styles.maxHeight.maxHeight
            ) + styles.textInput.paddingVertical * 2);
        }
    };

    const handleSend = () => {
        if (sendEnabled) {
            onSubmit();
            // TextInput on web cannot react to onChange or onLayout when
            // content is programmatically set. Hence we force a height reset.
            if (Platform.OS === 'web') {
                const el = textInputRef.current;
                if (el != null && el.style != null) {
                    el.style.height = 0;
                    el.parentNode.style.height = 0;
                }
            }
        }
    };

    const handleKeyPress = (e) => {
        if (Platform.OS === 'web' && e.nativeEvent.key === 'Enter') {
            handleSend();
        }
    };

    const handleTextChange = (text) => {
        let alignedText = text;
        if (Platform.OS === 'web') {
            alignedText = text.replace(/\n/g, '');
        }
        onChange(alignedText);
    };

    const LogoButton = ButtonClickWrapper(({ onPress }) => (
        <TouchableOpacity
            activeOpacity={0.7}
            onPress={onPress}
            style={styles.logoButton}>
            <Logo
                userColor={userColor}
                otherUserColor={isDark ? 'white' : 'black'}
                logoSize={LogoSize.SMALL}
            />
        </TouchableOpacity>
    ), isAssistantPage ? BUTTON_CLICK_ASSISTANT_CLOSE : BUTTON_CLICK_CHAT_ASSISTANT)

    return (
        <View onLayout={onLayout} style={styles.inputContainer}>
            {hasLogo && <LogoButton onPress={onPressLogo} />}
            <View style={[styles.paddingView, {
                height: height + 2 + styles.paddingView.paddingVertical * 2,
            }]}>
                <TextInput
                    ref={textInputRef}
                    multiline
                    maxLength={4096}
                    onContentSizeChange={adjustTextInputSize}
                    onChange={adjustTextInputSizeWeb}
                    onLayout={adjustTextInputSizeWeb}
                    onKeyPress={handleKeyPress}
                    onChangeText={handleTextChange}
                    autoFocus={autoFocus}
                    value={value}
                    style={styles.textInput}
                />
            </View>
            <TouchableOpacity
                onPress={handleSend}
                disabled={!sendEnabled}
                style={styles.sendButton}>
                {
                    boostable && isBoosting
                    ?<Image
                        source={
                            sendEnabled? boostedSendImagePath[1] : boostedSendImagePath[0]
                        }
                        style={styles.boostImage}
                    />
                    :
                    <Image
                        source={
                        sendEnabled ? sendImagePath[1] : sendImagePath[0]
                        }
                        style={styles.sendImage}
                    />
                }
            </TouchableOpacity>
        </View>
    );
});

export default MessageInput;