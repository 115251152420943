// models/User.js
import { createChat } from './Chat';
import { createCosmeticMetadata } from './CosmeticMetadata';

export const UserStatus = {
    'UNKNOWN': 'UNKNOWN',
    'PRE_REGISTERED': 'PRE_REGISTERED',
    'REGISTERED': 'REGISTERED',
    'ONBOARDED': 'ONBOARDED',
    'PROFILE_COMPLETE': 'PROFILE_COMPLETE',
};

export const createUser = (data) => {
    return {
        pk: data.pk,
        username: data.username,
        email: data.email,
        firstName: data.first_name,
        lastName: data.last_name,
        gender: data.gender,
        age: data.age !== null ? data.age : 0,
        location: data.location !== null ? data.location : '',
        phoneNumber: data.phone_number,
        status: data.status,
        onboardingChat: createChat(data.onboarding_chat),
        thumbnail: data.thumbnail,
        cosmeticMetadata: createCosmeticMetadata(data.cosmetic_metadata),
        expoToken: data.expo_push_token,
        nextMatchTime: data.next_match_time,
    };
};