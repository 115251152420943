// services/imageService.js

import axios from 'axios';
import {
    PROFILE_BASE_URL,
    PROFILE_MYIMAGES_BASE_URL,
    PROFILE_MYIMAGES_STAGING_URL,
    IMAGE_UPLOAD_PRESIGNED_URL,
    IMAGE_UPLOAD_CONFIRM_URL,
} from '../urlconfig';
import { getToken } from './common';
import { createProfileImage } from '../models/ProfileImage';

export const MIME_TYPE = 'image/jpeg';

export const initStagingProfileImages = async () => {
    const url = `${PROFILE_MYIMAGES_STAGING_URL}`;
    try {
        const token = await getToken();
        const response = await axios.post(url, {}, {
            headers: {
                Authorization: `Token ${token}`,
            },
        });
        if (response.status != 200) {
            throw new Error('Failed to initialize staging profile images.');
        }
        const profile_image_urls = response.data.map(
            (image) => (createProfileImage(image)))
        return profile_image_urls;
    } catch (error) {
        throw error;
    }
};

export const fetchStagingProfileImages = async () => {
    const url = `${PROFILE_MYIMAGES_STAGING_URL}`;
    try {
        const token = await getToken();
        const response = await axios.get(url, {
            headers: {
                Authorization: `Token ${token}`,
            },
        });
        if (response.status != 200) {
            throw new Error('Failed to fetch staging profile images.');
        }
        const profile_image_urls = response.data.map(
            (image) => (createProfileImage(image)))
        return profile_image_urls;
    } catch (error) {
        throw error;
    }
};

export const fetchProfileImages = async (userId) => {
    const url = `${PROFILE_BASE_URL}${userId}/images/`;
    try {
        const token = await getToken();
        const response = await axios.get(url, {
            headers: {
                Authorization: `Token ${token}`,
            },
        });
        if (response.status !== 200) {
            throw new Error('Error fetching profile images.');
        }
        const profile_image_urls = response.data.map(
            (image) => (createProfileImage(image)))
        return profile_image_urls;
    } catch (error) {
        throw error;
    }
};

export const deleteProfileImage = async (profileImageId) => {
    const url = `${PROFILE_MYIMAGES_BASE_URL}${profileImageId}/delete/`;
    try {
        const token = await getToken();
        const response = await axios.delete(url, {
            headers: {
                Authorization: `Token ${token}`,
            },
        });
        if (response.status != 204) {
            throw new Error('Failed to delete profile image.');
        }
        return true;
    } catch (error) {
        throw error;
    }
};

export const confirmProfileImage = async (isStaging = false) => {
    const url = `${PROFILE_MYIMAGES_BASE_URL}confirm/`;
    try {
        const token = await getToken();
        const response = await axios.post(url, { is_staging: isStaging }, {
            headers: {
                Authorization: `Token ${token}`,
            },
        });
        if (response.status != 200) {
            throw new Error('Failed to finish editing profile images.');
        }
        return true;
    } catch (error) {
        throw error;
    }
};

export const uploadImage = async (imageURI, imageType = 'profile', isStaging = false) => {
    try {
        const token = await getToken();

        // 1. Request a presigned URL
        const presignedUrlResponse = await axios.post(
            IMAGE_UPLOAD_PRESIGNED_URL, { mime_type: MIME_TYPE }, {
            headers: {
                Authorization: `Token ${token}`,
            },
        });
        if (presignedUrlResponse.status !== 200) {
            throw new Error('Failed to fetch presigned URL');
        }
        const presignedUrl = presignedUrlResponse.data.presigned_url;

        // 2. Upload image to the presigned URL
        let image = await fetch(imageURI);
        let blob = await image.blob();
        const uploadResponse = await fetch(presignedUrl, {
            method: 'PUT',
            body: blob,
            headers: {
                'Content-Type': MIME_TYPE,
            },
        });
        if (!uploadResponse.ok) {
            throw new Error('Failed to upload image to S3');
        }

        // 3. Confirm the upload
        const confirmResponse = await axios.post(IMAGE_UPLOAD_CONFIRM_URL, {
            presigned_url: presignedUrl,
            image_type: imageType,
            is_staging: isStaging,
        }, {
            headers: {
                Authorization: `Token ${token}`,
            },
        });
        if (confirmResponse.status !== 201) {
            throw new Error('Failed to confirm the image upload');
        }
        return true;
    } catch (error) {
        throw error;
    }
};