// components/MessageDateTime.js
import {
    Text,
    StyleSheet,
} from "react-native";
import { useScreenDimensions } from "../../utils/screenDimensions";

const styles = StyleSheet.create({
    text: {
        fontFamily: 'IBMPlexSans_700Bold',
        color: '#999',
        textAlignVertical: 'center',
        textAlign: 'center',
    },
    textTime: {
        fontFamily: 'IBMPlexSans_400Regular',
    },
});

const MessageDateTime = ({ formattedTimestamp }) => {
    const { adjustedWidth } = useScreenDimensions();
    const configValues = {
        fontSize: adjustedWidth * 0.0305,
        lineHeight: adjustedWidth * 0.0382,
        marginBottom: adjustedWidth * 0.0509,
    };

    return (
        <Text style={[styles.text, {
            fontSize: configValues.fontSize,
            lineHeight: configValues.lineHeight,
            marginBottom: configValues.marginBottom,
        }]}>
            {formattedTimestamp?.formattedDate}
            <Text style={styles.textTime}>
            {' at ' + formattedTimestamp?.formattedTime}
            </Text>
        </Text>
    );
};

export default MessageDateTime;