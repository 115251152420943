import {
    View,
    Text,
    Image,
    StyleSheet,
    TouchableOpacity,
    SafeAreaView,
    Platform,
} from "react-native";

import Logo, { LogoSize } from "../components/Logo";
import { useRelativeStyles } from "../utils/screenDimensions";
import { useSelector } from "react-redux";
import Drawer from "./common/drawer";
import { CancelXButton } from "./common/closeButton";

const MatchRequestModal = ({
    onClose,
    onConfirm,
    message,
    buttonText,
    fromUser,
}) => {
    const styles = StyleSheet.create(
        useRelativeStyles({
            overlay: {
                flex: 1,
                backgroundColor: "rgba(37,37,37, 0.91)",
            },
            container: {
                flex: 1,
                alignItems: "center",
                justifyContent: "space-between",
                ...Platform.select({
                    ios: {
                        rlw_borderRadius: 10,
                    },
                }),
            },
            // message
            messageContainer: {
                flex: 1,
                justifyContent: "flex-end",
            },
            message: {
                rlw_fontSize: 30,
                rlw_width: 200,
                textAlign: "center",
                fontFamily: "BricolageGrotesque_Regular",
                color: "white",
                rlw_marginBottom: 56,
            },
            iconContainer: {
                rlw_width: 183,
                rlw_height: 183,
                alignItems: "center",
            },
            // icon
            icon: {
                rlw_width: 183,
                rlw_height: 183,
            },
            // button
            buttonContainer: {
                flex: 1,
                justifyContent: "flex-end",
                alignItems: "center",
            },
            button: {
                position: "relative",
                rlw_bottom: 79,
                flexDirection: "row",
                alignItems: "center",
                rlw_width: 345,
                rlw_height: 75,
                rlw_paddingHorizontal: 28,
                rlw_paddingVertical: 23,
                backgroundColor: "#FF6B6B",
                rlw_borderRadius: 12,
            },
            buttonText: {
                color: "white",
                rlw_fontSize: 25,
                fontFamily: "BricolageGrotesque_Medium",
                textAlign: "center",
            },
            arrow: {
                rlw_width: 25,
                rlw_height: 25,
                rlw_marginLeft: 5,
                resizeMode: "stretch",
            },
        })
    );

    const rightArrow = require("../assets/arrows/arrow.right.circle.sf_regular.white.png");


    let user = useSelector((state) => state.user);

    return (
        <View style={styles.overlay}>
            <SafeAreaView edges={['top']}/>
            <View style={styles.container}>
                <CancelXButton onPress={onClose} color="#DADADA"/>
                <View style={styles.messageContainer}>
                    <Text style={styles.message}>{message}</Text>
                </View>
                <View style={styles.iconContainer}>
                    <Logo
                        userColor={user?.cosmeticMetadata?.color}
                        otherUserColor={fromUser?.cosmeticMetadata?.color}
                        logoSize={LogoSize.LARGE}
                    />
                </View>
                <View style={styles.buttonContainer}>
                    <TouchableOpacity
                        style={[
                            styles.button,
                            { backgroundColor: user?.cosmeticMetadata?.color },
                        ]}
                        onPress={onConfirm}
                    >
                        <Text style={styles.buttonText}>{buttonText}</Text>
                        <Image source={rightArrow} style={styles.arrow} />
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    );
};

export default Drawer(MatchRequestModal);
