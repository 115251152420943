// reducers/referralReducer.js

const initialState = {
    invitationCode: null,
    utmSource: null,
};

const referralReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'STORE_INVITATION_CODE':
            return { ...state, invitationCode : action.payload };
        case 'STORE_UTM_SOURCE':
            return { ...state, utmSource : action.payload };
        default:
            return state;
    }
};

export default referralReducer;