import { useEffect, useRef } from "react"
import { useSelector } from "react-redux"

import { setUserId, setUTMSource, logUserStatus } from "../utils"
import {
    EVENT_PRE_REGISTERED,
    EVENT_REGISTERED,
    EVENT_ONBOARDED,
    EVENT_PROFILE_COMPLETE
} from "../constants"

// this component is used to collect analytics data that can only be collected
// inside a react component
const Analytics = () => {
    const user = useSelector((state) => state.user)
    const utm = useSelector((state) => state.referral.utmSource)
    const prevStatusRef = useRef();

    useEffect(() => {
        // collect user id
        setUserId(user?.pk)

        // monitor user status
        const prevStatus = prevStatusRef.current;
        const currentStatus = user?.status;

        // Check if the status has changed
        if (prevStatus !== currentStatus) {
            switch(currentStatus) {
                case 'PRE_REGISTERED':
                    // notice, the pre_registered status only means user clicked the continue button
                    // in the home page. it doesn't mean whther this is a new user or registered user
                    // therefore, it's not a good way to use this as the first event in the funnel
                    logUserStatus(EVENT_PRE_REGISTERED)
                    break;
                case 'REGISTERED':
                    // user verified the phone number and identified as a new user
                    logUserStatus(EVENT_REGISTERED)
                    break;
                case 'ONBOARDED':
                    // user finished onboarding conversation and wait to upload images
                    logUserStatus(EVENT_ONBOARDED)
                    break;
                case 'PROFILE_COMPLETE':
                    // user uploaded all images and considered as a onboarded user
                    logUserStatus(EVENT_PROFILE_COMPLETE)
                    break;
                default:
                    break;
            }

            // Update the ref with the new status
            prevStatusRef.current = currentStatus;
        }
    }, [user])

    // collect utm source
    useEffect(() => {
        if (utm) {
            setUTMSource(utm)
        }
    }, [utm])

    return null
}

export default Analytics