import { useRef, useEffect } from "react";
import { Animated, View } from "react-native";
import { useScreenDimensions } from "../../utils/screenDimensions";

function Drawer(WrappedComponent) {
    return function WrappedWithDrawer(props) {
        const { isVisible, ...otherProps } = props;
        
        // Get the screen width
        const { windowWidth, windowHeight } = useScreenDimensions();

        // Initialize animation value based on screen width
        const animatedHeight = useRef(new Animated.Value(0)).current;
        const lastIsVisible = useRef(isVisible);

        useEffect(() => {
            if (lastIsVisible.current !== isVisible) {
                Animated.timing(animatedHeight, {
                    toValue: isVisible ? windowHeight: 0,
                    duration: 300,
                    useNativeDriver: false,
                }).start()
            } else {
                animatedHeight.setValue(isVisible ? windowHeight : 0);
            }
            lastIsVisible.current = isVisible;
        }, [isVisible, windowHeight]);

        return (
            <Animated.View
                style={{
                    flex: 1,
                    position: 'absolute',
                    width: "100%",
                    height: animatedHeight,
                    bottom: 0,
                    overflow: 'hidden',
                    zIndex: 2000,
                }}
            >
                <View
                    style={{
                        flex: 1,
                        width: windowWidth,
                        height: windowHeight,
                        position: 'absolute',
                        zIndex: 9000,
                    }}
                >
                    <WrappedComponent {...otherProps} />
                </View>
            </Animated.View>
        );
    };
}

export default Drawer;
