// screens/ChatListScreen.js
import { useCallback, useEffect, useState, useRef } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import { View, Platform } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAllChats, ChatWebSocketService } from '../services/chatService';
import { showNetworkError } from '../utils/errorHandlers';
import { resetNavigation } from '../utils/navigation';
import ChatListHeader from '../components/chats/ChatListHeader';
import expoNotification from '../utils/expoNotification';
import ChatTabView from '../components/chats/ChatTabView';
import ChatListFooter from '../components/chats/ChatListFooter';
import { useScreenDimensions } from '../utils/screenDimensions';
import { fetchUserDetails } from '../actions/userActions';
import { updateChat } from '../utils/chat';
import PageViewWrapper from '../firebase/components/pageViewWrapper';


const ChatListScreen = ({ navigation }) => {
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const [chats, setChats] = useState([]);
    const [isFooterVisible, setIsFooterVisible] = useState(true);
    const wsRef = useRef(null);
    const { windowHeight } = useScreenDimensions();
    const [headerHeight, setHeaderHeight] = useState(0);

    // in case user onboarded from another device or on web,
    // it will try to ask user for notification permission in
    // the chat list page
    useEffect(() => {
        if (user) {
            expoNotification.register(user)
        }
    }, [user?.pk]);

    // The chat list should be pulled every time the user lands on this screen, even it 
    // is from a navigation event, thus we use `useFocusEffect` instead of `useEffect`.
    useFocusEffect(
        useCallback(() => {
            const initChats = async () => {
                try {
                    const chats = await fetchAllChats(1, 20);
                    dispatch(fetchUserDetails());
                    setChats(chats);
                } catch (error) {
                    if (error.response.status === 401) {
                        resetNavigation(navigation);
                    } else if (error.message.includes('Network Error') || error.message?.includes('timeout')) {
                        showNetworkError();
                    } else {
                        console.error('Error fetching chats:', error);
                    }
                }
            }

            const initConnection = async () => {
                try {
                    wsRef.current = await ChatWebSocketService.init();
                    wsRef.current.setOnChatUpdateCallback(handleReceive);
                    wsRef.current.openConnection();
                } catch (error) {
                    if (error.response.status === 401) {
                        resetNavigation(navigation);
                    } else if (error.message.includes('Network Error') || error.message.includes('timeout')) {
                        showNetworkError();
                    } else {
                        console.error('Error initialize WebSocket connection:', error);
                    }
                }
            }

            if (user == null) {
                resetNavigation(navigation);
            } else {
                setIsFooterVisible(true);
                initChats();
                initConnection();
            }

            return () => {
                if (wsRef.current) {
                    wsRef.current.closeConnection();
                }
            };
        }, [user?.pk, navigation])
    );

    const handleReceive = (event) => {
        const data = JSON.parse(event.data);

        switch (data.type) {
            case 'chat_update':
                setChats(prevChats => {
                    let updatedChats = [...prevChats];
                    const chatIndex = updatedChats.findIndex(chat => chat.id === data.chatid);
                    if (chatIndex !== -1) {
                        updatedChats = updateChat(updatedChats, chatIndex, data)
                    }
                    return updatedChats;
                });
                break;
            default:
                if ("error" in data) {
                    console.error(data.error);
                } else {
                    console.error('Received unknown event type:', data.type);
                }
        }
    };

    const handleFooterPress = () => {
        setIsFooterVisible(false);
        navigation.navigate('Assistant');
    };

    return (
        <View style={{ flex: 1, backgroundColor: 'white' }}>
            <ChatListHeader
                onLayout={(event) => {
                    const { height } = event.nativeEvent.layout;
                    setHeaderHeight(height);
                }}
            />
            <ChatTabView
                navigation={navigation}
                chats={chats}
                maxHeight={Platform.OS === 'web' ?
                    windowHeight - headerHeight : undefined}
            />
            {isFooterVisible &&
                <ChatListFooter user={user} onPress={handleFooterPress} />
            }
        </View>
    );
};

export default PageViewWrapper(ChatListScreen);