// utils/errorHandlers.js
import { Alert, Platform } from 'react-native';

export const showNetworkError = () => {
    if (Platform.OS === 'web') {
        alert('Network error. Please check your internet connection.');
    } else {
        Alert.alert(
            "Network Error",
            "Please check your internet connection.",
            [
                { text: "OK" }
            ]
        );
    }
};