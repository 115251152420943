import React from 'react';

import { logButtonClicked } from '../utils';

/*************************************
 * This wrapper is used to intercept the button onPress event
 * and log it to firebase analytics.
 * Notice, in order to correctly use this wrapper, the wrapping
 * target must has props called onPress (analyticsProps is optional)
 * in it's props.
 * 
 * In practice, when wrapping a clickable component, there are generally
 * two cases:
 *    * The wrapped component has onPress in it's props. This is the most
 *      easy case, where you simply put the wrapper around the componnet function
 *      and pass the analytics label after that.
 *      example:
 *
 *      // original component
 *      const MyComponent = ({ onPress }) => {
 *        return <TouchableOpacity onPress={onPress} />
 *      }
 *
 *      // wrapped component
 *      // Notice the props is not limited to onPress, other
 *      // type of props will be passed through as well.
 *      const MyComponentWithAnalytics = ButtonClickWrapper(({ onPress }) => {
 *        return <TouchableOpacity onPress={onPress} />
 *      }, "BUTTON_CLICK_MyComponent")
 *
 *
 *    * The wrapped component does not have onPress in it's props. In other words,
 *      the component handles the onPress event inside the function. In this case,
 *      the last child component that contains onPress prop need to be extracted
 *      and wrapped to make the tracking work.
 *      example:
 *
 *      // original component
 *      const MyComponent = () => {
 *        const handleOnPress = () => { ...}
 *        return (
 *          <View>
 *           <TouchableOpacity onPress={handleOnPress} />
 *          </View>
 *        )
 *      }
 *
 *      // wrapped component
 *      const MyComponent = () => {
 *        const handleOnPress = () => { ...}
 *        
 *        // extracted button that's been wrapped
 *        const WrappedButton = ButtonClickWrapper(({ onPress }) => {
 *            return <TouchableOpacity onPress={onPress} />
 *        }, "BUTTON_CLICK_MyComponent")
 * 
 *        return (
 *          <View>
 *           <WrappedButton onPress={handleOnPress} />
 *          </View>
 *        )
 *      }
*************************************/

const ButtonClickWrapper = (WrappedComponent, ButtonLabel) => {
    const Wrapper = (props) => {
        let { analyticsProps, ...remainProps } = props;
        const onPress = () => {
            if (props.onPress) {
                props.onPress();

                logButtonClicked(ButtonLabel, analyticsProps)
            }
        };

        // Combine common props with passed props
        const combinedProps = {
            ...remainProps,
            onPress: onPress,
        };

        return <WrappedComponent {...combinedProps} />;
    };

    // Use the WrappedComponent's name in the displayName for easier debugging
    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    Wrapper.displayName = `ButtonClickWrapper(${wrappedComponentName})`;

    return Wrapper;
}

export default ButtonClickWrapper;
