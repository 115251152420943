// components/chat/RejectedNonAction.js
import {
    Text,
    StyleSheet,
} from "react-native";
import { useScreenDimensions } from "../../utils/screenDimensions";

const styles = StyleSheet.create({
    text: {
        fontFamily: 'IBMPlexSans_400Regular',
        color: '#999',
        textAlignVertical: 'center',
        textAlign: 'center',
    },
});

const RejectedNonAction = () => {
    const { adjustedWidth } = useScreenDimensions();
    const configValues = {
        fontSize: adjustedWidth * 0.0305,
        lineHeight: adjustedWidth * 0.0382,
        marginTop: adjustedWidth * 0.0000,
    };

    return (
        <Text style={[styles.text, {
            fontSize: configValues.fontSize,
            lineHeight: configValues.lineHeight,
            marginTop: configValues.marginTop,
        }]}>
            This conversation has ended
        </Text>
    );
};

export default RejectedNonAction;