import React from 'react'
import { Image, StyleSheet, TouchableOpacity } from "react-native";
import { useRelativeStyles } from '../../utils/screenDimensions';

export const CancelXButton = ({onPress, color}) => {
    const styles = StyleSheet.create(
        useRelativeStyles({
            cancel: {
                position: "absolute",
                rlw_top: 10,
                rlw_left: 10,
                paddingVertical: 3,
            },
            x: {
                // icon size is relative to width not height
                tintColor: color,
                rlw_height: 15,
                rlw_width: 15,
            },
        })
    )

    const cross = require("../../assets/arrows/xmark.sf_bold.png");

    return (
        <TouchableOpacity
            style={styles.cancel}
            onPress={onPress}
        >
            <Image
                source={cross}
                style={styles.x}
            />
        </TouchableOpacity>
    )
}

