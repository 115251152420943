// components/chat/RequestedAction.js
import {
    View,
    Text,
    StyleSheet,
} from "react-native";
import { useRelativeStyles } from "../../utils/screenDimensions";
import {
    getUserThumbnailButtonRLW, getUserThumbnailButtonOFF,
} from '../../utils/userUtils';
import ButtonClickWrapper from "../../firebase/components/buttonClickWrapper";
import { BUTTON_CLICK_CHAT_REJECT } from "../../firebase/constants";

const RequestedAction = ButtonClickWrapper(({ parentWidth, thumbnailSize, onPress }) => {
    const styles = StyleSheet.create(useRelativeStyles({
        messageBox: {
            flexDirection: 'row',
            alignItems: 'flex-end',
            justifyContent: 'flex-start',
            width: parentWidth,
        },
        text: {
            fontFamily: 'IBMPlexSans_400Regular',
            color: 'black',
            textAlignVertical: 'center',
            textAlign: 'center',
            rlw_fontSize: 12,
            rlw_lineHeight: 15,
            rlw_marginTop: 5,
            rlw_marginLeft: 11 + getUserThumbnailButtonRLW(thumbnailSize),
            off_marginLeft: getUserThumbnailButtonOFF(thumbnailSize),
        },
        nestedText: {
            fontFamily: 'IBMPlexSans_700Bold',
        },
    }));

    return (
        <View style={styles.messageBox}>
            <Text style={styles.text}>
                Respond to accept, or{' '}
                <Text
                    testID="decline-text"
                    style={styles.nestedText}
                    onPress={onPress}
                >
                    tap here to decline
                </Text>
            </Text>
        </View>
    );
}, BUTTON_CLICK_CHAT_REJECT);

export default RequestedAction;