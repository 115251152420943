// components/home/HelpText.js
import { Linking, Text, StyleSheet, Platform } from "react-native";
import { useRelativeStyles } from "../../utils/screenDimensions";

const HelpText = () => {
    const styles = StyleSheet.create(useRelativeStyles({
        text: {
            fontFamily: 'IBMPlexSans_400Regular',
            letterSpacing: 1,
            textAlign: 'center',
            color: 'white',
            rlw_fontSize: 12,
            rlw_lineHeight: 13,
            rlw_marginBottom: Platform.OS === 'ios' ? 40 : 16,
        },
    }));

    const openURL = (url) => {
        Linking.openURL(url);
    };

    return (
        <Text style={styles.text}>
            By continuing, you agree to our{' '}
            <Text
                style={{ textDecorationLine: 'underline' }}
                onPress={() => openURL('https://www.volardating.com/terms')}>
                Terms of Service
            </Text>
            .{'\n'} For more information, see our{' '}
            <Text
                style={{ textDecorationLine: 'underline' }}
                onPress={() => openURL('https://www.volardating.com/privacy')}>
                Privacy Notice
            </Text>
            .
        </Text>
    );
};

export default HelpText;