// components/boost/MenuCard/baseMenuModal.js
// the ui for the boost menu, no drawer effect yet
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    ActivityIndicator,
    View,
    Text,
    Image,
    Modal,
    StyleSheet,
} from "react-native";
import { useRelativeStyles } from "../../../utils/screenDimensions";
import PurchaseButton from "./PurchaseButton";
import { CancelXButton } from "../../common/closeButton";
import { closeBoostMenu } from "../../../actions/boostActions";
import { getUserCosmeticColor } from "../../../utils/userUtils";
import { shareLink } from "../../../utils/share";
import { createShareURL } from "../../../services/shareService";

const modalStyles = StyleSheet.create({
    modalBackground: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0,0,0,0.5)",
    },
    errModalContainer: {
        backgroundColor: "white",
        paddingTop: 48,
        padding: 32,
        borderRadius: 10,
    },
    ErrorText: {
        fontFamily: "IBMPlexSans_400Regular",
        fontSize: 16,
        textAlign: "center",
    },
});

const MenuModal = ({ isLoading, errMsg, onCloseError, onPurchase }) => {
    const dispatch = useDispatch();
    const options = useSelector((state) => state.boost.purchaseOptions);

    const user = useSelector((state) => state.user);
    const userColor = getUserCosmeticColor(user);

    const icon = require("../../../assets/boost/boostLogo_highres.png");

    let menuHeight = 350;

    const [referOptions, purchasableOptions] = options.reduce(
        ([refers, purchases], option) => {
            if (option.purchaseType == "refer") {
                refers.push(option);
            } else {
                purchases.push(option);
            }
            return [refers, purchases];
        },
        [[], []]
    );

    if (referOptions.length > 0) {
        menuHeight += 94;
    }

    if (
        purchasableOptions?.find(
            (option) => option.purchaseType == "subscription"
        )
    ) {
        menuHeight += 94;
    }

    const styles = StyleSheet.create(
        useRelativeStyles({
            cardContainer: {
                position: "relative",
                rlw_top: 25,
                rlw_width: 350,
                rlw_height: menuHeight,
                backgroundColor: "white",
                alignItems: "center",
                rlw_paddingTop: 50,
                rlw_borderRadius: 12,
                rlw_paddingBottom: 10,
            },
            iconContainer: {
                position: "absolute",
                // icon size is relative to width not height
                // since it's a circle
                rlw_top: -50,
                rlw_width: 100,
                rlw_height: 100,
                rlw_borderRadius: 50,
                backgroundColor: "#F3F631",
                justifyContent: "center",
                alignItems: "center",

                borderWidth: 10,
                borderColor: "white",
            },
            icon: {
                position: "relative",
                rlw_bottom: 5, // shift the icon up a bit to make it more visually centered
                rlw_width: 70,
                rlw_height: 70,
            },
            messageContainer: {
                rlw_height: 88,
                flexDirection: "column",
                alignItems: "center",
                rlw_marginBottom: 26,
            },
            textTitle: {
                fontFamily: "BricolageGrotesque_Bold",
                rlw_fontSize: 30,
                marginBottom: 12,
            },
            textDescription: {
                fontFamily: "BricolageGrotesque_Regular",
                rlw_fontSize: 16,
            },
            buttonContainer: {
                flex: 1,
                justifyContent: "space-evenly",
            },
        })
    );

    const shareProfile = async () => {
        const link = await createShareURL();
        await shareLink(link);
    };

    return (
        <>
            <Modal transparent={true} animationType="none" visible={isLoading}>
                <View style={modalStyles.modalBackground}>
                    <ActivityIndicator size="large" color={userColor} />
                </View>
            </Modal>

            <Modal
                transparent={true}
                visible={errMsg != null}
                onRequestClose={onCloseError}
            >
                <View style={modalStyles.modalBackground}>
                    <View style={modalStyles.errModalContainer}>
                        <Text style={modalStyles.ErrorText}>{errMsg}</Text>
                        <CancelXButton onPress={onCloseError} color="#5A5A5A" />
                    </View>
                </View>
            </Modal>
            <View style={styles.cardContainer}>
                <CancelXButton
                    onPress={() => {
                        dispatch(closeBoostMenu());
                    }}
                    color="#5A5A5A"
                />
                <View style={styles.iconContainer}>
                    <Image source={icon} style={styles.icon} />
                </View>
                <View style={styles.messageContainer}>
                    <Text style={styles.textTitle}>Boost to the Top</Text>
                    <Text style={styles.textDescription}>
                        {
                            "Boosted messages are at the\ntop of their conversation list"
                        }
                    </Text>
                </View>
                <View style={styles.buttonContainer}>
                    {referOptions?.map((option) => {
                        return (
                            <PurchaseButton
                                option={option}
                                key={option.pruchaseType + option.targetId}
                                onPress={shareProfile}
                            />
                        );
                    })}
                    {purchasableOptions?.map((option) => {
                        return (
                            <PurchaseButton
                                option={option}
                                key={option.pruchaseType + option.targetId}
                                onPress={() => onPurchase(option)}
                            />
                        );
                    })}
                </View>
            </View>
        </>
    );
};

export default MenuModal;
