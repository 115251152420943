export const updateChat = (chats, tgtChatIdx, data) => {
    chats[tgtChatIdx].lastMessage = {
        author: {
            username: data.last_message.username,
            userid: data.last_message.userid,
            gender: data.last_message.usergender,
        },
        content: data.last_message.message,
        type: data.last_message.messagetype,
        created: data.last_message.messagecreated,
        isBoosted: data.last_message.is_boosted === "True",
    };
    chats[tgtChatIdx].isActive = data.chatisactive;

    if (data.last_message_is_boosted === "True") {
        chats[tgtChatIdx].hasUnreadBoostedMessage = true;
        // this field will be only reset when user entering the chat room
        // when user exit the chat room, the offline fetch will be triggered
        // and therefore reset this field
    }

    return updateChatsOrder(chats, tgtChatIdx)
}

export const updateChatsOrder = (chats, tgtChatIdx) => {
    // Pop the updated chat out of the array
    const [updatedChat] = chats.splice(tgtChatIdx, 1);
    chats.unshift(updatedChat);
    return chats
}