// notification/provider.js
// this is for in-app notification system using ws connect to probius
import { createContext, useReducer, useState, useEffect, useRef } from "react";

import { useUserState, UserState } from "../components/common/useUserState";
import { NotificationService } from "../services/notificationService";
import { createNotification, NotificationState } from "../models/Notification";
import Queue from "./queue";

export const NotificationContext = createContext({
    modalQueue: Queue.defaultState,
    popMatchQueue: () => {},
});

// communication layer that mainly handles the connection and receives the message
// no message handling logic should be included here
const NotificationProvider = ({ children }) => {
    const userState = useUserState();
    const [isConnected, setIsConnected] = useState(false);
    const wsRef = useRef(null);

    // Local Notification Queue
    // Define different queues for different types of messages
    const [modalQueue, dispatchModalQueue] = useReducer(
        Queue.reducer,
        Queue.defaultState
    );
    const popModalQueue = () => {
        dispatchModalQueue(Queue.pop());
    };

    const notificationHandler = (message) => {
        if (message?.data == null) {
            console.error("message is null");
            return;
        }

        let data;
        try {
            data = JSON.parse(message.data);
        } catch (e) {
            console.error("can't parse incoming notification message:", e);
            return;
        }

        let notification = createNotification(data);

        switch (notification.type) {
            case NotificationState.FIRST_MATCH:
            case NotificationState.DAILY_MATCH:
            case NotificationState.ACCEPTED_REQUEST:
            case NotificationState.REQUEST:
                dispatchModalQueue(Queue.push(notification));
                break;
            case NotificationState.Message:
                // we don't show message notification yet
                break;
            default:
                console.error("message not supported:", message);
        }
    };

    // Notification Connection
    // Init NotificationService
    useEffect(() => {
        wsRef.current = new NotificationService();
        wsRef.current.setOnChatUpdateCallback(notificationHandler);
    }, []);

    // Connect/Disconnect
    useEffect(() => {
        try {
            if (wsRef.current) {
                if (userState === UserState.online) {
                    if (!isConnected) {
                        wsRef.current.openConnection().then(() => {
                            console.log(
                                "==================user online========================="
                            );
                            setIsConnected(true);
                        });
                    } else {
                        // connection has set up
                    }
                } else {
                    // userState === UserState.offline
                    if (!isConnected) {
                        // no connection
                    } else {
                        wsRef.current.closeConnection();
                        setIsConnected(false);
                        console.log(
                            "==================user offline========================="
                        );
                    }
                }
            }
        } catch (e) {
            console.error("error:", e);
        }
    }, [userState, wsRef]);

    return (
        <NotificationContext.Provider
            value={{
                modalQueue,
                popModalQueue,
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
};

export default NotificationProvider;
