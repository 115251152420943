// components/chats/Countdown.js
import { useState, useEffect } from 'react';
import { View, Text, StyleSheet } from "react-native";
import Svg, { Rect, Mask, Image as SVGImage } from 'react-native-svg';
import { useRelativeStyles } from "../../utils/screenDimensions";
import { getCosmeticFontColor } from '../../utils/cosmetics';

const Countdown = ({ user }) => {
    const [displayText, setDisplayText] = useState('0:00h');
    const headerImagePath = require('../../assets/common/chat/header_mask.png');
    const styles = StyleSheet.create(useRelativeStyles({
        view: {
            backgroundColor: 'white',
            rlw_width: 341,
            rlw_height: 104,
            rlw_borderRadius: 10,
            overflow: 'hidden',
        },
        background: {
            position: 'absolute',
            left: 0,
            bottom: 0,
            zIndex: 1,
            rlw_width: 341,
            rlw_height: 104,
        },
        container: {
            position: 'absolute',
            left: 0,
            bottom: 0,
            zIndex: 2,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            rlw_width: 341,
            rlw_height: 104,
        },
        countdownView: {
            rlw_marginHorizontal: 14,
            rlw_width: 85,
            rlw_height: 85,
            rlw_borderRadius: 42.5,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'white',
        },
        countdownText: {
            rlw_fontSize: 22,
            rlw_lineHeight: 27.5,
            fontFamily: 'BricolageGrotesque_Regular',
            textAlign: 'center',
        },
        textView: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        mainText: {
            rlw_fontSize: 20,
            rlw_lineHeight: 25,
            fontFamily: 'IBMPlexSans_700Bold',
            textAlign: 'left',
            textAlignVertical: 'center',
            color: getCosmeticFontColor(user?.cosmeticMetadata),
        },
        smallText: {
            fontFamily: 'IBMPlexSans_400Regular',
            rlw_fontSize: 16,
            rlw_lineHeight: 16,
            rlw_marginTop: 6,
            color: getCosmeticFontColor(user?.cosmeticMetadata),
        },
    }));

    useEffect(() => {
        const calculateTimeLeft = () => {
            const currentTime = new Date();
            const nextMatchTime = new Date(user?.nextMatchTime);
            const timeDifference = nextMatchTime - currentTime;
            if (timeDifference > 0) {
                const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
                const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
                setDisplayText(`${hours}:${minutes.toString().padStart(2, '0')}h`);
            } else {
                setDisplayText('0:00h');
            }
        };

        calculateTimeLeft();
        const timer = setInterval(calculateTimeLeft, 60000);

        return () => clearInterval(timer);
    }, [user?.nextMatchTime]);

    return (
        <View style={styles.view}>
            <View style={styles.background}>
                <Svg width="100%" height="100%" position='absolute'>
                    <Mask id="mask" x="0" y="0" height="100%" width="100%">
                        <SVGImage width="100%" height="100%"
                            preserveAspectRatio="xMidYMax slice"
                            href={headerImagePath}
                        />
                    </Mask>
                    <Rect x="0" y="0" width="100%" height="100%"
                        fill={user?.cosmeticMetadata?.color}
                        mask="url(#mask)"
                    />
                </Svg>
            </View>
            <View style={styles.container}>
                <View style={styles.countdownView}>
                    <Text style={styles.countdownText}  selectable={false} testID='countdown-text'>
                        {displayText}
                    </Text>
                </View>
                <View style={styles.textView}>
                    <Text style={styles.mainText} selectable={false}>
                        Until your next match
                    </Text>
                    <Text style={styles.smallText} selectable={false}>
                        New matches every day!
                        {/* New matches every day at{'\n'}8am, 4pm, and midnight! */}
                    </Text>
                </View>
            </View>
        </View>
    );
};

export default Countdown;