import { Platform } from "react-native";
import * as Application from "expo-application";
import { emitEvent, setUserId as nativeSetUserId } from "./init";
import {
    EVENT_PAGE_VIEW,
    EVENT_BUTTON_CLICKED,
    EVENT_MESSAGE_SENT,
    EVENT_IMAGE_UPLOAD,
    EVENT_NOTIFICATION,
    EVENT_COMPONENT_RENDERED,
} from "./constants";

const eventBaseParams = {
    platform: Platform.OS,
    app_version: Application.nativeApplicationVersion || "web",
    utm_source: null,
};

export const setUserId = (userId) => {
    if (userId) {
        // need to check if the user id is null or undefined
        // because native firebase doesn't allow to use null
        // or undefined as user id
        nativeSetUserId(userId);
    }
};

export const setUTMSource = (utmSource) => {
    if (utmSource != eventBaseParams.utm_source) {
        eventBaseParams.utm_source = utmSource;
    }
};

export const logScreenView = (screenName, params) => {
    try {
        emitEvent(EVENT_PAGE_VIEW, {
            ...eventBaseParams,
            ...params,
            screen_name: screenName,
        });
    } catch (error) {
        console.error("Error logging message sent:", error);
    }
};

export const logButtonClicked = (buttonName, params) => {
    try {
        emitEvent(EVENT_BUTTON_CLICKED, {
            ...eventBaseParams,
            ...params,
            button_name: buttonName,
        });
    } catch (error) {
        console.error("Error logging message sent:", error);
    }
};

export const logMessageSent = (chatType, chatId, message, isBoosted=false) => {
    try {
        // trunk message to 20 characters because firebase has 40 char limit for value
        if (message.length > 20) {
            message = message.slice(0, 20) + '...';
        }
        emitEvent(EVENT_MESSAGE_SENT, {
            ...eventBaseParams,
            chat_type: chatType,
            chat_id: chatId,
            message: message, 
            is_boosted: isBoosted,
        });
    } catch (error) {
        console.error("Error logging message sent:", error);
    }
};

export const logImageUpload = (psduoId, status) => {
    try {
        emitEvent(EVENT_IMAGE_UPLOAD, {
            ...eventBaseParams,
            img_psduo_id: psduoId,
            status: status,
        });
    } catch (error) {
        console.error("Error logging image upload:", error);
    }
};

export const logNotification = (notificationType, params) => {
    try {
        emitEvent(EVENT_NOTIFICATION, {
            ...eventBaseParams,
            notification_type: notificationType,
            ...params,
        });
    } catch (error) {
        console.error("Error logging notification:", error);
    }
}

export const logUserStatus = (status) => {
    try {
        emitEvent(status, eventBaseParams);
    } catch (error) {
        console.error("Error logging user status:", error);
    }
}

export const logComponentRendered = (componentName, params) => {
    try {
        emitEvent(EVENT_COMPONENT_RENDERED, {
            ...eventBaseParams,
            name: componentName,
            ...params,
        });
    } catch (error) {
        console.error("Error logging component rendered:", error);
    }
}
