export const EVENT_PAGE_VIEW = "volar_page_view";
export const EVENT_BUTTON_CLICKED = "volar_button_clicked";
export const EVENT_MESSAGE_SENT = "volar_message_sent";
export const EVENT_IMAGE_UPLOAD = "volar_image_upload";
export const EVENT_NOTIFICATION = "volar_notification";
export const EVENT_COMPONENT_RENDERED = "volar_component_rendered";

// user onboarding funnel
export const EVENT_PRE_REGISTERED = "volar_pre_registered";
export const EVENT_REGISTERED = "volar_registered";
export const EVENT_ONBOARDED = "volar_onboarded";
export const EVENT_PROFILE_COMPLETE = "volar_profile_complete";

// images uploading status
export const IMAGE_UPLOAD_START = "image_upload_start";
export const IMAGE_UPLOAD_SELECTED = "image_upload_selected";
export const IMAGE_UPLOAD_RESIZING = "image_upload_resizing";
export const IMAGE_UPLOAD_UPLOADING = "image_upload_uploading";
export const IMAGE_UPLOAD_DONE = "image_upload_done";

// notification in app
export const NOTIFICATION_FOREGROUND_RECEIVED = "notification_foreground_received";
export const NOTIFICATION_BACKGROUND_RECEIVED = "notification_background_received";

// buttons in home
export const BUTTON_CLICK_HOME_CONTINUE = "home_continue"

// buttons in onboarding 
export const BUTTON_CLICK_ONBOARDING_CONTINUE = "onboarding_continue"
export const BUTTON_CLICK_ONBOARDING_UPLOAD_PHOTO = "onboarding_upload_photo"
export const BUTTON_CLICK_ONBOARDING_LOGOUT = "onboarding_logout"

// buttons in upload photo
export const BUTTON_CLICK_UPLOAD_PHOTO_CONTINUE = "upload_photo_continue"
export const BUTTON_CLICK_UPLOAD_PHOTO_BACK = "upload_photo_back"
export const BUTTON_CLICK_UPLOAD_PHOTO_UPLOAD = "upload_photo_upload"
export const BUTTON_CLICK_UPLOAD_PHOTO_DELETE = "upload_photo_delete"

// button in chat list
export const BUTTON_CLICK_CHATS_MY_PROFILE = "chats_my_profile"
export const BUTTON_CLICK_CHATS_CHAT = "chats_chat"
export const BUTTON_CLICK_CHATS_SHARE_PROFILE = "chats_share_profile"
export const BUTTON_CLICK_CHATS_ASSISTANT = "chats_assistant"

// button in chat
export const BUTTON_CLICK_CHAT_HEADER_PROFILE = "chat_header_profile"
export const BUTTON_CLICK_CHAT_HEADER_BOOST = "chat_header_boost"
export const BUTTON_CLICK_CHAT_CHAT_PROFILE = "chat_chat_profile"
export const BUTTON_CLICK_CHAT_REJECT = "chat_reject"
export const BUTTON_CLICK_CHAT_CHAT_BOOST = "chat_chat_chat_boost"
export const BUTTON_CLICK_CHAT_ASSISTANT = "chat_assistant"

// button in assistant
export const BUTTON_CLICK_ASSISTANT_BUY_BOOST = "assistant_buy_boost"
export const BUTTON_CLICK_ASSISTANT_EMAIL_SUPPORT = "assistant_email_support"
export const BUTTON_CLICK_ASSISTANT_SHARE_PROFILE = "assistant_share_profile"
export const BUTTON_CLICK_ASSISTANT_GO_BACK = "assistant_go_back"
export const BUTTON_CLICK_ASSISTANT_LOGOUT = "assistant_logout"
export const BUTTON_CLICK_ASSISTANT_DELETE_ACCOUNT = "assistant_delete_account"
export const BUTTON_CLICK_ASSISTANT_HINT = "assistant_hint"
export const BUTTON_CLICK_ASSISTANT_CLOSE = "assistant_close"

