// models/Chat.js

import { createMessage } from "./Message";
import { createSimpleUser } from "./SimpleUser";

export const ChatType = {
    'FIRSTDATE': 'first_date',
    'REQUESTED': 'requested',
    'ISREQUESTED': 'is_requested',
    'MESSAGE': 'message',
    'INVALID': 'invalid',
};

export const createChat = (data) => {
    if (data == null) {
        return null;
    } else {
        return {
            id: data.id,
            name: data.name,
            users: data.users.map(user => createSimpleUser(user)),
            created: data.created,
            modified: data.modified,
            isActive: data.is_active,
            lastReadMessage: createMessage(data.last_read_message),
            lastMessage: createMessage(data.last_message),
            isBoosted: data.is_boosted,
            hasUnreadBoostedMessage: data.has_unread_boosted_message,
        };
    }
};