import { NOTIFICATION_BACKGROUND_RECEIVED } from "../firebase/constants";
import { logNotification } from "../firebase/utils";
import { NotificationState } from "../models/Notification";

// this is for background push notification
export const backgroundNotificationHandler = (response, navigate) => {
    console.log("notification response:", response)

    const extra = response?.notification?.request?.content?.data;
    console.log("notification extra payload:", extra)

    const chatId = response?.notification?.request?.content?.data?.chat_id;
    const type = response?.notification?.request?.content?.data?.type;
    console.log("notification type:", type, "chat_id:", chatId)

    // check if user has logged in
    // possible types:
    switch (type) {
        case NotificationState.REQUEST:
        case NotificationState.FIRST_MATCH:
        case NotificationState.DAILY_MATCH:
        case NotificationState.ACCEPTED_REQUEST:
        case NotificationState.MESSAGE:
            if (chatId) {
                navigate('Chat', { chatId: chatId })
            } else {
                console.error("new chats notification misses chat_id, payload:", extra)
            }

            // analytics
            logNotification(NOTIFICATION_BACKGROUND_RECEIVED, {
                notification_type: type,
            })
            break;
        default:
            console.error("unknwon notification type", type)
    }
}
