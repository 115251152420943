// models/SimpleUser.js
import { createCosmeticMetadata } from './CosmeticMetadata';

export const createSimpleUser = (data) => {
    if (data == null) {
        return null;
    } else {
        return {
            userid: data.id,
            firstName: data.first_name,
            lastName: data.last_name,
            gender: data.gender,
            age: data.age !== null ? data.age : 0,
            location: data.location !== null ? data.location : '',
            thumbnail: data.thumbnail,
            cosmeticMetadata: createCosmeticMetadata(data.cosmetic_metadata),
            datetimeMatched: data.datetime_matched,
        };
    }
};