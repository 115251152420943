// utils/share.js
import { Platform, Share } from "react-native";

export const shareLink = async (link) => {
    if (Platform.OS === 'ios') {
        try {
            await Share.share({
                message: link,
            });
        } catch (error) {
            console.error('Error sharing:', error);
        }
    } else if (Platform.OS === 'web') {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'Share Link',
                    text: '',
                    url: link,
                });
            } catch (error) {
                console.error('Error sharing:', error);
            }
        } else {
            try {
                await navigator.clipboard.writeText(link);
                alert('Sharing link copied to clipboard!');
            } catch (err) {
                alert('Please use the following link to share: ' + link);
            }
        }
    }
};