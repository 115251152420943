// components/SystemChatBubbleBody.js
import { StyleSheet, View, Text } from "react-native";
import { useRelativeStyles } from "../utils/screenDimensions";
import {
    getUserCosmeticColor, UserThumbnailSize,
    getUserThumbnailButtonRLW, getUserThumbnailButtonOFF,
} from '../utils/userUtils';
import { getCosmeticFontColor } from "../utils/cosmetics";
import UserThumbnail from "./UserThumbnail";
import BlinkingCursor from "./BlinkingCursor";

const SystemChatBubbleBody = ({ message, isMyMessage, cosmeticColor, thumbnailSize, isStreaming }) => {
    const styles = StyleSheet.create(useRelativeStyles({
        container: {
            flexDirection: 'row',
            alignItems: 'flex-end',
            alignSelf: 'center',
            justifyContent: isMyMessage ? 'flex-end' : 'flex-start',
            rlw_marginVertical: 6,
            rlw_marginHorizontal: 15,
            rlw_width: 363,
        },
        messageBubble: {
            backgroundColor: isMyMessage ?
                (cosmeticColor != null ? 'white' : getUserCosmeticColor(message.author)) :
                'transparent',
            alignSelf: isMyMessage ? 'flex-end' : 'flex-start',
            borderColor: (isMyMessage && cosmeticColor != null) ? cosmeticColor : 'black',
            borderWidth: (isMyMessage && cosmeticColor != null) ? 2 : 0,
            rlw_borderRadius: 16,
            rlw_marginLeft: isMyMessage ? 66 : 11,
            rlw_marginRight: isMyMessage ? 11 : 66,
            rlw_paddingHorizontal: 16,
            rlw_paddingVertical: 8,
            rlw_maxWidth: 286 - (isMyMessage ? getUserThumbnailButtonRLW(thumbnailSize) : 0),
            off_maxWidth: (isMyMessage && cosmeticColor == null) ?
                - getUserThumbnailButtonOFF(thumbnailSize) : 0
        },
        message: {
            fontFamily: 'IBMPlexSans_400Regular',
            color: isMyMessage ?
                (cosmeticColor != null ? 'black' : getCosmeticFontColor(message.author.cosmeticMetadata)) :
                'white',
            rlw_fontSize: 16,
            rlw_lineHeight: 20,
        },
        emptyIcon: {
            rlw_width: getUserThumbnailButtonRLW(thumbnailSize),
            rlw_height: getUserThumbnailButtonRLW(thumbnailSize),
            rlw_borderRadius: getUserThumbnailButtonRLW(thumbnailSize) / 2,
        },
    }));

    return (
        <View style={styles.container}>
            <View style={styles.messageBubble}>
                <Text style={styles.message}>
                    {message.content}
                    {isStreaming && <BlinkingCursor />}
                </Text>
            </View>
            {isMyMessage && (cosmeticColor != null ?
                <View style={[styles.emptyIcon, {
                    backgroundColor: 'white',
                }]}>
                    <View style={[styles.emptyIcon, {
                        backgroundColor: cosmeticColor,
                    }]} />
                </View> :
                <UserThumbnail
                    user={message.author}
                    thumbnailSize={UserThumbnailSize.SMALL}
                    hasBorder={true}
                    onPress={() => { }}
                    isDark={true}
                />
            )}
        </View>
    );
};

export default SystemChatBubbleBody;