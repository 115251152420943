// components/assistant/HintBubble.js
import { StyleSheet, View } from "react-native";
import { useRelativeStyles } from "../../utils/screenDimensions";
import { UserThumbnailSize } from '../../utils/userUtils';
import { UserStatus } from "../../models/User";
import UserThumbnail from "../UserThumbnail";
import HintButton from "./HintButton";
import HintNonAction from "./HintNonAction";

const HintBubble = ({ user, setText }) => {
    const buttonText = {
        default: [
            'More about me',
            'Send to friend',
            'Buy a Boost',
        ],
        fallback: [
            'Email support',
            'Sign out',
        ],
    };
    const styles = StyleSheet.create(useRelativeStyles({
        container: {
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            alignSelf: 'center',
            rlw_marginVertical: 6,
            rlw_marginHorizontal: 15,
            rlw_width: 363,
        },
        messageBox: {
            flexDirection: 'row',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            rlw_width: 363,
        },
    }));

    const getButtonText = () => {
        switch (user?.status) {
            case UserStatus.PROFILE_COMPLETE:
                return buttonText.default;
            default:
                return buttonText.fallback;
        }
    };

    return (
        <View style={styles.container}>
            <View style={styles.messageBox}>
                {getButtonText().map((text, _) => (
                    <HintButton
                        user={user}
                        onPress={() => setText(text)}
                        text={text}
                        key={text}
                        analyticsProps={{hint_text: text}}
                    />
                ))}
                <UserThumbnail
                    user={user}
                    thumbnailSize={UserThumbnailSize.SMALL}
                    hasBorder={false}
                    onPress={() => { }}
                    isDark={true}
                />
            </View>
            <HintNonAction
                parentWidth={styles.container.width}
                thumbnailSize={UserThumbnailSize.SMALL}
            />
        </View>
    );
};

export default HintBubble;