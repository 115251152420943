// components/ProfileImage.js
import { View, TouchableOpacity, Image, StyleSheet } from "react-native";
import { useRelativeStyles } from "../../utils/screenDimensions";
import ButtonClickWrapper from "../../firebase/components/buttonClickWrapper";
import {
    BUTTON_CLICK_UPLOAD_PHOTO_UPLOAD,
    BUTTON_CLICK_UPLOAD_PHOTO_DELETE
} from "../../firebase/constants";

const UploadImageButton = ButtonClickWrapper(({ onPress }) => {
    const buttonImagePath = require("../../assets/arrows/camera.circle.sf_light.png");
    const styles = StyleSheet.create(
        useRelativeStyles({
            button: {
                borderWidth: 1,
                borderColor: "black",
                borderStyle: "dashed",
                backgroundColor: "#EBEBEB",
                justifyContent: "center",
                alignItems: "center",
                rlw_width: 350,
                rlw_height: 350,
                rlw_borderRadius: 16,
                rlw_margin: 12,
            },
            image: {
                rlw_width: 48,
                rlw_height: 48,
            },
        })
    );

    return (
        <TouchableOpacity
            activeOpacity={0.7}
            style={styles.button}
            onPress={onPress}
        >
            <Image source={buttonImagePath} style={styles.image} />
        </TouchableOpacity>
    );
}, BUTTON_CLICK_UPLOAD_PHOTO_UPLOAD);

const ImageDisplay = ({
    imageURL,
    isEditing,
    onPressDelete,
    onLongPress,
    onImagePress,
}) => {
    const deleteImagePath = require("../../assets/arrows/minus.circle.fill.sf_bold.png");
    const styles = StyleSheet.create(
        useRelativeStyles({
            display: {
                borderWidth: 0,
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
                rlw_width: 350,
                rlw_height: 350,
                rlw_borderRadius: 16,
                rlw_margin: 12,
            },
            image: {
                rlw_width: 350,
                rlw_height: 350,
            },
            deleteButton: {
                position: "absolute",
                zIndex: 1,
                top: 0,
                left: 0,
                justifyContent: "center",
                alignItems: "center",
                rlw_width: 32,
                rlw_height: 32,
            },
            deleteImage: {
                tintColor: "#FF3737",
                rlw_width: 24,
                rlw_height: 24,
            },
        })
    );

    const DeleteButton = ButtonClickWrapper(({ onPress }) => {
        return (
            <TouchableOpacity
                style={styles.deleteButton}
                activeOpacity={1}
                onPress={onPress}
            >
                <Image source={deleteImagePath} style={styles.deleteImage} />
            </TouchableOpacity>
        );
    }, BUTTON_CLICK_UPLOAD_PHOTO_DELETE);

    return (
        <View>
            <TouchableOpacity
                activeOpacity={1}
                style={styles.display}
                onLongPress={onLongPress}
                onPress={onImagePress}
            >
                <Image source={{ uri: imageURL }} style={styles.image} />
            </TouchableOpacity>
            {isEditing && <DeleteButton onPress={onPressDelete} />}
        </View>
    );
};

const ProfileImage = ({
    images,
    index,
    isEditing,
    onPressUpload,
    onPressDelete,
    onLongPress,
    onImagePress,
}) => {
    return index < images.length ? (
        <ImageDisplay
            imageURL={images[index].presignedURL}
            isEditing={isEditing}
            onLongPress={onLongPress}
            onImagePress={onImagePress}
            onPressDelete={onPressDelete}
        />
    ) : (
        <UploadImageButton
            onPress={onPressUpload}
            analyticsProps={{ index: index }}
        />
    );
};

export default ProfileImage;
