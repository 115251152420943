import { BASE_URL, WS_BASE_URL } from '@env';

const endpoints = {
    // apis
    START: '/start/',
    START_ASSISTANT: '/start/assistant/',
    SIGNUP: '/auth/registration/',
    LOGIN: '/auth/login/',
    PHONE_LOGIN: '/auth/phone/',
    PHONE_VERIFY: '/auth/phone_verify/',
    EMAIL_LOGIN: '/auth/email/',
    EMAIL_VERIFY: '/auth/email_verify/',
    GOOGLE_AUTH: '/auth/google/',
    USER_DETAILS: '/auth/user/',
    USER_BASE: '/auth/',
    USER_DELETE: '/auth/user/delete/',
    USER_NOTIFICATION_TOKEN_URL: '/auth/user/push_token/',
    CHAT: '/chat/all_chats/',
    CHAT_HISTORY: '/chat/',
    PROFILE_BASE: '/profile/',
    PROFILE_MYIMAGES_BASE: '/profile/my_images/',
    PROFILE_MYIMAGES_STAGING: '/profile/my_images/staging/',
    IMAGE_UPLOAD_PRESIGNED: '/core/upload/generate_presigned_url/',
    IMAGE_UPLOAD_CONFIRM: '/core/upload/confirm_upload/',
    SHARE_CREATE: '/invitation/createcode/',
    SHARE_USE_BASE: '/invitation/usecode/',
    BOOST_COUNT: '/paywall/boosts/count/',
    BOOST_SUBSCRIPTION: '/paywall/boost/purchase/subscription/',
    BOOST_ON_DEMAND: '/paywall/boost/purchase/on_demand/',
    BOOST_PURCHASE_OPTIONS: '/paywall/boost/purchase/options/',

    // websockets
    WS_CHAT: '/ws/chat/',
    WS_CHAT_LIST: '/ws/chat_list/',
    WS_NOTIFICATION: '/ws/notifications/',
};

export const START_URL = `${BASE_URL}${endpoints.START}`;
export const START_ASSISTANT_URL = `${BASE_URL}${endpoints.START_ASSISTANT}`;
export const SIGNUP_URL = `${BASE_URL}${endpoints.SIGNUP}`;
export const LOGIN_URL = `${BASE_URL}${endpoints.LOGIN}`;
export const PHONE_LOGIN_URL = `${BASE_URL}${endpoints.PHONE_LOGIN}`;
export const PHONE_VERIFY_URL = `${BASE_URL}${endpoints.PHONE_VERIFY}`;
export const EMAIL_LOGIN_URL = `${BASE_URL}${endpoints.EMAIL_LOGIN}`;
export const EMAIL_VERIFY_URL = `${BASE_URL}${endpoints.EMAIL_VERIFY}`;
export const GOOGLE_AUTH_URL = `${BASE_URL}${endpoints.GOOGLE_AUTH}`;
export const USER_DETAILS_URL = `${BASE_URL}${endpoints.USER_DETAILS}`;
export const USER_BASE_URL = `${BASE_URL}${endpoints.USER_BASE}`;
export const USER_DELETE_URL = `${BASE_URL}${endpoints.USER_DELETE}`;
export const USER_NOTIFICATION_TOKEN_URL = `${BASE_URL}${endpoints.USER_NOTIFICATION_TOKEN_URL}`;
export const CHAT_URL = `${BASE_URL}${endpoints.CHAT}`;
export const CHAT_HISTORY_URL = `${BASE_URL}${endpoints.CHAT_HISTORY}`;
export const PROFILE_BASE_URL = `${BASE_URL}${endpoints.PROFILE_BASE}`;
export const PROFILE_MYIMAGES_BASE_URL = `${BASE_URL}${endpoints.PROFILE_MYIMAGES_BASE}`;
export const PROFILE_MYIMAGES_STAGING_URL = `${BASE_URL}${endpoints.PROFILE_MYIMAGES_STAGING}`;
export const IMAGE_UPLOAD_PRESIGNED_URL = `${BASE_URL}${endpoints.IMAGE_UPLOAD_PRESIGNED}`;
export const IMAGE_UPLOAD_CONFIRM_URL = `${BASE_URL}${endpoints.IMAGE_UPLOAD_CONFIRM}`;
export const SHARE_CREATE_URL = `${BASE_URL}${endpoints.SHARE_CREATE}`;
export const SHARE_USE_BASE_URL = `${BASE_URL}${endpoints.SHARE_USE_BASE}`;

export const WS_CHAT_URL = `${WS_BASE_URL}${endpoints.WS_CHAT}`;
export const WS_CHAT_LIST_URL = `${WS_BASE_URL}${endpoints.WS_CHAT_LIST}`;
export const WS_NOTIFICATION_URL = `${WS_BASE_URL}${endpoints.WS_NOTIFICATION}`;

export const BOOST_COUNT_URL = `${BASE_URL}${endpoints.BOOST_COUNT}`;
export const BOOST_PURCHASE_OPTIONS_URL = `${BASE_URL}${endpoints.BOOST_PURCHASE_OPTIONS}`;
export const BOOST_SUBSCRIPTION_URL = `${BASE_URL}${endpoints.BOOST_SUBSCRIPTION}`;
export const BOOST_ON_DEMAND_URL = `${BASE_URL}${endpoints.BOOST_ON_DEMAND}`;

export const START_SECRET = 'volar-key-7OnHGK1PF1BbFcAw';