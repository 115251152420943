// services/common.js
import AsyncStorage from '@react-native-async-storage/async-storage';

export async function setToken(token) {
    try {
        await AsyncStorage.setItem('token', token);
    } catch (error) {
        console.error('Error in updating token: ', error);
    }
}

export async function getToken() {
    try {
        const token = await AsyncStorage.getItem('token');
        return token != null ? token : null;
    } catch (error) {
        console.error('Error in getting token: ', error);
        return null;
    }
};

export async function removeToken() {
    try {
        await AsyncStorage.removeItem('token');
    } catch (error) {
        console.error('Error in removing token: ', error);
    }
};
