// utils/animation.js
export const cardStyleInterpolatorForSlideFromRight = ({ current, layouts }) => {
    return {
        cardStyle: {
            transform: [
                {
                    translateX: current.progress.interpolate({
                        inputRange: [0, 1],
                        outputRange: [layouts.screen.width, 0],
                    }),
                },
            ],
        },
    };
};

export const cardStyleInterpolatorForSlideFromBottom = ({ current, layouts }) => {
    return {
        cardStyle: {
            transform: [
                {
                    translateY: current.progress.interpolate({
                        inputRange: [0, 1],
                        outputRange: [layouts.screen.height, 0],
                    }),
                },
            ],
        },
    };
};