import * as Linking from 'expo-linking';

const prefix = Linking.createURL('/');

export default {
    prefixes: [
        prefix,
        'http://localhost:19006',
    ],
    config: {
        screens: {
            Home: '',
            Onboarding: 'onboarding',
            UploadImage: 'upload',
            Chats: 'chats',
            Chat: 'chat',
            Assistant: 'assistant',
            Terms: 'terms',
            Privacy: 'privacy',
        },
    },
};