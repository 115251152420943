// components/UserBio.js
import { View, Text, StyleSheet } from 'react-native'
import { useSelector } from 'react-redux';
import { useRelativeStyles } from '../../utils/screenDimensions';
import { getUserDisplayInfo, getUserDisplayName } from '../../utils/userUtils';

const UserBio = ({ targetUser }) => {
    const user = useSelector(state => state.user);
    const styles = StyleSheet.create(useRelativeStyles({
        container: {
            alignItems: 'flex-start',
            rlw_width: 341,
            rlw_marginVertical: 12,
            rlw_marginHorizontal: 26,
        },
        text: {
            fontFamily: 'BricolageGrotesque_Medium',
            textAlignVertical: 'center',
            color: 'black',
            rlw_fontSize: 30,
            rlw_lineHeight: 45,
        },
        textInfo: {
            fontFamily: 'BricolageGrotesque_Medium',
            textAlignVertical: 'center',
            color: '#666',
            rlw_fontSize: 30,
            rlw_lineHeight: 45,
        },
    }));

    return (
        <View style={styles.container}>
            <Text style={styles.text} selectable={false}>
                {targetUser == null ?
                    user?.firstName + ' ' + user?.lastName :
                    getUserDisplayName(targetUser)
                }
            </Text>
            <Text style={styles.textInfo} selectable={false}>
                {getUserDisplayInfo(targetUser == null ? user : targetUser, true)}
            </Text>
        </View>
    );
};

export default UserBio;