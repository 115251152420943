// utils/screenDimensions.js
import { StyleSheet, useWindowDimensions } from "react-native";

const FIGMA_DEFAULT_SCREEN_WIDTH = 393;

export function useScreenDimensions() {
    const windowDimensions = useWindowDimensions();
    const maxWindowWidth = 600;

    return {
        windowWidth: windowDimensions.width,
        windowHeight: windowDimensions.height,
        isLargeScreen: windowDimensions.width > maxWindowWidth,
        adjustedWidth: Math.min(windowDimensions.width, maxWindowWidth),
    };
}

export const useRelativeStyles = (
    inputStyles, figmaScreenWidth = FIGMA_DEFAULT_SCREEN_WIDTH
) => {
    const { adjustedWidth } = useScreenDimensions();

    const transformStyles = (styles) => {
        const transformedStyles = {};

        for (const key in styles) {
            const value = styles[key];

            if (typeof value === "object" && !Array.isArray(value)) {
                // Recursively transform nested styles
                transformedStyles[key] = transformStyles(value);
            } else if (key.startsWith("rlw_")) {
                const actualKey = key.replace("rlw_", "");
                const addValue = (value / figmaScreenWidth) * adjustedWidth;
                transformedStyles[actualKey] = transformedStyles.hasOwnProperty(actualKey) ?
                    transformedStyles[actualKey] + addValue : addValue;
            } else if (key.startsWith("off_")) {
                const actualKey = key.replace("off_", "");
                transformedStyles[actualKey] = transformedStyles.hasOwnProperty(actualKey) ?
                    transformedStyles[actualKey] + value : value;
            } else {
                transformedStyles[key] = value;
            }
        }

        return transformedStyles;
    };

    return StyleSheet.create(transformStyles(inputStyles));
};
