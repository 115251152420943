// utils/cosmetics.js

export const MAX_COMPLETENESS = 80;

const linearMappingToHex = (value, minValue = 0, maxValue = 80) => {
    value = Math.max(minValue, Math.min(value, maxValue));
    const normalizedValue = (value - minValue) / (maxValue - minValue);
    const mappedValue = Math.round(normalizedValue * 255);

    // Convert to hexadecimal and ensure it's two characters long
    let hexValue = mappedValue.toString(16).toUpperCase();
    if (hexValue.length === 1) {
        hexValue = '0' + hexValue;
    }
    return hexValue;
}

export const getCosmeticColor = (cosmetic, numOfImages = 0) => {
    return cosmetic.color + linearMappingToHex(
        cosmetic.completeness + numOfImages * 5, 0, MAX_COMPLETENESS,
    );
};

export const getCosmeticFontColor = (cosmetic) => {
    const lightColor = [
        "#FF6B6B", "#F4A261", "#F9C80E", "#F4D35E",
        "#BBE1FA", "#8D99AE", "#A799B7", "#F97F51",
        "#1A936F", "#F3F631",
    ];
    if (lightColor.includes(cosmetic?.color.toUpperCase())) {
        return "#111111";
    } else {
        return "#FFFFFF";
    }
};

const hexToRgba = (hex) => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 24) & 255;
    const g = (bigint >> 16) & 255;
    const b = (bigint >> 8) & 255;
    const a = (bigint & 255);
    return [r, g, b, a];
}

const rgbaToHex = (r, g, b, a) => {
    return (
        "#" +
        ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase() +
        (a).toString(16).padStart(2, '0').toUpperCase()
    );
}

export const animateColorTransition = (oldHexColor, newHexColor, setCosmeticColor, frameNumber=60) => {
    const [oldR, oldG, oldB, oldA] = hexToRgba(oldHexColor);
    const [newR, newG, newB, newA] = hexToRgba(newHexColor);

    const rDiff = (newR - oldR) / frameNumber;
    const gDiff = (newG - oldG) / frameNumber;
    const bDiff = (newB - oldB) / frameNumber;
    const aDiff = (newA - oldA) / frameNumber;

    let currentFrame = 0;

    const animate = () => {
        currentFrame++;
        const currentR = Math.round(oldR + rDiff * currentFrame);
        const currentG = Math.round(oldG + gDiff * currentFrame);
        const currentB = Math.round(oldB + bDiff * currentFrame);
        const currentA = Math.round(oldA + aDiff * currentFrame);
        const nextColor = rgbaToHex(currentR, currentG, currentB, currentA);

        setCosmeticColor(nextColor);

        if (currentFrame < frameNumber) {
            requestAnimationFrame(animate);
        }
    };

    animate();
};