// components/chats/ChatListHeader.js
import { View, Text, StyleSheet, Platform } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { useSelector } from "react-redux";
import { SafeAreaView } from 'react-native-safe-area-context';
import { useRelativeStyles } from "../../utils/screenDimensions";
import { UserThumbnailSize } from "../../utils/userUtils";
import UserThumbnail from "../UserThumbnail";
import Countdown from "./Countdown";
import ButtonClickWrapper from '../../firebase/components/buttonClickWrapper';
import { BUTTON_CLICK_CHATS_MY_PROFILE } from '../../firebase/constants';

const ChatListHeader = ({ onLayout }) => {
    const navigation = useNavigation();
    const user = useSelector(state => state.user);
    const styles = StyleSheet.create(useRelativeStyles({
        full: {
            width: "100%",
            borderTopWidth: Platform.OS === "ios" ? 0.333 : 0,
            borderTopColor: '#0000004D',
        },
        container: {
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            backgroundColor: 'white',
            alignSelf: 'center',
            rlw_width: 393,
            rlw_marginBottom: 4,
        },
        header: {
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            rlw_height: 82,
            rlw_paddingHorizontal: 24,
        },
        headerText: {
            fontFamily: 'BricolageGrotesque_Medium',
            rlw_fontSize: 30,
            rlw_lineHeight: 37.5,
            rlw_marginLeft: 2,
            color: '#333',
        },
    }));

    const MyProfileButton = ButtonClickWrapper(
        (props) => (<UserThumbnail {...props}/>), BUTTON_CLICK_CHATS_MY_PROFILE
    )

    return (
        <SafeAreaView
            edges={['right', 'left', 'top',]}
            style={{ backgroundColor: 'white' }}
            onLayout={onLayout}
        >
            <View style={styles.full}>
                <View style={styles.container}>
                    <View style={styles.header}>
                        <Text style={styles.headerText} selectable={false}>
                            Volar
                        </Text>
                        <MyProfileButton
                            user={user}
                            thumbnailSize={UserThumbnailSize.LARGE}
                            hasBorder={true}
                            isDark={false}
                            onPress={() => {
                                navigation.navigate('UploadImage');
                            }}
                        />
                    </View>
                    <Countdown user={user} />
                </View>
            </View>
        </SafeAreaView>
    );
};

export default ChatListHeader;