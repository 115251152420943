import { getToken } from './common';
import { WS_NOTIFICATION_URL } from '../urlconfig';

export class NotificationService {
    // WebSocket that manages the in-app notification for the user
    // This is a passive WebSocket, as it only receives updates but never sends
    async openConnection() {
        this.shouldReconnect = true;
        const token = await getToken();
        this.websocket = new WebSocket(`${WS_NOTIFICATION_URL}?token=${token}`);

        this.websocket.onopen = () => {
            console.log('Chat WebSocket connection opened');
        };

        this.websocket.onmessage = this.onChatUpdateCallback;

        this.websocket.onerror = (error) => {
            console.error('Chat WebSocket encountered error:', error);
        };

        this.websocket.onclose = (event) => {
            console.log('Chat WebSocket connection closed:', event);

            // retry 10 times if the disconnection is caused by network issue
            if (!event.wasClean && this.shouldReconnect) {
                let timeout = 5 * 1000;
                console.log(`Attempting to reconnect in ${timeout} ms...`);
                setTimeout(() => this.openConnection(), timeout);
            }
        };
    }

    setOnChatUpdateCallback(callback) {
        this.onChatUpdateCallback = callback;
    }

    closeConnection() {
        this.shouldReconnect = false;
        this.websocket.close();
    }
};