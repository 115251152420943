// firebase.js
import { initializeApp } from "firebase/app";
import {
    getAnalytics,
    setUserId as fbaseSetUID,
    logEvent
} from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyCMHE4IaRtyxVEkBLtTtnHDMlLXt4bQ5Rc",
    authDomain: "volar-prod.firebaseapp.com",
    projectId: "volar-prod",
    storageBucket: "volar-prod.appspot.com",
    messagingSenderId: "424055058537",
    appId: "1:424055058537:web:f5ef451e7473cc6d8872f8",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics
const analytics = getAnalytics(app);

export const emitEvent = (eventName, eventParams) => {
    logEvent(analytics, eventName, eventParams);
}

export const setUserId = (userId) => {
    fbaseSetUID(analytics, userId);
}