export const CreateBoostPurchaseOption = (data) => {
    if (data == null ) {
        return null;
    } else {
        return {
            purchaseType: data.purchase_type,
            boostAmount: data.boost_amount,
            transactionAmount: data.transaction_amount,
            targetId: data.target_id,
        }
    }
}