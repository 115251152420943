// screens/TermsScreen.js
import { View, StyleSheet } from 'react-native';
import Markdown, { MarkdownFile } from '../components/legal/Markdown';
import { useRelativeStyles } from '../utils/screenDimensions';
import PageViewWrapper from '../firebase/components/pageViewWrapper';

const TermsScreen = () => {
    const styles = StyleSheet.create(useRelativeStyles({
        container: {
            rlw_maxWidth: 393,
            rlw_paddingHorizontal: 10,
            fontFamily: 'BricolageGrotesque_Regular',
            backgroundColor: 'white',
            alignSelf: 'center',
        },
    }));
    
    return (
        <View style={styles.container}>
            <Markdown markdown={MarkdownFile.TERMS} />
        </View>
    );
};

export default PageViewWrapper(TermsScreen);