// components/legal/Markdown.web.js

import { useState, useEffect } from 'react';
import { Asset } from 'expo-asset';
import ReactMarkdown from 'react-markdown';

export const MarkdownFile = {
    TERMS: 'terms',
    PRIVACY: 'privacy',
};

const WebMarkdown = ({ markdown }) => {
    const [termsContent, setTermsContent] = useState('');

    const fetchContent = async () => {
        let file;
        switch (markdown) {
            case MarkdownFile.PRIVACY:
                file = Asset.fromModule(require("../../assets/legal/privacy.md"));
                break;
            case MarkdownFile.TERMS:
            default:
                file = Asset.fromModule(require("../../assets/legal/terms.md"));
        }
        await file.downloadAsync() // Optional, saves file into cache
        const content = await fetch(file.uri)
        const text = await content.text()
        setTermsContent(text);
    }

    useEffect(() => {
        fetchContent();
    }, []);

    return (
        <ReactMarkdown
            children={termsContent}
        />
    );
};

export default WebMarkdown;