// components/chats/ChatListFooter.js
import { View, Text, TouchableOpacity, StyleSheet, Platform, Image } from 'react-native';
import { useRelativeStyles } from '../../utils/screenDimensions';
import Logo, { LogoSize } from '../Logo';
import { getUserCosmeticColor } from '../../utils/userUtils';
import ButtonClickWrapper from '../../firebase/components/buttonClickWrapper';
import { BUTTON_CLICK_CHATS_ASSISTANT } from '../../firebase/constants';

const ChatListFooter = ButtonClickWrapper(({ user, onPress }) => {
    const upImagePath = require('../../assets/arrows/chevron.up.sf_light.png');
    const tips = [
        'Provide feedback to Volar',
        'Volar never reads user chats',
        'Update your profile anytime',
        'Share your Volar profile',
    ];

    const getRandomTip = (tips) => {
        const idx = Math.floor(Math.random() * tips.length);
        return tips[idx];
    };

    const styles = StyleSheet.create(useRelativeStyles({
        button: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            rlw_height: Platform.OS === 'web' ? 57 : 72,
            rlw_borderTopLeftRadius: 12,
            rlw_borderTopRightRadius: 12,
            zIndex: 10,
        },
        container: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            rlw_height: 57,
            rlw_width: 393,
            rlw_paddingHorizontal: 24,
            backgroundColor: 'transparent',
        },
        text: {
            flex: 1,
            fontFamily: 'IBMPlexSans_400Regular',
            color: 'white',
            rlw_marginHorizontal: 18,
            rlw_fontSize: 16,
            rlw_lineHeight: 20,
        },
        textMain: {
            color: '#999',
        },
        image: {
            rlw_width: 20,
            rlw_height: 20,
            tintColor: '#999',
            resizeMode: 'contain',
        },
    }));

    return (
        <TouchableOpacity
            activeOpacity={1}
            style={styles.button}
            onPress={onPress}
        >
            <View style={styles.container}>
                <Logo
                    userColor={getUserCosmeticColor(user)}
                    otherUserColor={'white'}
                    logoSize={LogoSize.SMALL}
                />
                <Text style={styles.text}>
                    Tips:{' '}
                    <Text style={styles.textMain}>
                        {getRandomTip(tips)}
                    </Text>
                </Text>
                <Image style={styles.image} source={upImagePath} />
            </View>
        </TouchableOpacity>
    );
}, BUTTON_CLICK_CHATS_ASSISTANT);

export default ChatListFooter;