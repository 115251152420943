// components/UserThumbnail.js

import { TouchableOpacity, Image, StyleSheet } from 'react-native';
import { useRelativeStyles } from '../utils/screenDimensions';
import {
    getUserCosmeticColor, getUserThumbnail,
    getUserThumbnailButtonRLW, getUserThumbnailButtonOFF,
} from '../utils/userUtils';

const UserThumbnail = ({ user, thumbnailSize, hasBorder, onPress, isDark }) => {
    const styles = StyleSheet.create(useRelativeStyles({
        container: {
            overflow: 'hidden',
            alignItems: 'center',
            justifyContent: 'center',    
            rlw_width: getUserThumbnailButtonRLW(thumbnailSize),
            off_width: getUserThumbnailButtonOFF(thumbnailSize),
            rlw_height: getUserThumbnailButtonRLW(thumbnailSize),
            off_height: getUserThumbnailButtonOFF(thumbnailSize),
            rlw_borderRadius: getUserThumbnailButtonRLW(thumbnailSize) / 2,
            off_borderRadius: getUserThumbnailButtonOFF(thumbnailSize) / 2,
            borderWidth: getUserThumbnailButtonOFF(thumbnailSize) / 2,
            borderColor: hasBorder ? getUserCosmeticColor(user) : 'transparent',
        },
        image: {
            rlw_width: getUserThumbnailButtonRLW(thumbnailSize),
            rlw_height: getUserThumbnailButtonRLW(thumbnailSize),
            rlw_borderRadius: getUserThumbnailButtonRLW(thumbnailSize) / 2,
            tintColor: user?.thumbnail == null ?  (isDark ? 'white' : 'black') : undefined,
        },
    }));

    return (
        <TouchableOpacity
            testID="user-thumbnail-button"
            activeOpacity={0.7}
            onPress={onPress}
            style={styles.container}>
            <Image
                source={getUserThumbnail(user)}
                testID="user-thumbnail-image"
                style={styles.image}
            />
        </TouchableOpacity>
    );
};

export default UserThumbnail;