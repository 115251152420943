// components/FlatListFooter.js
import { StyleSheet, View, ActivityIndicator } from 'react-native';
import { useRelativeStyles } from '../utils/screenDimensions';

const FlatListFooter = ({ isDark, isLoadingMore }) => {
    const styles = StyleSheet.create(useRelativeStyles({
        indicator: {
            rlw_marginVertical: 6,
        },
        padding: {
            rlw_height: isDark ? 10 : 20,
        },
    }));

    return (
        <View>
            {isLoadingMore ? <ActivityIndicator
                size={"small"}
                color={isDark ? 'white' : 'black'}
                style={styles.indicator}
            /> : <View style={styles.padding} />}
        </View>
    );
};

export default FlatListFooter;