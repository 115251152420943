import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppState, AppState } from "./useAppState";
import { UserStatus } from "../../models/User";

export const UserState = Object.freeze({
    online: "online",
    offline: "offline",
});

// this hook is to monitor is the user state is online or offline
// a user is online only if it's logged in and the app is in foreground
export const useUserState = () => {
    // check if user has logged in
    const userStatus = useSelector((state) => state.user?.status);
    // check if app is in foreground
    const appState = useAppState();

    const [userState, setUserState] = useState(UserState.offline);

    useEffect(() => {
        if (
            appState === AppState.foreground &&
            (userStatus === UserStatus.PROFILE_COMPLETE ||
                userStatus === UserStatus.ONBOARDED)
        ) {
            setUserState(UserState.online);
        } else {
            setUserState(UserState.offline);
        }

        return () => {
            setUserState(UserState.offline);
        };
    }, [userStatus, appState]);

    return userState;
};
