// components/upload/UploadImageFooter.js
import { View, TouchableOpacity, Text, StyleSheet, Platform } from 'react-native';
import { useSelector } from 'react-redux';
import { useRelativeStyles } from '../../utils/screenDimensions';
import Logo, { LogoSize } from '../../components/Logo';
import { UserStatus } from '../../models/User';

const UploadImageFooter = ({ cosmeticColor, logoColor, numOfImages, onPressLogo, hasEdited }) => {
    const styles = StyleSheet.create(useRelativeStyles({
        footer: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.92)',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            rlw_height: Platform.OS === 'web' ? 69 : 85,
            zIndex: 10,
        },
        splitter: {
            width: '100%',
            rlw_height: 5,
            backgroundColor: cosmeticColor,
        },
        container: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            rlw_height: 64,
            rlw_width: 393,
            rlw_paddingHorizontal: 5,
        },
        button: {
            justifyContent: 'center',
            alignItems: 'center',
            rlw_width: 56,
        },
        text: {
            fontFamily: 'IBMPlexSans_400Regular',
            rlw_fontSize: 16,
            rlw_lineHeight: 20,
            color: 'black',
        },
        textTips: {
            fontFamily: 'IBMPlexSans_400Regular',
            rlw_fontSize: 16,
            rlw_lineHeight: 20,
            color: 'gray',
        },
    }));
    const user = useSelector(state => state.user);

    const isIncomplete = () => {
        return user?.status === UserStatus.PROFILE_COMPLETE && numOfImages < 6;
    };

    const getTipText = (numOfImages) => {
        if (isIncomplete()) {
            return "You must have 6 photos to get matches"
        } else {
            switch (numOfImages) {
                case 0:
                    return 'Tip: Add 6 photos to get a match';
                case 1:
                case 2:
                    return 'Tip: Long press to delete any photo';
                case 3:
                case 4:
                    return 'Tip: Show different sides of who you are';
                case 5:
                case 6:
                default:
                    return 'Tip: Volar learns from your feedback';
            }
        }
    };

    const getCompletenessText = (numOfImages) => {
        const completeness = Math.round(50 + numOfImages * 50 / 6);
        return `${completeness}% complete`;
    };

    return (
        <View style={styles.footer}>
            <View style={styles.splitter} />
            <View style={styles.container}>
                <TouchableOpacity
                    activeOpacity={0.7}
                    style={styles.button}
                    onPress={onPressLogo}
                >
                    <Logo
                        userColor={logoColor}
                        otherUserColor={'black'}
                        logoSize={LogoSize.SMALL}
                    />
                </TouchableOpacity>
                <View>
                    <Text selectable={false} style={[styles.text, {
                        color: isIncomplete() ? 'red' : 'black',
                    }]}>{getCompletenessText(numOfImages)}</Text>
                    <Text style={styles.textTips} selectable={false}>
                        {getTipText(numOfImages)}
                    </Text>
                </View>
            </View>
        </View>
    )
};

export default UploadImageFooter;