// components/chats/ChatTabView.js
import { useState } from "react";
import { View, ScrollView, Text, StyleSheet, Platform } from "react-native";
import { TabView, SceneMap, TabBar } from "react-native-tab-view";
import { MessageType } from "../../models/Message";
import ChatBlock from "../../components/chats/ChatBlock";
import EmptyState from "./EmptyState";
import { useRelativeStyles } from "../../utils/screenDimensions";

import React from "react";
import { getChatIsUnread } from "../../utils/chatUtils";

const RedDot = () => {
    const styles = StyleSheet.create(
        useRelativeStyles({
            dot: {
                rlw_width: 12,
                rlw_height: 12,
                backgroundColor: "red",
                rlw_borderRadius: 6,
                position: "relative",
                bottom: Platform.OS == "ios"? 2:-1,
            }
        })
    );

    return <View style={styles.dot} />;
};

const ChatTabView = ({ chats, maxHeight }) => {
    const styles = StyleSheet.create(
        useRelativeStyles({
            container: {
                alignSelf: "center",
                backgroundColor: "white",
                borderBottomWidth: 0,
                shadowOpacity: 0,
                rlw_height: 40,
                rlw_width: 378,
            },
            tab: {
                rlw_width: 120,
            },
            scroll: {
                rlw_paddingBottom: Platform.OS === "web" ? 57 : 72,
            },
        })
    );

    const isChatMatch = (chat) => {
        return (
            chat.isActive &&
            [MessageType.SIMULATED, MessageType.REQUEST].includes(
                chat.lastMessage?.type
            )
        );
    };

    const isChatChat = (chat) => {
        return (
            chat.isActive &&
            [MessageType.CONFIRM, MessageType.TEXT].includes(
                chat.lastMessage?.type
            )
        );
    };

    const boostChats = (chats) => {
        // this function move all the chats with unread boosted message to the front
        // of the array while maintaining the order of the chats within their groups
        let boostingChat = chats.filter((chat) => chat.hasUnreadBoostedMessage);
        let normalChat = chats.filter((chat) => !chat.hasUnreadBoostedMessage);
        return boostingChat.concat(normalChat);
    };

    let matches = boostChats(chats.filter(isChatMatch))
    let conversations = boostChats(chats.filter(isChatChat))
    let unreadChatMap = {
        matches: matches.some(getChatIsUnread),
        conversations: conversations.some(getChatIsUnread),
    }

    const [index, setIndex] = useState(0);
    const [routes] = useState([
        { key: "matches", title: "Matches" },
        { key: "conversations", title: "Conversations" },
    ]);

    const ChatTabLabel = ({ route, focused }) => {
        const getWidth = (key) => {
            switch (key) {
                case "matches":
                    return 75;
                case "conversations":
                default:
                    return 130;
            }
        };

        const styles = StyleSheet.create(
            useRelativeStyles({
                container: {
                    rlw_width: getWidth(route.key),
                    marginRight: 10,
                },
                text: {
                    fontFamily: focused
                        ? "IBMPlexSans_700Bold"
                        : "IBMPlexSans_400Regular",
                    rlw_fontSize: 18,
                    rlw_lineHeight: 22.5,
                }
            })
        );

        return (
            <View style={styles.container}>
                <View style={{flexDirection:"row", paddingLeft: 5}}>
                    <Text style={styles.text}>{route.title}</Text>
                    {
                        unreadChatMap[route.key] && <RedDot/>
                    }
                </View>
            </View>
        );
    };

    const renderTabBar = (props) => (
        <TabBar
            {...props}
            indicatorStyle={{ height: 0 }}
            indicatorContainerStyle={{ height: 0 }}
            style={styles.container}
            tabStyle={styles.tab}
            renderLabel={ChatTabLabel}
        />
    );

    const MatchesScene = () => (
        <ScrollView style={{ flex: 1 }} contentContainerStyle={styles.scroll}>
            {matches.length === 0 ? (
                <EmptyState />
            ) : (
                matches.map((chat) => (
                    <ChatBlock
                        key={chat.id}
                        chat={chat}
                        analyticsProps={{ chat_type: "match" }}
                    />
                ))
            )}
        </ScrollView>
    );

    const ConversationsScene = () => (
        <ScrollView style={{ flex: 1 }} contentContainerStyle={styles.scroll}>
            {conversations.length === 0 ? (
                <EmptyState />
            ) : (
                conversations.map((chat) => (
                    <ChatBlock
                        key={chat.id}
                        chat={chat}
                        analyticsProps={{ chat_type: "conversation" }}
                    />
                ))
            )}
        </ScrollView>
    );

    const renderScene = SceneMap({
        matches: MatchesScene,
        conversations: ConversationsScene,
    });

    return (
        <TabView
            navigationState={{ index, routes }}
            renderScene={renderScene}
            onIndexChange={setIndex}
            renderTabBar={renderTabBar}
            style={{ maxHeight: maxHeight }}
        />
    );
};

export default ChatTabView;
