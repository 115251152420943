// components/assistant/HintNonAction.js
import {
    View,
    Text,
    StyleSheet,
} from "react-native";
import { useRelativeStyles } from "../../utils/screenDimensions";
import {
    getUserThumbnailButtonRLW,
} from '../../utils/userUtils';

const HintNonAction = ({ parentWidth, thumbnailSize }) => {
    const styles = StyleSheet.create(useRelativeStyles({
        messageBox: {
            flexDirection: 'row',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            width: parentWidth,
        },
        text: {
            fontFamily: 'IBMPlexSans_400Regular',
            color: '#9A9A9A',
            textAlignVertical: 'center',
            textAlign: 'center',
            rlw_fontSize: 12,
            rlw_lineHeight: 15,
            rlw_marginTop: 5,
            rlw_marginRight: 11 + getUserThumbnailButtonRLW(thumbnailSize),
        },
    }));

    return (
        <View style={styles.messageBox}>
            <Text style={styles.text}>
                You can type or tap on any answer above
            </Text>
        </View>
    );
};

export default HintNonAction;