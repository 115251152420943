// screens/UploadImageScreen.js
import { useEffect, useState, useRef, useCallback } from 'react';
import { useFocusEffect, useRoute } from '@react-navigation/native';
import { View, Linking, Platform } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import uuid from 'react-native-uuid';
import ReactGA from "react-ga4";
import { useScreenDimensions } from '../utils/screenDimensions';
import { showNetworkError } from '../utils/errorHandlers';
import { resetNavigation, resetNavigationFromUserStatus } from '../utils/navigation';
import {
    getCosmeticColor, getCosmeticFontColor,
    animateColorTransition
} from '../utils/cosmetics';
import { fetchUser, blockUser } from '../services/authService';
import {
    initStagingProfileImages,
    fetchStagingProfileImages,
    fetchProfileImages,
    deleteProfileImage,
    confirmProfileImage,
    uploadImage,
} from '../services/imageService';
import { createShareURL } from '../services/shareService';
import { shareLink } from '../utils/share';
import UploadImageFooter from '../components/upload/UploadImageFooter';
import UploadImageHeader from '../components/upload/UploadImageHeader';
import UploadScrollView from '../components/upload/UploadScrollView';
import ChatListFooter from '../components/chats/ChatListFooter';
import PopupMenu from '../components/upload/PopupMenu';
import { selectImageFromCameraRoll, resizeImage } from '../utils/imageUtils';
import { getUserCosmeticColor } from '../utils/userUtils';
import { fetchUserDetails } from '../actions/userActions';
import { UserStatus } from '../models/User';
import PageViewWrapper from '../firebase/components/pageViewWrapper';
import { logImageUpload } from '../firebase/utils';
import {
    IMAGE_UPLOAD_START,
    IMAGE_UPLOAD_SELECTED,
    IMAGE_UPLOAD_RESIZING,
    IMAGE_UPLOAD_UPLOADING,
    IMAGE_UPLOAD_DONE
} from '../firebase/constants';

const UploadImageScreen = ({ navigation }) => {
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const { windowHeight } = useScreenDimensions();
    const [profileImages, setProfileImages] = useState([]);
    const [headerHeight, setHeaderHeight] = useState(0);
    const [cosmeticColor, setCosmeticColor] = useState('#FFFFFF00');
    const [cosmeticFontColor, setCosmeticFontColor] = useState('#111111');
    const [isEditing, setIsEditing] = useState(false);
    const [hasEdited, setHasEdited] = useState(false);
    const [isFooterVisible, setIsFooterVisible] = useState(true);
    const [targetUser, setTargetUser] = useState(null);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const cosmeticColorRef = useRef(cosmeticColor);
    const route = useRoute();

    const initProfileImages = async (updateCosmetic = false) => {
        try {
            let images = [];
            if (route?.params?.userId == null) {
                // Visiting /upload without parameters
                if (user?.status === UserStatus.PROFILE_COMPLETE) {
                    // Editing profile -> use staging
                    if (updateCosmetic == false) {
                        // Initialization
                        images = await initStagingProfileImages();
                        setProfileImages(images);
                    } else {
                        images = await fetchStagingProfileImages();
                        setProfileImages(images);
                    }
                } else {
                    // Onboarding
                    images = await fetchProfileImages(user?.pk);
                    setProfileImages(images);
                }
            } else {
                // Visiting someone else's profile
                setTargetUser(await fetchUser(route?.params?.userId));
                images = await fetchProfileImages(route?.params?.userId);
                setProfileImages(images);
            }
            if (updateCosmetic) {
                setHasEdited(true);
                const oldHexColor = cosmeticColorRef.current;
                const newHexColor = getCosmeticColor(user?.cosmeticMetadata, images.length);
                animateColorTransition(
                    oldHexColor, newHexColor, setCosmeticColor
                );
            } else {
                setCosmeticColor(getCosmeticColor(user?.cosmeticMetadata, images.length));
            }
        } catch (error) {
            if (error.response?.status === 401) {
                resetNavigation(navigation);
            } else if (error.message?.includes('Network Error') || error.message?.includes('timeout')) {
                showNetworkError();
            } else {
                console.error('Error fetching profile images:', error);
            }
        }
    }

    useEffect(() => {
        if (user === null) {
            resetNavigation(navigation);
        } else {
            initProfileImages();
            setCosmeticFontColor(getCosmeticFontColor(user.cosmeticMetadata));
        }
    }, [user?.pk]);

    useEffect(() => {
        cosmeticColorRef.current = cosmeticColor;
    }, [cosmeticColor]);

    useFocusEffect(
        useCallback(() => {
            setIsFooterVisible(true);
        }, [])
    );

    const handlePressUpload = async () => {
        if (targetUser == null) {
            setIsEditing(false);
            let img_psduo_id = uuid.v4();
            logImageUpload(img_psduo_id, IMAGE_UPLOAD_START)
            const selectResult = await selectImageFromCameraRoll();
            logImageUpload(img_psduo_id, IMAGE_UPLOAD_SELECTED)
            if (selectResult && !selectResult.canceled) {
                logImageUpload(img_psduo_id, IMAGE_UPLOAD_RESIZING)
                const resizeResult = await resizeImage(selectResult.assets[0]);
                try {
                    logImageUpload(img_psduo_id, IMAGE_UPLOAD_UPLOADING)
                    await uploadImage(
                        resizeResult.uri,
                        'profile',
                        user?.status === UserStatus.PROFILE_COMPLETE
                    );
                    logImageUpload(img_psduo_id, IMAGE_UPLOAD_DONE)
                    await initProfileImages(true);
                } catch (error) {
                    if (error.response?.status === 401) {
                        resetNavigation(navigation);
                    } else if (error.message?.includes('Network Error') || error.message?.includes('timeout')) {
                        showNetworkError();
                    } else {
                        console.error('Error uploading profile images:', error);
                    }
                }
            }
        }
    };

    const handleLongPress = () => {
        if (targetUser == null) {
            setIsEditing(true);
        }
    };

    const handlePressDelete = async (index) => {
        if (targetUser == null) {
            try {
                await deleteProfileImage(profileImages[index].profileImageId);
                await initProfileImages(true);
            } catch (error) {
                if (error.response?.status === 401) {
                    resetNavigation(navigation);
                } else if (error.message?.includes('Network Error') || error.message?.includes('timeout')) {
                    showNetworkError();
                } else {
                    console.error('Error deleting profile images:', error);
                }
            }
        }
    };

    const handleImagePress = () => {
        if (targetUser == null) {
            setIsEditing(false);
        }
    };

    const handleFooterPress = () => {
        setIsFooterVisible(false);
        navigation.navigate('Assistant');
    };

    const handleConfirm = async () => {
        if (user?.status !== UserStatus.PROFILE_COMPLETE && Platform.OS === 'web') {
            ReactGA.event({
                category: "User",
                action: "ConfirmClick",
            });
            if (typeof fbq !== 'undefined') {
                fbq('trackCustom', 'ConfirmClick', {});
            }
        }
        try {
            await confirmProfileImage(user?.status === UserStatus.PROFILE_COMPLETE);
            await dispatch(fetchUserDetails());
            resetNavigation(navigation, 'Chats');
        } catch (error) {
            if (error.response?.status === 401) {
                resetNavigation(navigation);
            } else if (error.message?.includes('Network Error') || error.message?.includes('timeout')) {
                showNetworkError();
            } else {
                console.error('Error finishing editing profile images:', error);
            }
        }
    };

    const handleUser = async () => {
        setIsPopupVisible(!isPopupVisible);
    };

    const handleShare = async () => {
        const link = await createShareURL();
        await shareLink(link);
    };

    const handleBlock = async () => {
        setIsPopupVisible(false);
        const result = await blockUser(targetUser.userid);
        if (result) {
            resetNavigationFromUserStatus(navigation, user?.status);
        }
    };

    const handleReport = () => {
        setIsPopupVisible(false);
        const email = 'support@volardating.com';
        const subject = encodeURIComponent(`Support Request - Report: ${targetUser.userid}`);
        const body = encodeURIComponent('Describe your issue or question here.\n\n'); // Pre-filled body text
        const mailtoURL = `mailto:${email}?subject=${subject}&body=${body}`;
        Linking.openURL(mailtoURL);
    };

    return (
        <View style={{ flex: 1, backgroundColor: 'white' }}>
            <View style={{ flex: 1, justifyContent: 'flex-end' }}>
                <UploadImageHeader
                    cosmeticColor={targetUser == null ?
                        cosmeticColor :
                        getCosmeticColor(targetUser.cosmeticMetadata, 6)}
                    cosmeticFontColor={targetUser == null ?
                        cosmeticFontColor :
                        getCosmeticFontColor(targetUser.cosmeticMetadata)}
                    onLayout={(event) => {
                        const { height } = event.nativeEvent.layout;
                        setHeaderHeight(height);
                    }}
                    numOfImages={profileImages.length}
                    navigation={navigation}
                    onConfirm={handleConfirm}
                    onShare={handleShare}
                    onUser={handleUser}
                    hasEdited={hasEdited}
                    targetUser={targetUser}
                />
                <PopupMenu
                    isVisible={isPopupVisible}
                    onBlock={handleBlock}
                    onReport={handleReport}
                />
                <UploadScrollView
                    maxHeight={windowHeight - headerHeight}
                    profileImages={profileImages}
                    isEditing={isEditing}
                    handlePressDelete={handlePressDelete}
                    handlePressUpload={handlePressUpload}
                    handleLongPress={handleLongPress}
                    handleImagePress={handleImagePress}
                    targetUser={targetUser}
                />
                {isFooterVisible &&
                    (targetUser == null ? <UploadImageFooter
                        cosmeticColor={cosmeticColor}
                        logoColor={getUserCosmeticColor(user)}
                        numOfImages={profileImages.length}
                        onPressLogo={handleFooterPress}
                        hasEdited={hasEdited}
                    /> :
                        <ChatListFooter user={user} onPress={handleFooterPress} />
                    )}
            </View>
        </View>
    );
};

export default PageViewWrapper(UploadImageScreen);