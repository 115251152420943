import React from "react";

import { TouchableOpacity, Text, StyleSheet } from "react-native";
import { useRelativeStyles } from "../../../utils/screenDimensions";

const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount / 100);
}

const getButtonText = (option) => {
    let {
        purchaseType, boostAmount, transactionAmount
    } = option;

    switch (purchaseType) {
        case "refer":
            return {
                isSolid: true,
                purchaseTarget: "First Boost Free",
                priceTag: "Share your Volar profile\n for others to simulate chats with!"
            }
        case "subscription":
            return {
                isSolid: false,
                purchaseTarget: "Volar Boost Subscription",
                priceTag: "1 Boost per week\n$10/mo • $2.50 each"
            }
        case "on_demand":
            let isSolid = false
            let purchaseTarget = `${boostAmount} Boost`
            let priceTag = formatCurrency(transactionAmount)
            if (boostAmount > 1) {
                purchaseTarget += "s",
                priceTag += ` • ${formatCurrency(transactionAmount / boostAmount)}`
            }
            priceTag += " each"

            return { isSolid, purchaseTarget, priceTag }
        default:
            console.log("unrecognized purchase type:", purchaseType)
            return null;
    }
}

const PurchaseButton = ({option, onPress}) => {
    let {isSolid, purchaseTarget, priceTag} = getButtonText(option)

    const styles = StyleSheet.create(
        useRelativeStyles({
            button: {
                rlw_width: 280,
                borderWidth: 3,
                rlw_borderRadius: 50,
                borderColor: "#F3F631",
                backgroundColor: isSolid ? "rgba(243, 246, 49, 0.50)" : "white",
                textAlign: "center",

                rlw_paddingVertical: 8,
                rlw_paddingHorizontal: 16,

                rlw_marginBottom: 18,

                justifyContent: "center",
            },
            textBoost: {
                color: "#414141",
                rlw_fontSize: 16,
                fontFamily: "IBMPlexSans_700Bold",
                rlw_lineHeight: 20,
                textAlign: "center",
            },
            textPriceTag: {
                color: "#414141",
                rlw_fontSize: 16,
                fontFamily: "IBMPlexSans_400Regular",
                textAlign: "center",
            },
        })
    );

    return (
        <TouchableOpacity style={styles.button} onPress={onPress}>
            <Text style={styles.textBoost}> {purchaseTarget} </Text>
            <Text style={styles.textPriceTag}> {priceTag} </Text>
        </TouchableOpacity>
    );
};

export default PurchaseButton;
