// services/messageService.js

import axios from 'axios';
import { CHAT_HISTORY_URL, WS_CHAT_URL } from '../urlconfig';
import { getToken } from './common';
import { createMessage } from '../models/Message';

export const MAX_NUM_OF_MESSAGES_RENDERED = 60;
export const MIN_NUM_OF_MESSAGES_RENDERED = 20;
export const SAFETY_INDEX_MARGIN = 5;

export const fetchMessages = async (chatId, page, pageSize) => {
    const url = `${CHAT_HISTORY_URL}${chatId}/history/?page=${page}&page_size=${pageSize}`;
    try {
        const token = await getToken();
        const response = await axios.get(url, {
            headers: {
                Authorization: `Token ${token}`,
            },
        });
        if (response.status !== 200) {
            throw new Error('Error fetching messages.');
        }
        const transformedMessages = response.data.results.map((msg) => (createMessage(msg)));
        return {
            messages: transformedMessages,
            hasMorePages: response.data.next !== null,
            nextPage: page + 1,
        };
    } catch (error) {
        throw error;
    }
};

export const fetchAllMessages = async (chatId, page, pageSize, prevMessages = []) => {
    try {
        const messages = await fetchMessages(chatId, page, pageSize);
        const allMessages = [...prevMessages, ...messages.messages];
        if (messages.hasMorePages) {
            return fetchAllMessages(chatId, page + 1, pageSize, allMessages);
        } else {
            return allMessages;
        }
    } catch (error) {
        throw error;
    }
};

const findMessageIndex = (messages, messageToFind) => {
    if (messageToFind == null) {
        return messages.length;
    }
    for (let i = 0; i < messages.length; i++) {
        if (messages[i].id === messageToFind.id) {
            return i;
        }
        if (messages[i].created <= messageToFind.created) {
            return i;
        }
    }
    return messages.length;
}

export const fetchMessagesUpTo = async (
    chatId, page, pageSize, lastReadMessage, prevMessages = []
) => {
    try {
        const messages = await fetchMessages(chatId, page, pageSize);
        const allMessages = [...prevMessages, ...messages.messages];
        if (messages.hasMorePages &&
            allMessages.length < MAX_NUM_OF_MESSAGES_RENDERED &&
            (lastReadMessage == null ||
                messages.messages.slice(-SAFETY_INDEX_MARGIN)[0].created > lastReadMessage.created)) {
            return fetchMessagesUpTo(
                chatId, page + 1, pageSize, lastReadMessage, allMessages);
        } else {
            return {
                messages: allMessages,
                hasMorePages: messages.hasMorePages,
                nextPage: page + 1,
                lastReadIndex: Math.min(
                    findMessageIndex(allMessages, lastReadMessage),
                    MAX_NUM_OF_MESSAGES_RENDERED - SAFETY_INDEX_MARGIN
                ),
            };
        }
    } catch (error) {
        throw error;
    }
};

export class MessageWebSocketService {
    // WebSocket that manages the messages in the chat
    // To use:
    //   1. call init() first to create an instance
    //   2. call setOnMessageCallback() to register callback function to receive messages
    //   3. call openConnection() to establish connections with backend
    // After connection is established, sendMessage() is used to send messages
    // the registered callback function is used to receive messages
    //   .. n. remember to call closeConnection() to release resource in the end
    constructor(chatId, token) {
        this.chatId = chatId;
        this.token = token;
        this.wsUrl = `${WS_CHAT_URL}${this.chatId}/?token=${this.token}`;
        this.reconnectAttempts = 0;
        this.maxReconnectAttempts = 10;
    }

    static async init(chatId) {
        const token = await getToken();
        return new MessageWebSocketService(chatId, token);
    }

    openConnection() {
        this.websocket = new WebSocket(this.wsUrl);

        this.websocket.onopen = () => {
            console.log('Message WebSocket connection opened');
            this.reconnectAttempts = 0;
        };

        this.websocket.onmessage = this.onMessageCallback;

        this.websocket.onerror = (error) => {
            console.error('Message WebSocket encountered error:', error);
        };

        this.websocket.onclose = (event) => {
            console.log('Message WebSocket connection closed:', event);

            // retry 10 times if the disconnection is caused by network issue
            if (!event.wasClean && this.reconnectAttempts < this.maxReconnectAttempts) {
                this.reconnectAttempts++;
                let timeout = this.reconnectAttempts * 1000;
                console.log(`Attempting to reconnect in ${timeout} ms...`);
                setTimeout(() => this.openConnection(), timeout);
            }
        };
    }

    setOnMessageCallback(callback) {
        this.onMessageCallback = callback;
    }

    sendMessage(messagePayload) {
        if (this.websocket.readyState === WebSocket.OPEN) {
            this.websocket.send(JSON.stringify(messagePayload));
        } else {
            console.error('Cannot send message, WebSocket is not open');
        }
    }

    closeConnection() {
        this.reconnectAttempts = this.maxReconnectAttempts;
        this.websocket.close();
    }
};