// components/home/LargeCaption.js
import { Text, StyleSheet, View, Image, Platform } from 'react-native';
import { useRelativeStyles } from '../../utils/screenDimensions';

const LargeCaption = () => {
    const styles = StyleSheet.create(useRelativeStyles({
        container: {
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            rlw_width: 341,
            rlw_marginHorizontal: 26,
            rlw_marginVertical: 28,
        },
        image: {
            rlw_width: 44,
            rlw_height: 44,
        },
        text0: {
            textAlign: 'left',
            fontFamily: 'BricolageGrotesque_Medium',
            color: 'white',
            rlw_marginTop: 26,
            rlw_fontSize: 70,
            rlw_lineHeight: 70,
            ...Platform.select({
                // This is a depressing bug in React Native, where iOS has
                // incorrect font behavior (ignore vertical top align) when
                // lineHeight is not large enough to support the full text.
                // https://github.com/facebook/react-native/issues/29507
                ios: {
                    rlw_paddingTop: 70 * 0.09,
                    rlw_height: 70,
                },
            }),
        },
        text1: {
            textAlign: 'left',
            fontFamily: 'BricolageGrotesque_Medium',
            color: 'white',
            rlw_fontSize: 32,
            rlw_lineHeight: 32,
            ...Platform.select({
                ios: {
                    rlw_paddingTop: 32 * 0.09,
                    rlw_height: 32,
                },
            }),
        },
        text2: {
            textAlign: 'left',
            fontFamily: 'BricolageGrotesque_Medium',
            color: 'white',
            rlw_fontSize: 32,
            rlw_lineHeight: 32,
            ...Platform.select({
                ios: {
                    rlw_paddingTop: 32 * 0.09,
                    rlw_height: 32,
                },
            }),
        },
        text3: {
            textAlign: 'left',
            fontFamily: 'IBMPlexSans_400Regular',
            color: 'white',
            rlw_fontSize: 14,
            rlw_lineHeight: 17.5,
            rlw_marginTop: 11,
        },
    }));
    const logoImagePath = require('../../assets/common/home/thumb_white.png');

    return (
        <View style={styles.container}>
            <Image source={logoImagePath} style={styles.image} />
            <Text style={styles.text0}>
                Volar
            </Text>
            <Text style={styles.text1}>
                We go on blind dates
            </Text>
            <Text style={styles.text2}>
                so you don't have to
            </Text>
            <Text style={styles.text3}>
                First dates are simulated for both you and your{'\n'}
                match, giving you more info before messaging the{'\n'}
                real person!
            </Text>
        </View>
    );
};

export default LargeCaption;