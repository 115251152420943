// components/home/Background.js
import { useState, useEffect, useRef } from 'react';
import { View, Animated, Platform, StyleSheet, ImageBackground, Image } from 'react-native';
import { LinearGradient } from "expo-linear-gradient";
import { useRelativeStyles, useScreenDimensions } from "../../utils/screenDimensions";

const Background = () => {
    const { windowWidth, windowHeight } = useScreenDimensions();
    const imageSize = [1285, 857];
    const imageAnchorX = 860;
    const isPortrait = windowWidth / windowHeight < imageSize[0] / imageSize[1];
    const styles = StyleSheet.create(useRelativeStyles({
        view: {
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
            overflow: 'hidden',
            zIndex: -1,
        },
        gradient: {
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: -1,
        },
        image: {
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
            resizeMode: 'cover',
            zIndex: -2,
        },
        imagePortrait: {
            width: imageSize[0] / imageSize[1] * windowHeight,
            height: windowHeight,
            position: 'absolute',
            left: imageAnchorX / imageSize[0] * windowWidth - imageAnchorX / imageSize[1] * windowHeight,
            top: 0,
            zIndex: -1,
        },
    }));
    const pathWeb = require('../../assets/common/home/bg_large1.jpg');
    const images = [
        require('../../assets/common/home/bg4.jpg'),
        require('../../assets/common/home/bg3.jpg'),
        require('../../assets/common/home/bg2.jpg'),
        require('../../assets/common/home/bg1.jpg'),
    ];
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [reverse, setReverse] = useState(false);
    const currentOpacityAnim = useRef(new Animated.Value(1)).current;
    const nextOpacityAnim = useRef(new Animated.Value(0)).current;

    const animateImageTransition = () => {
        const nextIndex = (currentImageIndex + 1) % images.length;

        // Start the cross-fade animation
        Animated.parallel([
            Animated.timing(currentOpacityAnim, {
                toValue: 0,
                duration: 1500,
                useNativeDriver: true,
            }),
            Animated.timing(nextOpacityAnim, {
                toValue: 1,
                duration: 1500,
                useNativeDriver: true,
            }),
        ]).start(() => {
            // Prepare for the next animation cycle
            setReverse(!reverse);
            setCurrentImageIndex(nextIndex);
            currentOpacityAnim.setValue(1);
            nextOpacityAnim.setValue(0);
        });
    };

    useEffect(() => {
        if (Platform.OS === 'ios') {
            const intervalId = setInterval(() => {
                animateImageTransition();
            }, 4500); // 3000 ms wait + 1500 ms animation

            return () => clearInterval(intervalId);
        }
    }, [currentImageIndex]);

    const nextImageIndex = (currentImageIndex) => (
        (currentImageIndex + 1) % images.length
    );

    if (Platform.OS === 'ios') {
        return (
            <View style={styles.view}>
                <Animated.Image
                    source={images[
                        reverse ? currentImageIndex : nextImageIndex(currentImageIndex)]}
                    style={[styles.image, {
                        opacity: reverse ? currentOpacityAnim : nextOpacityAnim,
                    }]}
                    resizeMode="cover"
                />
                <Animated.Image
                    source={images[
                        reverse ? nextImageIndex(currentImageIndex) : currentImageIndex]}
                    style={[styles.image, {
                        opacity: reverse ? nextOpacityAnim : currentOpacityAnim,
                    }]}
                    resizeMode="cover"
                />
                <LinearGradient
                    colors={['transparent', 'rgba(0,0,0,0.8)']}
                    style={styles.gradient}
                />
            </View>
        );
    }

    // For Web and other platforms
    return (
        <View style={styles.view}>
            <Image source={pathWeb} style={
                isPortrait ? styles.imagePortrait : styles.image
            } />
            <LinearGradient
                colors={['transparent', 'rgba(0,0,0,0.8)']}
                style={styles.gradient}
            />
        </View>
    );
};

export default Background;