// components/chat/SimulatedAction.js
import {
    Text,
    StyleSheet,
} from "react-native";
import { useScreenDimensions } from "../../utils/screenDimensions";
import { startBoosting } from "../../actions/boostActions";
import { useDispatch } from "react-redux";
import ButtonClickWrapper from "../../firebase/components/buttonClickWrapper";
import { BUTTON_CLICK_CHAT_CHAT_BOOST } from "../../firebase/constants";

const styles = StyleSheet.create({
    text: {
        fontFamily: 'IBMPlexSans_700Bold',
        color: '#999',
        textAlignVertical: 'center',
        textAlign: 'center',
    },
    nestedText: {
        fontFamily: 'IBMPlexSans_400Regular',
    },
});

const SimulatedAction = ({ chatName }) => {
    const { adjustedWidth } = useScreenDimensions();
    const dispatch = useDispatch();
    const configValues = {
        fontSize: adjustedWidth * 0.0356,
        lineHeight: adjustedWidth * 0.0445,
        marginTop: adjustedWidth * 0.0712,
    };

    const BoostButton = ButtonClickWrapper(({onPress}) => (
        <Text onPress={onPress}>send them a boosted message!</Text>
    ), BUTTON_CLICK_CHAT_CHAT_BOOST)

    return (
        <Text style={[styles.text, {
            fontSize: configValues.fontSize,
            lineHeight: configValues.lineHeight,
            marginTop: configValues.marginTop,
        }]}>
            This conversation was simulated by AI{'\n'}
            <Text style={styles.nestedText}>
                Want to keep the convo going?{'\n'}
                Send {chatName} a request with a message, or{'\n'}
            </Text>
            <BoostButton onPress={() => {dispatch(startBoosting())}} />
        </Text>
    );
};

export default SimulatedAction;