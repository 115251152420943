// components/home/ContinueButton.js
import { useRef, useCallback } from 'react';
import { TouchableOpacity, Text, Image, StyleSheet, Platform } from 'react-native';
import { useDispatch } from 'react-redux';
import * as Animatable from 'react-native-animatable';
import ReactGA from "react-ga4";
import { resetNavigationByKey } from '../../utils/navigation';
import { useRelativeStyles } from '../../utils/screenDimensions';
import { startOnboarding } from '../../services/authService';
import ButtonClickWrapper from '../../firebase/components/buttonClickWrapper';
import { BUTTON_CLICK_HOME_CONTINUE } from '../../firebase/constants';
import { logComponentRendered } from '../../firebase/utils';

const ContinueButton = ({ navigation, id }) => {
    const styles = StyleSheet.create(useRelativeStyles({
        button: {
            backgroundColor: '#F3F631',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            rlw_width: 341,
            rlw_height: 71,
            rlw_borderRadius: 12,
            rlw_marginBottom: 15,
        },
        text: {
            color: 'black',
            textAlign: 'center',
            fontFamily: 'BricolageGrotesque_Medium',
            rlw_fontSize: 25,
            rlw_marginRight: 7,
        },
        image: {
            rlw_width: 25,
            rlw_height: 25,
        },
    }));
    const buttonImagePath = require('../../assets/arrows/arrow.right.circle.sf_medium.png');
    const dispatch = useDispatch();
    const animationRef = useRef(null);
    const handleContinue = async () => {
        if (Platform.OS === 'web') {
            ReactGA.event({
                category: "User",
                action: "ContinueClick",
            });
            if(typeof fbq !== 'undefined') {
                fbq('trackCustom', 'ContinueClick', {});
            }
        }
        const success = await startOnboarding();
        if (success) {
            await resetNavigationByKey(navigation, dispatch);
        } else {
            console.error('Failed to send phone number');
            animationRef.current.shake(800);
        }
    };

    const handleOnLayout = useCallback(() => {
        if (Platform.OS === 'web') {
            const url = new URL(document.location.href);
            if (url.pathname === '/') {
                logComponentRendered('home_continue_button')
            }
        }
    }, [id]);

    const BaseContinueButton = ButtonClickWrapper(({onPress}) => (
        <TouchableOpacity
            activeOpacity={0.7}
            style={styles.button}
            onPress={onPress}
            onLayout={handleOnLayout}
        >
            <Text style={styles.text}>
                Get started
            </Text>
            <Image source={buttonImagePath} style={styles.image} />
        </TouchableOpacity>
    ), BUTTON_CLICK_HOME_CONTINUE)

    return (
        <Animatable.View ref={animationRef}>
            <BaseContinueButton
                onPress={handleContinue}
            />
        </Animatable.View>
    );
};

export default ContinueButton;