// reducers/boostReducer.js

import { actions } from "../actions/boostActions";

const boostReducer = (
    state = {
        isMenuOpen: false,
        boostCount: 0,
        purchaseOptions: [],
        isBoosting: false,
    },
    action
) => {
    switch (action.type) {
        case actions.BoostMenuOpen:
            return {
                ...state,
                isMenuOpen: true,
            };
        case actions.BoostMenuClose:
            return {
                ...state,
                isMenuOpen: false,
            };
        case actions.SetBoostCount:
            return {
                ...state,
                boostCount: action.payload,
            }
        case actions.ReduceBoost:
            return {
                ...state,
                boostCount: state.boostCount - 1,
                isBoosting: false,
            }
        case actions.StartBoosting:
            if (state.boostCount > 0) {
                return {
                    ...state,
                    isBoosting: true,
                };
            } else {
                return {
                    ...state,
                    isMenuOpen: true,
                }
            }
        case actions.PurchaseMenuOpen:
            return {
                ...state,
                isMenuOpen: true,
            }
        case actions.SetBoostPurchaseOptions:
            return {
                ...state,
                purchaseOptions: action.payload,
            };
        case actions.StopBoosting:
            return {
                ...state,
                isBoosting: false,
            };
        default:
            return state;
    }
};

export default boostReducer;
