// utils/userUtils.js
export const UserThumbnailSize = {
    SMALL: 'small',
    LARGE: 'large',
};

export const getUserThumbnailButtonRLW = (thumbnailSize) => {
    switch (thumbnailSize) {
        case UserThumbnailSize.SMALL:
            return 24;
        case UserThumbnailSize.LARGE:
            return 44;
    }
};

export const getUserThumbnailButtonOFF = (thumbnailSize) => {
    return 4;
};

const capitalizeName = (str) => {
    return str
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

const abridgeName = (str) => {
    return str.charAt(0).toUpperCase() + '.';
};

export const getUserDisplayName = (user) => {
    const firstName = user?.firstName || "Unknown";
    const lastName = user?.lastName || '';
    if (lastName === '') {
        return capitalizeName(firstName);
    } else {
        return capitalizeName(firstName) + ' ' + abridgeName(lastName);
    }
};

export const getUserCosmeticColor = (user) => {
    return user?.cosmeticMetadata?.color || '#FFFFFF00';
};

export const getUserDisplayInfo = (user, isBrief=false) => {
    const age = user?.age ? user?.age + (isBrief ? '' : 'yo') : 'Unknown age';
    const location = user?.location || '';
    if (location === '') {
        return age;
    } else {
        return age + ', ' + capitalizeName(location);
    }
};

export const getUserThumbnail = (user) => {
    if (user?.thumbnail == null) {
        return require('../assets/arrows/person.crop.circle.fill.sf_medium.png');
    } else {
        return { uri: user?.thumbnail };
    }
};