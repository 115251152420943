// models/Message.js
import { createSimpleUser } from "./SimpleUser";

export const MessageType = {
    'REGISTRATION': 'REGISTRATION',
    'ONBOARDING': 'ONBOARDING',
    'SIMULATED': 'SIMULATED',
    'REQUEST': 'REQUEST',
    'CONFIRM': 'CONFIRM',
    'TEXT': 'TEXT',
    'REJECT': 'REJECT',
    'ASSISTANCE': 'ASSISTANCE',
};

export const createMessage = (data) => {
    if (data == null) {
        return null;
    } else {
        return {
            id: data.id,
            author: createSimpleUser(data.author),
            content: data.content,
            type: data.type,
            created: data.created,
            isBoosted: data.is_boosted,
        };
    }
};