// screens/HomeScreen.js
import { useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import PageViewWrapper from '../firebase/components/pageViewWrapper';
import { resetNavigationFromUserStatus } from '../utils/navigation';
import ContinueButton from '../components/home/ContinueButton';
import HelpText from '../components/home/HelpText';
import LargeCaption from '../components/home/LargeCaption';
import Background from '../components/home/Background';

const styles = StyleSheet.create({
    container: {
        justifyContent: 'flex-end',
        alignItems: 'center',
        backgroundColor: 'black',
        flex: 1,
    },
});

const ContainerView = ({ navigation }) => (
    <View style={styles.container}>
        <Background />
        <LargeCaption />
        <ContinueButton navigation={navigation} id={'continue_button'}/>
        <HelpText />
    </View>
);

const HomeScreen = ({ navigation }) => {
    const user = useSelector(state => state.user);

    useEffect(() => {
        if (user) {
            resetNavigationFromUserStatus(navigation, user.status);
        }
    }, [user]);

    return (
        <ContainerView navigation={navigation} />
    );
};

export default PageViewWrapper(HomeScreen);