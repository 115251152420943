// services/boostService.js

import axios from "axios";
import uuid from 'react-native-uuid';
import {
    BOOST_COUNT_URL, BOOST_PURCHASE_OPTIONS_URL,
    BOOST_ON_DEMAND_URL, BOOST_SUBSCRIPTION_URL,
} from "../urlconfig";
import { getToken } from "./common";

// export this for mocking in unit test
export const axiosClient = axios.create({
    headers: {
      'Content-Type': 'application/json',
    },
    timeout: 30000, // Timeout in milliseconds
});

export async function getBoostCount() {
    try {
        let token = await getToken();
        if (token === null) {
            throw new Error("Token is null");
        }
        const config = { headers: { Authorization: `Token ${token}` } };
        const response = await axiosClient.get(BOOST_COUNT_URL, config);
        if (response.status === 200) {
            return response.data.boost_count;
        } else {
            console.error("Error in getting boost count: ", response);
            return 0;
        }
    } catch (error) {
        console.error("Error in getting boost count: ", error);
        return 0;
    }
}

export async function getBoostPurchaseOptions() {
    try {
        const config = { headers: {} };
        let token = await getToken();
        if (token !== null) {
            config.headers.Authorization = `Token ${token}`;
        }

        const response = await axiosClient.get(BOOST_PURCHASE_OPTIONS_URL, config);
        if (response.status === 200) {
            return response.data;
        } else {
            console.error("Error in getting boost purchase options: ", response);
            return [];
        }
    } catch (error) {
        console.error("Error in getting boost purchase options: ", error);
        return [];
    }
}

export async function purchaseBoost(targetId, transactionData, platform) {
    try {
        let token = await getToken();
        if (token === null) {
            throw new Error("Token is null");
        }
        const config = { headers: { Authorization: `Token ${token}` } };
        const response = await axiosClient.post(
            BOOST_ON_DEMAND_URL,
            {
                payment_id: uuid.v4(),
                target_id: targetId,
                transaction_data: transactionData,
                platform
            },
            config
        )

        if (response.status >= 400)
            throw Error("Failed to make transaction")

    } catch(ex) {
        console.error("Error in purchasing boost: ", ex);
        throw Error("Failed to make transaction")
    }
}

export async function subscribeBoost(targetId, transactionData, platform, cardInfo) {
    // receiptData is a string, for web it's the card token, for ios it's the receipt payload
    try {
        let token = await getToken();
        if (token === null) {
            throw new Error("Token is null");
        }
        const config = { headers: { Authorization: `Token ${token}` } };

        const body = {
                payment_id: uuid.v4(),
                transaction_data: transactionData,
                target_id: targetId,
                platform,
        }
        if (platform == 'web') {
            console.log("get cardInfo:", cardInfo)
            body.card_info = {
                card_brand: cardInfo.brand,
                exp_month: cardInfo.expMonth,
                exp_year: cardInfo.expYear,
                last_4: cardInfo.last4,
            }
        }
        const response = await axiosClient.post(BOOST_SUBSCRIPTION_URL, body, config)
    } catch(ex) {
        console.error("Error in subscribing boost: ", ex);
        throw Error("Failed to make transaction")
    }
}
