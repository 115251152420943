// components/chat/ChatBubbleBody.js
import {
    Image,
    View,
    Text,
    StyleSheet,
} from "react-native";
import { useRelativeStyles } from "../../utils/screenDimensions";
import { UserThumbnailSize } from "../../utils/userUtils";
import { MessageType } from "../../models/Message";
import { getCosmeticFontColor } from '../../utils/cosmetics';
import UserThumbnail from '../UserThumbnail';
import {
    getUserThumbnailButtonRLW, getUserThumbnailButtonOFF,
} from '../../utils/userUtils';
import ButtonClickWrapper from "../../firebase/components/buttonClickWrapper";
import { BUTTON_CLICK_CHAT_CHAT_PROFILE } from "../../firebase/constants";

const ChatBubbleBody = ({ parentWidth, thumbnailSize, message, details, onPressProfile }) => {
    const boostIcon = require("../../assets/boost/boostLogo.png");

    const getBackgroundColor = () => {
        if (message.type === MessageType.SIMULATED) {
            return 'transparent';
        } else {
            return message.author.cosmeticMetadata.color;
        }
    };

    const getColor = () => {
        if (message.type === MessageType.SIMULATED) {
            return message.author.cosmeticMetadata.color;
        } else {
            return getCosmeticFontColor(message.author.cosmeticMetadata);
        }
    };

    const styles = StyleSheet.create(useRelativeStyles({
        messageBox: {
            flexDirection: 'row',
            alignItems: 'flex-end',
            justifyContent: details.isMyMessage ? 'flex-end' : 'flex-start',
            width: parentWidth,
        },
        emptyIcon: {
            rlw_width: getUserThumbnailButtonRLW(thumbnailSize),
            off_width: getUserThumbnailButtonOFF(thumbnailSize),
        },
        messageBubble: {
            backgroundColor: getBackgroundColor(),
            rlw_borderRadius: 16,
            rlw_marginLeft: details.isMyMessage ? 5 : 11,
            rlw_marginRight: details.isMyMessage ? 11 : 5,
            rlw_paddingHorizontal: 16,
            rlw_paddingVertical: message.type === MessageType.SIMULATED ? 0 : 8,
            rlw_maxWidth: -77 - getUserThumbnailButtonRLW(thumbnailSize),
            off_maxWidth: parentWidth - getUserThumbnailButtonOFF(thumbnailSize),
        },
        message: {
            fontFamily: 'IBMPlexSans_400Regular',
            color: getColor(),
            rlw_fontSize: 16,
            rlw_lineHeight: 20,
        },
        boostIconBox: {
            rlw_width: 24,
            height: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        },
        boostIcon: {
            rlw_width: 22,
            rlw_height: 22,
        },
    }));

    const ProfileButton = ButtonClickWrapper(({onPress, user}) => {
        return (
            <UserThumbnail
                user={user}
                thumbnailSize={UserThumbnailSize.SMALL}
                hasBorder={message.type !== MessageType.SIMULATED}
                onPress={onPress}
                isDark={false}
            />
        )
    }, BUTTON_CLICK_CHAT_CHAT_PROFILE)

    return (
        <View style={styles.messageBox}>
            {details.showThumbnail && (!details.isMyMessage)
                ? <ProfileButton
                    onPress={() => onPressProfile(message.author)}
                    user={message.author}
                />
                : <View style={styles.emptyIcon} />
            }
            {message.isBoosted && details.isMyMessage &&
                <View style={styles.boostIconBox}>
                    <Image style={styles.boostIcon} source={boostIcon} />
                </View>
            }
            <View style={styles.messageBubble}>
                <Text style={styles.message}>
                    {message.content}
                </Text>
            </View>
            {message.isBoosted && !details.isMyMessage &&
                <View style={styles.boostIconBox}>
                    <Image style={styles.boostIcon} source={boostIcon} />
                </View>
            }
            {details.showThumbnail && details.isMyMessage
                ? <ProfileButton
                    onPress={() => onPressProfile(message.author)}
                    user={message.author}
                    />
                : <View style={styles.emptyIcon} />
            }
        </View>
    );
};

export default ChatBubbleBody;