// actions/referralActions.js

export const storeInvitationCode = (code) => {
    return {
        type: 'STORE_INVITATION_CODE',
        payload: code,
    };
};

export const storeUTMSource = (utm_source) => {
    return {
        type: 'STORE_UTM_SOURCE',
        payload: utm_source,
    }
};