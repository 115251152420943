// utils/chatUtils.js
import { UserStatus } from "../models/User";
import { MessageType } from "../models/Message";
import { ChatType } from "../models/Chat";
import { getUserDisplayName } from "./userUtils";

export const getChatDisplayName = (chat, user) => {
    const userNames = chat.users
        .filter(chatUser => chatUser.userid !== user?.pk)
        .map(filteredUser => getUserDisplayName(filteredUser));
    return userNames.join(', ');
};

export const getChatOtherUser = (chat, user) => {
    return chat.users.filter(chatUser => chatUser.userid != user?.pk)[0];
};

export const getDisplayTime = (messageDate) => {
    const now = new Date();

    // difference in minutes
    const diffInMinutes = Math.floor((now.getTime() - messageDate.getTime()) / 1000 / 60);
    if (diffInMinutes < 60) {
        return `${diffInMinutes}m`;
    } else {
        // difference in hours
        const diffInHours = Math.floor(diffInMinutes / 60);
        if (diffInHours < 24) {
            return `${diffInHours}h`;
        } else {
            // difference in days
            const diffInDays = Math.floor(diffInHours / 24);
            if (diffInDays < 7) {
                return `${diffInDays}d`;
            } else {
                // difference in weeks
                const diffInWeeks = Math.floor(diffInDays / 7);
                return `${diffInWeeks}w`;
            }
        }
    }
};

export const getChatDisplayTime = (chat) => {
    if (chat.lastMessage !== null) {
        const messageDate = new Date(chat.lastMessage.created);
        return getDisplayTime(messageDate);
    }
    return '';
};

export const getChatIsUnread = (chat) => {
    if (chat.lastMessage == null) {
        return false;
    } else if (chat.lastReadMessage == null) {
        return true;
    } else {
        return (chat.lastMessage.id !== chat.lastReadMessage.id);
    }
};

export const getChatDisplayTypeAndText = (chat, user) => {
    // This function will analyze chat, based on lastMessage and lastReadMessage
    // It returns a tuple (chatType, displayText, displayTime, isUnread)
    const displayTime = getChatDisplayTime(chat);
    const isUnread = getChatIsUnread(chat);
    const chatName = getChatDisplayName(chat, user);
    const otherUser = getChatOtherUser(chat, user);
    let chatType = ChatType.FIRSTDATE;
    let displayText = '';

    if (user === null || chat === null || !chat.isActive) {
        chatType = ChatType.INVALID;
        displayText = 'Unavailable';
    } else if (chat.lastMessage === null ||
        chat.lastMessage.type === MessageType.SIMULATED) {
        chatType = ChatType.FIRSTDATE;
        displayText = 'Volar simulated your first date';
    } else if (chat.lastMessage.type === MessageType.REQUEST) {
        if (user.pk === chat.lastMessage.author.userid) {
            // Request was from this user
            chatType = ChatType.REQUESTED;
            displayText = 'Message requested';
        } else {
            chatType = ChatType.ISREQUESTED;
            displayText = 'Sent you a request';
        }
    } else if (chat.lastMessage.type === MessageType.CONFIRM ||
        chat.lastMessage.type === MessageType.TEXT) {
        chatType = ChatType.MESSAGE;
        displayText = chat.lastMessage.content;
    } else {
        chatType = ChatType.INVALID;
        displayText = 'Archived';
    }
    return { otherUser, chatName, chatType, displayText, displayTime, isUnread, isBoosted: chat.isBoosted };
};

export const updateChatType = (currentChatType, lastMessageType, isMyMessage) => {
    // Backend is responsible for safe-guarding the right sequence
    // On frontend we are more tolerant and simply update chatType based on lastMessageType
    // The logic here is hence consistent with that in getChatDisplayTypeAndText()
    switch (lastMessageType) {
        case MessageType.SIMULATED:
            return ChatType.FIRSTDATE;
        case MessageType.REQUEST:
            return isMyMessage ? ChatType.REQUESTED : ChatType.ISREQUESTED;
        case MessageType.CONFIRM:
            return ChatType.MESSAGE;
        case MessageType.TEXT:
            return ChatType.MESSAGE;
        case MessageType.REJECT:
            return ChatType.INVALID;
        default:
            return currentChatType;
    }
};

export const getMessageTypeFromUserStatus = (userStatus) => {
    switch (userStatus) {
        case UserStatus.PRE_REGISTERED:
            return MessageType.REGISTRATION;
        case UserStatus.REGISTERED:
            return MessageType.ONBOARDING;
        case UserStatus.ONBOARDED:
            return MessageType.TEXT;
        default:
            return MessageType.TEXT;
    }
};