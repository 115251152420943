// components/chat/ChatHeader.js
import {
    View,
    Text,
    StyleSheet,
    Platform,
    TouchableOpacity,
    Image,
} from 'react-native';
import { useRelativeStyles } from '../../utils/screenDimensions';
import { resetNavigation } from '../../utils/navigation';
import { getUserDisplayName, getUserDisplayInfo } from '../../utils/userUtils';
import BoostIndicator from '../boost/indicator';
import ButtonClickWrapper from '../../firebase/components/buttonClickWrapper';
import { BUTTON_CLICK_CHAT_HEADER_BOOST, BUTTON_CLICK_CHAT_HEADER_PROFILE } from '../../firebase/constants';

const ChatHeader = ButtonClickWrapper(({ otherUser, onLayout, navigation, onPress }) => {
    const styles = StyleSheet.create(useRelativeStyles({
        header: {
            flexDirection: 'row',
            backgroundColor: 'white',
            justifyContent: 'center',
            alignItems: 'flex-end',
            borderBottomWidth: 0.333,
            borderBottomColor: '#0000004D',
            rlw_height: Platform.OS === 'web' ? 54 :
                (Platform.isPad ? 70 : 100),
        },
        container: {
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            rlw_height: 54,
        },
        back: {
            justifyContent: 'center',
            alignItems: 'center',
            rlw_width: 74,
        },
        image: {
            rlw_width: 22,
            rlw_height: 18,
            resizeMode: 'contain',
        },
        textView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        text: {
            fontFamily: 'IBMPlexSans_700Bold',
            color: '#333',
            textAlignVertical: 'center',
            textAlign: 'center',
            rlw_fontSize: 14,
            rlw_lineHeight: 17.5,
        },
        textAux: {
            fontFamily: 'IBMPlexSans_400Regular',
            color: '#BDBDBD',
        },
        boostIndicatorContainer: {
            rlw_marginRight: 21,
            justifyContent: 'center',
        }
    }));
    const image = require('../../assets/arrows/arrow.left.sf_light.png');

    const WrappedBoostIndicator = ButtonClickWrapper(({ onPress }) => {
        // notice this only tracks the click that turns on the boost
        return (
            <View style={styles.boostIndicatorContainer}>
                <BoostIndicator sideEffect={onPress}/>
            </View>
        )
    }, BUTTON_CLICK_CHAT_HEADER_BOOST)

    return (
        <View style={styles.header} onLayout={onLayout}>
            <View style={styles.container}>
                <TouchableOpacity
                    testID='back-button'
                    activeOpacity={0.5}
                    style={styles.back}
                    onPress={() => navigation.canGoBack() ?
                        navigation.goBack() :
                        resetNavigation(navigation, 'Chats')
                    }
                >
                    <Image
                        source={image}
                        style={styles.image}
                    />
                </TouchableOpacity>
                <TouchableOpacity
                    testID='profile-button'
                    activeOpacity={0.5}
                    style={styles.textView}
                    onPress={onPress}
                >
                    <Text style={styles.text}>
                        {getUserDisplayName(otherUser) + '\n'}
                        <Text style={styles.textAux}>
                            {getUserDisplayInfo(otherUser, false)}
                        </Text>
                    </Text>
                </TouchableOpacity>
                <WrappedBoostIndicator onPress={()=>{}}/>
            </View>
        </View>
    );
}, BUTTON_CLICK_CHAT_HEADER_PROFILE);

export default ChatHeader;