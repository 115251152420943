// utils/imageUtils.js
import * as ImageManipulator from 'expo-image-manipulator';
import * as ImagePicker from 'expo-image-picker';

export const selectImageFromCameraRoll = async () => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (status !== 'granted') {
        alert('Sorry, we need camera roll permissions to complete your profile!');
        return null;
    }

    const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [1, 1],
        quality: 1,
    });

    return result;
};

export const resizeImage = async (input) => {
    const isPortrait = input.height > input.width;
    let cropConfig;

    if (isPortrait) {
        cropConfig = {
            originX: 0,
            originY: (input.height - input.width) / 2,
            width: input.width,
            height: input.width
        };
    } else {
        cropConfig = {
            originX: (input.width - input.height) / 2,
            originY: 0,
            width: input.height,
            height: input.height
        };
    }

    const manipResult = await ImageManipulator.manipulateAsync(
        input.uri,
        [
            // Crop
            {
                crop: cropConfig
            },
            // Resize
            {
                resize: {
                    width: 1000
                }
            },
        ],
        { compress: 0.5, format: ImageManipulator.SaveFormat.JPEG }
    );

    return manipResult;
};