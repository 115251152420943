// services/authService.js

import axios from 'axios';
import {
    START_URL, START_SECRET,
    USER_DETAILS_URL, USER_BASE_URL, USER_NOTIFICATION_TOKEN_URL, USER_DELETE_URL,
} from '../urlconfig';
import { createUser } from '../models/User'
import { showNetworkError } from '../utils/errorHandlers';
import { createSimpleUser } from '../models/SimpleUser';
import { useInvitationCode } from '../services/shareService';
import { setToken, getToken } from './common';
import store from '../store';

export async function startOnboarding() {
    try {
        const { utmSource } = store.getState().referral;
        const response = await axios.post(START_URL, {
            api_key: START_SECRET,
            ...(utmSource ? { utm_source: utmSource } : {})
        });

        if (response.status === 201) {
            await setToken(response.data.key)
            await useInvitationCode();
            return true;
        } else {
            console.error('Error in initialization: ', response);
            return false;
        }
    } catch (error) {
        if (error.message?.includes('Network Error') || error.message?.includes('timeout')) {
            showNetworkError();
        } else {
            console.error('Error in sending phone number: ', error);
        }
        return false;
    }
};

export async function updateToken(token) {
    try {
        await setToken(token);
        await useInvitationCode();
    } catch (error) {
        console.error('Error in updating token: ', error);
    }
};

export async function getUserDetails() {
    let token = await getToken();
    if (token === null) {
        throw new Error('Token is null');
    }
    const config = {
        headers: {
            Authorization: `Token ${token}`
        }
    }
    const response = await axios.get(USER_DETAILS_URL, config);
    if (response.status === 200) {
        const userDetails = createUser(response.data);
        return userDetails;
    } else {
        throw new Error('Error in fetching user details: ' + response);
    }
};

export async function setUserExpoPushToken(expoToken) {
    const config = {
        headers: {
            Authorization: `Token ${await getToken()}`
        }
    }

    const data = {
        "expo_push_token": expoToken
    }

    const response = await axios.patch(USER_NOTIFICATION_TOKEN_URL, data, config)
    if (response.status === 200) {
        return;
    } else {
        throw new Error('Error in setting user push token: ' + response);
    }
};

export const fetchUser = async (uesrId) => {
    const url = `${USER_BASE_URL}${uesrId}/`;
    try {
        const token = await getToken();
        const response = await axios.get(url, {
            headers: {
                Authorization: `Token ${token}`,
            },
        });
        if (response.status != 200) {
            throw new Error('Failed to fetch user data.');
        }
        return createSimpleUser(response.data);
    } catch (error) {
        throw error;
    }
};

export const blockUser = async (uesrId) => {
    const url = `${USER_BASE_URL}${uesrId}/block/`;
    try {
        const token = await getToken();
        const response = await axios.post(url, {}, {
            headers: {
                Authorization: `Token ${token}`,
            },
        });
        if (response.status != 200) {
            throw new Error('Failed to fetch user data.');
        }
        return true;
    } catch (error) {
        throw error;
    }
};

export const deleteUser = async () => {
    const url = `${USER_DELETE_URL}`;
    try {
        const token = await getToken();
        const response = await axios.delete(url, {
            headers: {
                Authorization: `Token ${token}`,
            },
        });

        if (response.status != 204) {
            throw new Error('Failed to delete user data.');
        }
    } catch (error) {
        throw error;
    }
}