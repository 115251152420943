// components/boot/MenuCard/MenuModal.web.js
// MenuModal wrapper for payment function on web
import { useState } from "react";
import { Modal, View, StyleSheet } from "react-native";
import { useDispatch } from "react-redux";
import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";

import { useRelativeStyles } from "../../../utils/screenDimensions";
import { CancelXButton } from "../../common/closeButton";
import BaseMenuModal from "./BaseMenuModal";
import { purchaseBoost, subscribeBoost } from "../../../services/boostService";
import {
    refreshBoostCount,
    closeBoostMenu,
} from "../../../actions/boostActions";

import { SQ_APPLICATION_ID } from "@env";

// define an enum called payment type
// which has two values: ondemand and subscription
const PaymentType = {
    ON_DEMAND: "ondemand",
    SUBSCRIPTION: "subscription",
};

const PaymentErrorMsg = "Failed to prcess the payment,\nplease retry.";

const PaymentCard = ({ onSubmit, isVisible, onClose }) => {
    const styles = StyleSheet.create(
        useRelativeStyles({
            modalContainer: {
                flex: 1,
                backgroundColor: "rgba(0,0,0,0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            },
            cardContainer: {
                marginHorizontal: 25,
                paddingTop: 57,
                padding: 32,
                backgroundColor: "white",
                alignItems: "center",
                rlw_borderRadius: 12,
            },
        })
    );

    return (
        <Modal
            visible={isVisible}
            transparent={true}
            onRequestClose={onClose}
        >
            <View style={styles.modalContainer}>
                <View style={styles.cardContainer}>
                    <CancelXButton
                        onPress={() => {
                            onClose();
                        }}
                        color="#5A5A5A"
                    />
                    <PaymentForm
                        applicationId={SQ_APPLICATION_ID}
                        cardTokenizeResponseReceived={(cardInfo, buyer) => {
                            onSubmit(cardInfo);
                        }}
                        locationId="LNAKCPR1JG858"
                    >
                        <CreditCard />
                    </PaymentForm>
                </View>
            </View>
        </Modal>
    );
};

const WebMenuModal = ({ purchaseOptions }) => {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [errMsg, setErrorMsg] = useState();
    const [showPayment, setShowPayment] = useState(false);
    const [paymentInfo, setPaymentInfo] = useState(null);

    const submitPayment = (cardInfo) => {
        const { token, details } = cardInfo;
        setIsLoading(true);
        if (paymentInfo.type === PaymentType.ON_DEMAND) {
            purchaseBoost(paymentInfo.targetId, token, "web")
                .then((res) => {
                    console.log("boost purchased: ", res);
                    dispatch(refreshBoostCount());
                    dispatch(closeBoostMenu());
                    setShowPayment(false);
                })
                .catch((err) => {
                    console.error("Error in purchasing boost: ", err);
                    setErrorMsg(PaymentErrorMsg);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else if (paymentInfo.type === PaymentType.SUBSCRIPTION) {
            subscribeBoost(paymentInfo.targetId, token, "web", details.card)
                .then(() => {
                    dispatch(closeBoostMenu());
                    setShowPayment(false);
                })
                .catch((err) => {
                    console.error("Error in subscribing boost: ", err);
                    setErrorMsg(PaymentErrorMsg);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            console.error("unrecognized payment type:", paymentInfo.type);
            setIsLoading(false);
        }
    };

    return (
        <>
            <PaymentCard
                isVisible={showPayment}
                onClose={() => setShowPayment(false)}
                onSubmit={submitPayment}
            />
            <BaseMenuModal
                isLoading={isLoading}
                errMsg={errMsg}
                options={purchaseOptions}
                onCloseError={() => setErrorMsg(null)}
                onPurchase={(option) => {
                    switch (option.purchaseType) {
                        case "subscription":
                            console.log("purchase subscription");
                            setPaymentInfo({
                                type: PaymentType.SUBSCRIPTION,
                                targetId: option.targetId,
                            });
                            break;
                        case "on_demand":
                            setPaymentInfo({
                                type: PaymentType.ON_DEMAND,
                                targetId: option.targetId,
                            });
                            break;
                        default:
                            return;
                    }
                    setShowPayment(true);
                }}
            />
        </>
    );
};

export default WebMenuModal;
